import React, { useState, useEffect, useRef } from 'react';
import { Box, Textarea, VStack, Text, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, FormLabel } from '@chakra-ui/react';
import hljs from 'highlight.js/lib/core';
import sql from 'highlight.js/lib/languages/sql';
import 'highlight.js/styles/github.css';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { getCountsFromQuery, getCountsFromQueryMulti } from '../../Services/api';
import store from '../../store/store';

hljs.registerLanguage('sql', sql);

const CodeEditor = ({ sendQuery, orgId, formik, audienceRange, setAudienceRange }) => {
  const [query, setQuery] = useState('');
  const textareaRef = useRef(null);
  const highlightedRef = useRef(null);
  const [countsFromQuery, setCountsFromQuery] = useState([]);
  const [countsFromQuerySingle, setCountsFromQuerySingle] = useState();
  const [isLoading, setLoading] = useState(false);

  const getQueryCounts = async () => {
    setLoading(true);
    let res = {};
    if (window.location.href.includes('all-banks')) {
      res = await getCountsFromQueryMulti(formik.values.orgList, query).catch(err => {});
      if (res?.data) setCountsFromQuery(res?.data?.data);
    } else {
      res = await getCountsFromQuery(orgId, query).catch(err => {});
      if (res?.data) setCountsFromQuerySingle(res?.data?.data?.counts);
    }
    setLoading(false);
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    sendQuery(e.target.value);
  };

  const handleScroll = () => {
    if (highlightedRef.current && textareaRef.current) {
      highlightedRef.current.scrollTop = textareaRef.current.scrollTop;
      highlightedRef.current.scrollLeft = textareaRef.current.scrollLeft;
    }
  };

  useEffect(() => {
    if (highlightedRef.current) {
      const highlighted = hljs.highlight(query, { language: 'sql' }).value;
      highlightedRef.current.innerHTML = highlighted;
    }
  }, [query]);

  return (
    <VStack spacing={4} align="stretch" width="100%">
      <Text>Enter a raw query</Text>
      <Box position="relative" width="100%" height="150px" borderWidth="1px" borderRadius="md" overflow="hidden" backgroundColor="white">
        <Box
          ref={highlightedRef}
          as="pre"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          padding="12px"
          pointerEvents="none"
          whiteSpace="pre-wrap"
          wordWrap="break-word"
          overflow="auto"
          style={{
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            color: 'black',
            zIndex: 0,
            margin: 0,
          }}
        />
        <Textarea
          ref={textareaRef}
          value={query}
          onChange={handleQueryChange}
          onScroll={handleScroll}
          placeholder="Type your query here..."
          size="md"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          padding="12px"
          borderWidth="0"
          backgroundColor="transparent"
          zIndex="1"
          resize="none"
          style={{
            caretColor: 'black',
            color: 'transparent',
            whiteSpace: 'pre-wrap',
            overflow: 'auto',
            outline: 'none',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            lineHeight: 'inherit',
          }}
        />
      </Box>
      <PrimaryButton label={'Get Counts'} onClick={getQueryCounts} isLoading={isLoading} />
      {countsFromQuerySingle ? <Text>Count: {countsFromQuerySingle}</Text> : countsFromQuery.length > 0 ? (
        <>
          {countsFromQuery.map((dt, i) => (
            <Text key={i}>{dt?.org_name} : {dt?.result?.data?.counts || 0}</Text>
          ))}
          <FormLabel>Select Audience for segment</FormLabel>
          <NumberInput maxW='100px' value={audienceRange} onChange={(e) => setAudienceRange(e)}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </>
      ) : <Text color="red.500">No Counts Available For This Segment</Text>}
    </VStack>
  );
};

export default CodeEditor;