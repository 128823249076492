import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Text, Button, VStack, Textarea } from '@chakra-ui/react';

const BlockCodes = () => {
  const location = useLocation();
  const { apiResponse } = location.state || {}; // Retrieve the passed state

  // Function to copy code to clipboard
  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    alert('Code copied to clipboard!');
  };

  return (
    <Box p={4} borderWidth={1} borderRadius="lg" shadow="md">
      <Text fontWeight="bold" mb={4}>Saved Block Details:</Text>
      {apiResponse ? (
        <VStack align="start" spacing={6}>
          {/* Email Embed Code */}
          <Box w="full">
            <Text fontWeight="semibold">Email Embed Code:</Text>
            <Textarea
              mt={2}
              value={apiResponse.emailCode}
              readOnly
              rows={6}
              fontFamily="monospace"
              borderColor="gray.200"
            />
            <Button
              mt={2}
              size="sm"
              colorScheme="blue"
              onClick={() => copyToClipboard(apiResponse.emailCode)}
            >
              Copy Email Code
            </Button>
          </Box>

          {/* Website Embed Code */}
          <Box w="full">
            <Text fontWeight="semibold">Website Embed Code:</Text>
            <Textarea
              mt={2}
              value={apiResponse.websiteCode}
              readOnly
              rows={6}
              fontFamily="monospace"
              borderColor="gray.200"
            />
            <Button
              mt={2}
              size="sm"
              colorScheme="blue"
              onClick={() => copyToClipboard(apiResponse.websiteCode)}
            >
              Copy Website Code
            </Button>
          </Box>
        </VStack>
      ) : (
        <Text>No data received.</Text>
      )}
    </Box>
  );
};

export default BlockCodes;
