import React from 'react'
import DiskWriter from './TransactionalProviders/DiskWriter'
import Mandrill from './TransactionalProviders/Mandrill'
import AmazonSES from './TransactionalProviders/AmazonSES'
import GenericSMTP from './TransactionalProviders/GenericSMTP'
import NetCore from './TransactionalProviders/NetCore'
import SendGrid from './TransactionalProviders/SendGrid'
import SparkPost from './TransactionalProviders/SparkPost'
import Equitas from './TransactionalProviders/Equitas'
const EmailProviderMapping ={
    DiskWriter:DiskWriter,
    Mandrill:Mandrill,
    AmazonSES:AmazonSES,
    GenericSMTP:GenericSMTP,
    Netcore:NetCore,
    SendGrid:SendGrid,
    SparkPost:SparkPost,
    Equitas:Equitas,
}


export default EmailProviderMapping
