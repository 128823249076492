import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Input,
  Checkbox,
  VStack,
  HStack,
  Box,
  Text,
  Flex,
  Wrap,
  WrapItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import { getAllUsersForSU, updateUserForSU, resetPasswordForSU } from "../../Services/api";
import TableHead from "../Campaigns/components/TableHead";

 const AllUsersPage = () => {
  const [users, setUsers] = useState([]);
  const [editedUser, setEditedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchUsers(page);
  }, [page]);

  const fetchUsers = async (pageNum, query = "") => {
    try {
      const response = await getAllUsersForSU(pageNum, 10, query);
      setUsers(response.data.items);
      setTotalPages(response.data.page_count);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleEdit = (user) => {
    setEditedUser({ ...user });
    onOpen();
  };

  const handleSave = async () => {
    if (!editedUser) return;

    const confirmation = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to save the changes?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, save it!",
      cancelButtonText: "Cancel",
    });

    if (confirmation.isConfirmed) {
      try {
        await updateUserForSU(editedUser);
        Swal.fire("Success!", "User updated successfully.", "success");
        setEditedUser(null);
        fetchUsers(page);
        onClose();
      } catch (error) {
        Swal.fire("Error!", "Failed to update user.", "error");
        console.error("Error updating user:", error);
      }
    }
  };

  const handleResetPassword = async (email) => {
    const { value: password } = await Swal.fire({
      title: "Reset Password",
      input: "password",
      inputLabel: "New Password",
      inputPlaceholder: "Enter the new password",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    });

    if (password) {
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to reset the password?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset it!",
        cancelButtonText: "Cancel",
      });

      if (confirmation.isConfirmed) {
        try {
          await resetPasswordForSU({ email, password });
          Swal.fire("Success!", "Password reset successfully.", "success");
        } catch (error) {
          Swal.fire("Error!", "Failed to reset password.", "error");
          console.error("Error resetting password:", error);
        }
      }
    }
  };

  const handleInputChange = (field, value) => {
    if (!editedUser) return;
    setEditedUser((prev) => ({ ...prev, [field]: value }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearch = () => {
    setPage(0); // Reset to first page when searching
    fetchUsers(0, searchTerm);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <VStack spacing={4} align="stretch">
      <Text fontWeight="bold">
        All Users
      </Text>
      <Box>
        <HStack spacing={4} width="100%" direction={{ base: "column", md: "row" }}>
          <Input
          padding={'0.3vw'}

            placeholder="Search by email or name"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleKeyPress}
            width={{ base: "100%", md: "auto" }}
          />
          <Button colorScheme="blue"
          onClick={handleSearch} width={{ base: "100%", md: "auto" }}>
            Search
          </Button>
        </HStack>
      </Box>
      <Box bg={'#fff'} border="1px" borderColor="gray.200" borderRadius="md" p={2}>
        <Box bg={'#fff'} overflowX="auto">
          <Table >
          <TableHead headersProps={["First Name","Last Name","Email","Email Verified","Mobile No","Actions"]} />

            <Tbody  >
              {users.map((user) => (
                <Tr key={user.email}>
                  <Td>{user.first_name}</Td>
                  <Td>{user.last_name}</Td>
                  <Td>{user.email}</Td>
                  <Td>{user.email_verified ? "Yes" : "No"}</Td>
                  <Td>{user.mobile_no}</Td>
                  {/* <Td>{user.allowed_by_admin ? "Yes" : "No"}</Td>
                  <Td>{user.is_super_admin ? "Yes" : "No"}</Td>
                  <Td>{user.is_disabled ? "Yes" : "No"}</Td>
                  <Td>{user.all_bank_user ? "Yes" : "No"}</Td>
                  <Td>{user.all_features_user ? "Yes" : "No"}</Td> */}
                  <Td>
                    <Wrap spacing={2}>
                      <WrapItem>
                        <Button

                          colorScheme="blue"
                          size="sm"
                          padding={'0.3vw'}
                          onClick={() => handleEdit(user)}
                        >
                          Edit
                        </Button>
                      </WrapItem>
                      <WrapItem>
                        <Button

                          colorScheme="red"
                          size="sm"
                          padding={'0.3vw'}
                          onClick={() => handleResetPassword(user.email)}
                        >
                          Reset Password
                        </Button>
                      </WrapItem>
                    </Wrap>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Flex justify="center">
        {[...Array(totalPages)].map((_, i) => (
          <Button
            key={i}
            size="sm"
            colorScheme={i === page ? "blue" : "gray"}
            onClick={() => setPage(i)}
          >
            {i + 1}
          </Button>
        ))}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit User</ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Input
                placeholder="First Name"
                value={editedUser?.first_name || ""}
                onChange={(e) => handleInputChange("first_name", e.target.value)}
              />
              <Input
                placeholder="Last Name"
                value={editedUser?.last_name || ""}
                onChange={(e) => handleInputChange("last_name", e.target.value)}
              />
              <Input
                placeholder="Email"
                value={editedUser?.email || ""}
                isDisabled
              />
              <Input
                placeholder="Mobile No"
                value={editedUser?.mobile_no || ""}
                onChange={(e) => handleInputChange("mobile_no", e.target.value)}
              />

              <Checkbox
                isChecked={editedUser?.email_verified || false}
                onChange={(e) => handleInputChange("email_verified", e.target.checked)}
              >
                Email Verified
              </Checkbox>
              <Checkbox
                isChecked={editedUser?.allowed_by_admin || false}
                onChange={(e) => handleInputChange("allowed_by_admin", e.target.checked)}
              >
                Allowed By Admin
              </Checkbox>
              <Checkbox
                isChecked={editedUser?.is_super_admin || false}
                onChange={(e) => handleInputChange("is_super_admin", e.target.checked)}
              >
                Is Super Admin
              </Checkbox>
              <Checkbox
                isChecked={editedUser?.is_disabled || false}
                onChange={(e) => handleInputChange("is_disabled", e.target.checked)}
              >
                Is Disabled
              </Checkbox>
              <Checkbox
                isChecked={editedUser?.all_bank_user || false}
                onChange={(e) => handleInputChange("all_bank_user", e.target.checked)}
              >
                All Bank User
              </Checkbox>
              <Checkbox
                isChecked={editedUser?.all_features_user || false}
                onChange={(e) => handleInputChange("all_features_user", e.target.checked)}
              >
                All Features User
              </Checkbox>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
</VStack>
  )
}

export default AllUsersPage;