import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  Box,
  Grid,
  Progress,
  Flex,
  Badge,
  VStack,
  HStack,
  Divider,
} from "@chakra-ui/react";
import MobilePreview from "./MobilePreview";
import axiosInstance from "../../../Services/axiosInstance";
import TemplatePreviewModal from "./TemplatePreviewModal";
import DownloadReportModal from "../DownloadReportModal";
import * as _ from 'lodash'

function CampaignDetailsModal({ isOpen, onClose, data, rawData }) {
  const statusColors = {
    RUNNING: "#08830D",
    QUEUED: "cyan",
    RECURRING: "#08830D",
    SCHEDULED: "#836707",
    PROCESSING: "#836707",
    COMPLETED: "#08830D",
    ERROR: "#FE1B1B",
    WAITING: "#836707",
    STOPPED: "#FE1B1B",
    DRAFT: "#836707",
    ARCHIVED: "#FE1B1B",
  };

  const [filteredData, setFilteredData] = useState({});
  const [selectedTempFooter, setSelectedTempFooter] = useState({})
  const [selectedTempHeader,setSelectedTempHeader] = useState({})
  const [campaignReportModalOpen,setCampaignReportModalOpen] = useState(false)
  const [fullDetail, setFullDetail] = useState(_.find(rawData,{uuid:data.uuid}))
  const [previewOpen,setPreviewOpen] = useState(false)
  let combinedMarkup = ""
  debugger
  console.log(fullDetail)
  const getWhatsappTemplateDetails = (uuid) => {

    let url = `/whatsapp/template/${uuid}?orgToken=${data.org_token}`
    if(!data?.org_token){
      url = `/whatsapp/template/${uuid}`
    }
    axiosInstance.get(url).then((response) =>{
      // console.log(response.data.templates);
      setFilteredData(response?.data?.templates)
    })

  }
  const getSmsTemplateDetails = (uuid) => {
    let url = "/sms/template/" + uuid + "?orgToken=" + data?.org_token;
    if(!data?.org_token){
      url = "/sms/template/" + uuid 
    }
    axiosInstance.get(url).then((response) =>{
      setFilteredData(response.data)
    })
  }
  const getEmailTemplateDetails = (uuid) => {
    if(!!uuid){
      let url = `/emailtemplate/templates/${uuid}`
      if(!!data?.org_token){
        url = `/emailtemplate/templates/${uuid}?orgToken=${data?.org_token}`;
      };
      axiosInstance.get(url).then((response) =>{
      setFilteredData(response.data)
    }).catch((err) =>console.log(err))
    if(!!data?.campaignData?.selectedTempFooter){

      let footerUrl = `/emailtemplate/templates/${data?.campaignData?.selectedTempFooter}?orgToken=${data?.org_token}`;
      axiosInstance.get(footerUrl).then((response) =>{
        setSelectedTempFooter(response.data)
      })
    }
    if( !!data?.campaignData?.selectedTempHeader){
      let headerUrl = `/emailtemplate/templates/${data?.campaignData?.selectedTempHeader}?orgToken=${data?.org_token}`;
      axiosInstance.get(headerUrl).then((response) =>{
        setSelectedTempHeader(response.data)   
      })    
    }
  }
  }
  useEffect(() => {
    if(isOpen){
      
      if(data.channel == 'whatsapp' || data?.campaignData?.selectedChannel == 'whatsapp'){
        getWhatsappTemplateDetails(data.template)
      }
      if(data.channel == 'sms' || data?.campaignData?.selectedChannel == 'sms'){
        getSmsTemplateDetails(data.template || data.campaignData?.selectedTemp)
      }if(data.channel == 'email' || data?.campaignData?.selectedChannel == 'email'){
        
        getEmailTemplateDetails(data?.template || data?.campaignData?.selectedTemp ||  data?.campaignData?.scheduleData?.targetRatios[0]?.templateData?.bodyUuid)
      }
    }
    // ssetFilteredData(matchedData || {});
  }, [data, rawData]);

  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader fontSize="2xl" fontWeight="bold">
          Campaign Group Details
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack>

          <VStack spacing={6} align="stretch">
            {/* Control Group Statistics */}
            {data.controlGroupMetrics && (
              <Box>
                <Text fontSize="lg" fontWeight="bold" mb={4}>
                  Control Group Statistics
                </Text>
                <Grid
                  templateColumns={{ base: "1fr", md: "1fr 1fr" }}
                  gap={6}
                  >
                  {[
                    {
                      label: "Transaction Campaign Sent",
                      value:
                      data?.controlGroupMetrics?.transactionCampaignSent || 0,
                    },
                    {
                      label: "Transaction Campaign Not Sent",
                      value:
                      data?.controlGroupMetrics?.transactionCampaignNotSent || 0,
                    },
                    {
                      label: "Campaign Sent to Customers",
                      value:
                      data?.controlGroupMetrics?.campaignSentToCustomers || 0,
                    },
                    {
                      label: "Campaign Not Sent to Customers",
                      value:
                      data?.controlGroupMetrics?.campaignNotSentToCustomer || 0,
                    },
                  ].map((metric, index) => (
                    <Box
                    key={index}
                    p={4}
                    borderWidth="1px"
                    borderRadius="md"
                    boxShadow="sm"
                    bg="gray.50"
                    >
                      <Text fontWeight="semibold" color="gray.600">
                        {metric?.label}
                      </Text>
                      <Text fontSize="xl" fontWeight="bold">
                        {metric?.value}
                      </Text>
                    </Box>
                  ))}
                </Grid>
                <Box mt={8}>
                  <Text fontSize="lg" fontWeight="bold" mb={2}>
                    Sent vs. Not Sent
                  </Text>
                  <HStack justify="space-between">
                    <Text>
                      Sent:{" "}
                      {data?.controlGroupMetrics?.transactionCampaignSentPercentage ||
                        0}
                      %
                    </Text>
                    <Text>
                      Not Sent:{" "}
                      {data?.controlGroupMetrics?.transactionCampaignNotSentPercentage ||
                        0}
                      %
                    </Text>
                  </HStack>
                  <Progress
                    colorScheme="green"
                    value={
                      data?.controlGroupMetrics?.transactionCampaignSentPercentage ||
                      0
                    }
                    mt={2}
                    />
                </Box>
              </Box>
            )}

            {/* Campaign Statistics */}
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Campaign Statistics
              </Text>
              <Grid templateColumns="1fr 2fr" rowGap={4} columnGap={6}>
                {[
                  { label: "Name", value: data.name || data.campaignName },
                  {
                    label: "Channel",
                    value: (
                      <HStack>
                        <Text>{data.channel || data?.campaignData?.selectedChannel}  </Text>
                        <img
                          src={`${process.env.REACT_APP_API_ENDPOINT}/assets/img/broadcast-v2/create/${data?.channel || data?.campaignData?.selectedChannel }.svg`}
                          alt="channel icon"
                          style={{ height: 15, width: 15 }}
                          />
                      </HStack>
                    ),
                  },
                  {
                    label: "Status",
                    value: (
                      <Badge
                      colorScheme={statusColors[data?.status || data.displayStatus]}
                      px={3}
                      py={1}
                      >
                        {data?.status || data?.displayStatus }
                      </Badge>
                    ),
                  },
                  !!fullDetail?.campaignData?.scheduleAt && {
                    label: "Scheduled At",
                    value: new Date( fullDetail.campaignData.scheduleAt).toLocaleString(),
                  },
                  { label: "Total Sent", value: data?.messagesSentCount || 0 },
                  { label: "Total Suppressed", value: data?.suppressedCount || 0 },
                  { label: "Segment", value:  !_.isEmpty(fullDetail?.campaignData?.targetedAudience?.includes) && fullDetail?.campaignData?.targetedAudience?.includes[0]?.selectedSegmentName || "N/A" },
                  {
                    label: "Total Frequency Capped",
                    value: data.freqCappedCount || 0,
                  },
                  { label: "Total Duplicates", value: data?.duplicatesCount || 0 },
                  data.actionId && {
                    label: "Tracking ID",
                    value: data?.actionId,
                  },
                ]
                .filter(Boolean)
                .map((stat, index) => (
                  <React.Fragment key={index}>
                      <Text fontWeight="semibold">{stat.label}</Text>
                      <Text>{stat.value}</Text>
                      
                    </React.Fragment>
                  ))}
              </Grid>
            </Box>

           
            {( data?.campaignData?.selectedChannel === "email" || data?.channel === "email" || data?.channel === "onsitepush") && (
              <Box>
                <Text fontSize="lg" textDecoration={'underline'} cursor={'pointer'} onClick={()=>setPreviewOpen(true)} fontWeight="bold" mb={2}>
                  Show Template Preview
                </Text>
                
                {/* <a
                  href={`${process.env.REACT_APP_API_ENDPOINT}/broadcast/v2/preview/${data.template}/${data.channel}`}
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "blue",
                    marginBottom: 8,
                  }}
                  rel="noopener noreferrer"
                  >
                  Click here to see template preview
                </a> */}
              </Box>
            )}

            
          </VStack>
            {
             ( data.channel == 'email' ||  data?.campaignData?.selectedChannel == 'email') &&
            <TemplatePreviewModal isOpen={previewOpen} onClose={()=>setPreviewOpen(false)} tempHeader={selectedTempHeader?.body} tempFooter={selectedTempFooter?.body} tempBody={filteredData.body} />
            }
          {(data.channel === "sms" || data.channel === "whatsapp" || data?.campaignData?.selectedChannel=='sms' || data?.campaignData?.selectedChannel== 'whatsapp') && (
            <MobilePreview
            message={filteredData?.message}
            channel={data?.channel || data?.campaignData?.selectedChannel}
            buttons={filteredData?.buttons}
            attachment={filteredData?.attachments}
            />
          )}
          </HStack>
          <Text fontSize="lg" textDecoration={'underline'} cursor={'pointer'} onClick={()=>setCampaignReportModalOpen(true)} fontWeight="bold" mb={2}>
                    Get Full Report
                </Text>
                <DownloadReportModal uuid = {data.uuid} emailOnly={true} isOpen={campaignReportModalOpen} onClose={()=>setCampaignReportModalOpen(false)} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CampaignDetailsModal;
