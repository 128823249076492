import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Text,
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  useToken,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import { searhcSuperAdmin, getSuperAdminOrgs } from "../../Services/api";
import { searchDropDown } from "../../_helpers/data_helper";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import { OvalSpinner } from "../../components/Loaders";
import SuperAdminTable from "./SuperAdminTable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import hexToRgba from "hex-to-rgba";
import { Select } from "@chakra-ui/react";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";

const SuperAdmin = () => {
  const [fetchData, setFetchData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mainColor] = useToken("colors", ["primary.main"]);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState(null);
  const [selectedKey, setSelectedKey] = useState("");
  const token = useSelector((state) => state.auth.token);
  const navigate = useNavigate();
  const urlPath = window.location.pathname.split("/")[2];
  const isManager = urlPath === "manager";

  const fetchSuperOrgs = async (page) => {
    try {
      const response = await getSuperAdminOrgs(page);
      setFetchData(response.data.orgs);
      setSearchResults(response.data.orgs);
      setTotalCount(response.data.orgs.length);
    } catch (error) {
      console.log("Error while fetching super orgs");
    }
  };

  const getPayloadData = (selectedValue) => {
    const selectedItem = searchDropDown.find(
      (item) => item.key === selectedValue
    );
    
    return selectedItem
      ? { key: selectedItem.key, label: selectedItem.label }
      : { key: "", label: "" };
  };
  

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    total: totalCount,
    initialState: { currentPage: 1, pageSize: 5, isDisabled: false },
    limits: { outer: 1, inner: 1 },
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      setError(null);
      
      
      const { key: payloadKey } = getPayloadData(selectedKey);
  
      // Construct the payload
      const payload = {
        search_filter: selectedKey,  
        searchbox: values.searchbox,  
      };
  
      const response = await searhcSuperAdmin(payload);
      setSearchResults(response.data);
    } catch (error) {
      setError("Error submitting form: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  


  useEffect(() => {
    fetchSuperOrgs(currentPage - 1); 
  }, [currentPage, token]);

  return (
    <>
      <Flex gap={4} align="center" mb={4}>
        <Button
          variant={isManager ? "solid" : "outline"}
          size="sm"
          colorScheme={isManager ? "blue" : "gray"}
          onClick={() => navigate("/su/manager")}
        >
          Super Manager
        </Button>
        <Button
          variant="outline"
          size="sm"
          onClick={() => navigate("/su/sftp")}
        >
          SFTP
        </Button>
        <Button
                  variant="outline"
                  size="sm"
                  onClick={() => navigate("/su/allusers")}
                >
                  All Users
                </Button>
                 <Button
                  variant="outline"
                  size="sm"
                  onClick={() => navigate("/su/whitelabel")}
                >
                  Whitelabel
                </Button>
      </Flex>
      <Flex justifyContent="center" alignItems="center">
        <Box p={4}>
          <Text mb={2}>Search</Text>
          <Formik
            initialValues={{ searchbox: "", search_filter: "" }}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <Flex gap={4} align="center">
                  <Field name="searchbox">
                    {({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter Keyword"
                        width="500px"
                        // disabled={!selectedKey}
                      />
                    )}
                  </Field>

                  <Select
                    placeholder="Select option"
                    width="200px"
                    onChange={(e) => {
                      const selectedValue = e.target.value;
                      setSelectedKey(selectedValue);
                      setFieldValue("dropdown", selectedValue);
                    }}
                  >
                    {searchDropDown.map((item, index) => (
                      <option key={index} value={item.key}>
                        {item.label}
                      </option>
                    ))}
                  </Select>

                  <PrimaryButton type="submit" label="Search" />
                </Flex>
              </Form>
            )}
          </Formik>
        </Box>
      </Flex>

      <Box p={4}>
        {loading && (
          <Flex justifyContent="center" alignItems="center" height="200px">
            <OvalSpinner width={30} height={30} />
          </Flex>
        )}

        {error && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        )}

        {!loading &&
          !error &&
          (searchResults.length === 0 ? (
            <Text textAlign="center">No results found</Text>
          ) : (
            <>
              <SuperAdminTable orderData={searchResults} />
              {!!fetchData && (
                <Pagination
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                >
                  <PaginationContainer
                    alignItems={"center"}
                    justifyContent="end"
                    mt={2}
                  >
                    <PaginationPrevious
                      mr={2}
                      w={8}
                      bg={hexToRgba(mainColor, 0.4)}
                      p={1}
                      fontSize={"xs"}
                      height={8}
                      minW={8}
                    >
                      &lt;
                    </PaginationPrevious>
                    <PaginationPageGroup height={8}>
                      {pages.map((page) => (
                        <PaginationPage
                          key={`pagination_page_${page}`}
                          page={page}
                          w={10}
                          bg={hexToRgba(mainColor, 0.4)}
                          p={1}
                          fontSize={"xs"}
                          height={8}
                          minW={8}
                          isDisabled={currentPage === page}
                        />
                      ))}
                    </PaginationPageGroup>
                    <PaginationNext
                      ml={2}
                      w={8}
                      bg={hexToRgba(mainColor, 0.4)}
                      p={1}
                      fontSize={"xs"}
                      height={8}
                      minW={8}
                    >
                      &gt;
                    </PaginationNext>
                  </PaginationContainer>
                </Pagination>
              )}
            </>
          ))}
      </Box>
    </>
  );
};

export default SuperAdmin;
