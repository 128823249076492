
import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Input,
  Divider,
  FormControl,
  FormErrorMessage,
  useToast,
  FormLabel,
} from "@chakra-ui/react";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { syncAnalyticsDB, getSyncAnalyticsDB, getCurrency, getWalletDetails, setWalletCredits } from "../../Services/api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import PowerBiIntegration from "./PowerBiIntegration";
import { useSelector } from "react-redux";
import SyncCustomerDB from "./SyncCustomerDB";

const validationSchema = Yup.object({
  viewNames: Yup.string().required("Name is required"),
});

const OrgFeatures = () => {
  const navigate = useNavigate();
  const token = window.location.pathname.split("/")[3];
    const [wallet, setWallet] = useState({});
    const [currency, setCurrency] = useState("");
  const toast = useToast();
  const [syncData, setSyncData] = useState("");
  const orgInfo=useSelector((state)=>(state.auth.selectedOrg))
  // console.log(orgInfo,"orgIgo")

  const handleNavigation = () => {
    navigate(`/su/allUsers/${token}`);
  };
  const WalletCreditsForm = ({ currency, wallet, setCredits }) => {
    return (
      <Formik
        initialValues={{ amount: "", reason: "" }}
        onSubmit={(values, { resetForm }) => {
          setCredits(values, true);
          resetForm();
        }}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Flex align="center" justify="space-between" mb={4}>
              <Text fontSize="lg" fontWeight="bold">
                Wallet Credits 
                <Text>{currency} {wallet?.balance}</Text>
              </Text>
              
              <FormLabel p={'1rem'} m={'10px'}>
                Amount (+ / -)
                <Field as={Input} mt={'10px'} type="number" name="amount" required />
              </FormLabel>
              
              <FormLabel p={'1rem'} m={'10px'}>
                Reason (Optional)
                <Field as={Input} mt={'10px'} type="text" name="reason" />
              </FormLabel>
              
              <PrimaryButton mt={'10px'} label={'Submit'} type="submit" />
            </Flex>
          </Form>
        )}
      </Formik>
    );
  };
  
  
  const fetchSyncAnalytic = async () => {
    try {
      const response = await getSyncAnalyticsDB(token);
      
      setSyncData(response.data.data || "");
    } catch (error) {
      console.error("Error while fetching analytics:", error);
    }
  };
    const getCurrencyDetails = async () => {
      try {
        const response = await getCurrency();
        setCurrency(response.data.data.key);
        await getWalletInfo(response.data.data.key);
      } catch (err) {
        console.error("Error fetching currency:", err);
        
      }
    };
  
    const getWalletInfo = async () => {
      try {
        if (!currency) return;
        const response = await getWalletDetails(currency);
        setWallet(response?.data);
        
      } catch (err) {
        console.error("Error fetching wallet details:", err);
        
      }
    };
  const handleSync = async (values, { setSubmitting }) => {
    // console.log("Form data:", values);
    try {
      await syncAnalyticsDB({ viewNames: values.viewNames }, token);
      toast({
        title: "Success",
        description: "Synced successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await fetchSyncAnalytic();
    } catch (error) {
      console.error("Sync error:", error);
      toast({
        title: "Error",
        description: "Failed to sync",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };
  const setCredits = async (values, { setSubmitting }) => {
    // console.log("Form data:", values);
    try {
      let formData = new FormData()
      formData.append("walletAmount", values.amount)
      formData.append("amountReason", values.reason)
      formData.append("walletBalance", wallet.balance)
      formData.append("walletCurrency", currency)
      await setWalletCredits(formData, token);
      toast({
        title: "Success",
        description: "Synced successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await getCurrencyDetails();
    } catch (error) {
      
      toast({
        title: "Error",
        description: "Failed to set credits",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      // setSubmitting(false);
    }
  };
  
  useEffect(() => {
    fetchSyncAnalytic();
    getCurrencyDetails();
  }, [token]);

  return (
    <Box p={6} maxW="container.xl" mx="auto">
      <Flex direction="column" gap={6}>
        <Flex direction="column" gap={2}>
          <Text fontSize="2xl" fontWeight="bold">Manage Features for {orgInfo.name}</Text>
          <Text fontSize="xl" color="gray.600">Super Admin</Text>
          
        </Flex>

        <Divider my={4} />

        <Box>
        <WalletCreditsForm wallet={wallet} currency={currency} setCredits={setCredits}  />
        </Box>

        <Divider my={4} />

        <Box>
          <Flex align="center" justify="space-between" mb={4}>
            <Text fontSize="lg" fontWeight="bold">User Details</Text>
            <PrimaryButton label={"View Details"} onClick={handleNavigation} size="sm" />
          </Flex>
        </Box>

        <Divider my={4} />

        <Box>
          <Flex direction="column" gap={4}>
            <Flex align="center" justify="space-between" mb={4}>
              <Text fontSize="lg" fontWeight="bold">Sync Analytics DB</Text>
            </Flex>

            <Formik
              initialValues={{ viewNames: syncData }} 
              validationSchema={validationSchema}
              onSubmit={handleSync}
              enableReinitialize 
            >
              {({ isSubmitting }) => (
                <Form>
                  <FormControl isInvalid={!!(ErrorMessage && ErrorMessage.viewNames)}>
                    <Field name="viewNames">
                      {({ field }) => (
                        <Input
                          placeholder="Enter column names, separated by commas"
                          {...field}
                          size="md"
                        />
                      )}
                    </Field>
                    <ErrorMessage name="viewNames" component={FormErrorMessage} />
                  </FormControl>
                  <Box mt={4}>
                    <PrimaryButton label={"Submit"} type="submit" isLoading={isSubmitting} />
                  </Box>
                </Form>
              )}
            </Formik>
            <PowerBiIntegration />
            <SyncCustomerDB/>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default OrgFeatures;
