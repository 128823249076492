import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  Text,
  TableContainer,
  Flex,
  Table,
  useToken,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import {
  Pagination,
  PaginationContainer,
  PaginationNext,
  PaginationPage,
  PaginationPageGroup,
  PaginationPrevious,
  usePagination,
} from "@ajna/pagination";
import hexToRgba from "hex-to-rgba";

import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import OutlinedButton from "../../components/themeComponents/OutlinedButton";
import { OvalSpinner } from "../../components/Loaders";
// Replace with your actual API call
import { getAllContentPersonalization, deleteContentPersonalization } from "../../Services/api";
import ContentPersonalizationFilter from "./ContentPersonalizationFilter";
// A small TableHead component, or you can code inline
import TableHead from "./TableHead";
import ContentPersonalizationTable from "./ContentPersonalizationTable";

function ContentPersonalizationList() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // read the primary color from your Chakra theme
  const [mainColor] = useToken("colors", ["primary.main"]);

  // total results from server, for pagination
  const [totalCount, setTotalCount] = useState(0);

  // Filter object for searching, etc.
  const [filterState, setFilterState] = useState({
    keyword: "",
    // additional filter fields if needed
  });

  // Setup Ajna pagination
  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages,
    pageSize,
  } = usePagination({
    total: totalCount,
    initialState: { currentPage: 1, pageSize: 10, isDisabled: false },
    limits: { outer: 1, inner: 1 },
  });

  // React Query to fetch list
  const cpQuery = useQuery(
    ["contentPersonalizationList", currentPage, filterState],
    {
      queryFn: async () => {
        // call your getCPList with current page and filters
        const res = await getAllContentPersonalization(currentPage, filterState);
        // Suppose res = { data: [...], count: 100 }
        setTotalCount(res?.count ?? 0);
        return res.data || [];
      },
      keepPreviousData: true,
      retry: 1,
      // disable autoFetch on mount if needed:
      // enabled: false
    }
  );

  // Example: on mount, or when filters change, refetch
  useEffect(() => {
    cpQuery.refetch();
    // eslint-disable-next-line
  }, [currentPage, filterState]);

  // Example toggling archived or something else
  const toggleArchived = (archived, id) => {
    // optional, if you want to do immediate UI updates
    const existingData = cpQuery.data || [];
    const updatedData = existingData.map((item) => {
      if (item.id === id) {
        return { ...item, archived: archived };
      }
      return item;
    });
    // update local cache
    queryClient.setQueryData(
      ["contentPersonalizationList", currentPage, filterState],
      updatedData
    );
  };

  return (
    <Fragment>
      <Box
        h={"100%"}
        overflow={"hidden"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} mt={1}>
          <Text fontSize={"xl"} as={"b"}>
            Content Personalization
          </Text>

          {/* Button to create a new record */}
          <OutlinedButton
            mb={"5px"}
            label={"Create New"}
            onClick={() => navigate("/content-personalization/new")}
          />
        </Box>

        {/* Filter Component */}
        <ContentPersonalizationFilter onFilter={(newState) => setFilterState(newState)} />

        <TableContainer bg={"white"} position={"relative"} p={3} overflowY={"auto"}>
          {/* Loading state */}
          {cpQuery.isLoading && (
            <Flex justifyContent={"center"} alignItems={"center"} h={"200px"}>
              <OvalSpinner width={50} height={50} />
            </Flex>
          )}

          <Table>
            {!cpQuery.isLoading && cpQuery.data?.length > 0 ? (
              <Fragment>
                <TableHead headersProps={["Name", "Description","Created", "Actions"]} />
                <ContentPersonalizationTable
                  list={cpQuery.data}
                  refetch={() => cpQuery.refetch()}
                />
              </Fragment>
            ) : (
              !cpQuery.isLoading && (
                <Flex direction="column" justifyContent="center" alignItems="center" py={10}>
                  <Text fontSize="lg" fontWeight="bold" color="gray.600">
                    No records found.
                  </Text>
                  <Text fontSize="sm" color="gray.500" mt={2}>
                    Create a new record to get started.
                  </Text>
                  <PrimaryButton
                    label="Create Now"
                    mt={4}
                    onClick={() => navigate("/content-personalization/new")}
                    size="md"
                  />
                </Flex>
              )
            )}
          </Table>
        </TableContainer>

        {/* Pagination */}
        {/* Show pagination only if data is loaded & there's more than one page */}
        {cpQuery.data && cpQuery.data.length > 0 && pagesCount > 1 && (
          <Pagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer alignItems={"center"} justifyContent="end" mt={2}>
              <PaginationPrevious
                mr={2}
                w={8}
                bg={hexToRgba(mainColor, 0.4)}
                p={1}
                fontSize={"xs"}
                minH={8}
                height={8}
                minW={8}
              >
                &lt;
              </PaginationPrevious>
              <PaginationPageGroup height={8}>
                {pages.map((page) => (
                  <PaginationPage
                    minH={8}
                    key={`pagination_page_${page}`}
                    page={page}
                    w={8}
                    bg={hexToRgba(mainColor, 0.4)}
                    p={1}
                    fontSize={"xs"}
                    height={8}
                    minW={8}
                    isDisabled={currentPage === page}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext
                ml={2}
                w={8}
                minH={8}
                bg={hexToRgba(mainColor, 0.4)}
                p={1}
                fontSize={"xs"}
                height={8}
                minW={8}
              >
                &gt;
              </PaginationNext>
            </PaginationContainer>
          </Pagination>
        )}
      </Box>
    </Fragment>
  );
}

export default ContentPersonalizationList;
