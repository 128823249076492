import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import store from './store/store';
import hexToRgba from 'hex-to-rgba';
import '@fontsource/dm-sans'
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
let brandColor =  {
  50: hexToRgba('#594DA3', 0.2),
  100: hexToRgba('#594DA3', 0.3),
  200: hexToRgba('#594DA3', 0.5),
  300: hexToRgba('#594DA3', 0.5),
  full: hexToRgba('#594DA3', 1)
}
export const theme = extendTheme({
  fonts: {
    heading: `'DM Sans', sans-serif`,
    body: `'DM Sans', sans-serif`
  },
  components: {
    Text: {
      baseStyle: {
        color: '#00195a'
      }
    },
    Heading:{
        color: '#723FA3'
    },
    
    Button: {
      baseStyle: {
        fontWeight: '400',
        borderRadius: '2px'
      }
    },
    Switch: {
      defaultProps:{
        colorScheme: 'teal'
      }
    }
  },
  initialColorMode: 'system',
  useSystemColorMode: true,
  styles: {
    global: () => ({
      body: {
        bg: "gray.50",
      },
      colors: {
        brand: {
          primary: '#594DA3'
        }
      }
    }),
  },
  colors: {
    primary: {
      main: "#594DA3",
    },
    brand: {
      50: hexToRgba('#594DA3', 0.2),
      100: hexToRgba('#594DA3', 0.3),
      200: hexToRgba('#594DA3', 0.5),
      300: hexToRgba('#594DA3', 0.5),
      full: hexToRgba('#594DA3', 1)
    },
    secondary: {
      main: "black"
    },
    light: '#718096',
    inputLabel: '#495057',
    sidebar: 'white',
    danger: '#E53E3E',
    link: '#3182ce',
    success: '#38A169',
    heading: "#2D3748",
    stripTable: '#6D6D6D',
    chartColors: {
      color1: '#65a6fa',
      color2: '#00cadc',
      color3: '#1cbbf3'
    }
  },
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App></App>
</QueryClientProvider>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
