import React from "react";
import { Thead, Tr, Th } from "@chakra-ui/react";

const TableHead = ({ headersProps }) => {
  return (
    <Thead>
      <Tr>
        {headersProps.map((header) => (
          <Th key={header}>{header}</Th>
        ))}
      </Tr>
    </Thead>
  );
};

export default TableHead;
