import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getWhiteLabelEntities } from "../../Services/api";
import "./Whitelabel.css";
import moment from "moment-timezone";

const Whitelabel = () => {
  const [whiteLabelEntities, setWhiteLabelEntities] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const perPage = 10;
  const userTimezone = moment.tz.guess();

  useEffect(() => {
    fetchWhiteLabelEntities(currentPage);
  }, [currentPage]);

  const fetchWhiteLabelEntities = async (page) => {
    try {
      const response = await getWhiteLabelEntities(page, perPage);
      setWhiteLabelEntities(response?.data?.items || []);
      setTotalItems(response?.data?.count || 0);
    } catch (error) {
      console.error("Error fetching white label entities", error);
      setWhiteLabelEntities([]);
      setTotalItems(0);
    }
  };

  return (
    <div className="container mt-5 whitelabel-container">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="whitelabel-title">White Label Overview</h1>
        <a href="/su/whitelabel-edit" className="btn whitelabel-create-btn">+ Create New White Label App</a>
      </div>
      {whiteLabelEntities.length === 0 ? (
        <div className="alert alert-info text-center whitelabel-alert">No White Label Apps found!</div>
      ) : (
        <div className="table-responsive">
          <table className="table table-striped table-bordered whitelabel-table">
            <thead className="thead-dark">
              <tr>
                <th>Name</th>
                <th>Created Date</th>
                <th>Last Updated</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {whiteLabelEntities.map((entity) => (
                <tr key={entity.domain || entity.id}>
                  <td>{entity.entityName || "N/A"}</td>
                  <td>{entity.createdAt ? moment.tz(entity.createdAt, userTimezone).format("Do MMMM YYYY, h:mm A") : "N/A"}</td>
                  <td>{entity.updatedAt ? moment.tz(entity.updatedAt, userTimezone).format("Do MMMM YYYY, h:mm A") : "Never"}</td>
                  <td>
                    <a href={`/su/whitelabel-edit?domain=${entity.domain}`} className="btn whitelabel-edit-btn">Edit</a>
                    <button className="btn whitelabel-delete-btn" onClick={() => console.log("Delete action")}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="d-flex justify-content-center mt-3">
        <button className="btn whitelabel-pagination-btn" disabled={currentPage === 0} onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
        <span className="mx-3 whitelabel-page-info">Page {currentPage + 1}</span>
        <button className="btn whitelabel-pagination-btn" disabled={(currentPage + 1) * perPage >= totalItems} onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
      </div>
    </div>
  );
};

export default Whitelabel;
