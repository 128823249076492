import {
    Text, Flex, Box, Grid, useToken, Container, Select, FormLabel, FormControl, Input, Icon,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, useDisclosure,
    VStack,
    Textarea,
    Checkbox,
    RadioGroup,
    Radio
} from '@chakra-ui/react'
import React, { Fragment, useEffect, useState, useRef } from 'react'
import { useQuery } from 'react-query'
import { connect } from 'react-redux'
import { getSegmentationProps } from '../../Services/api'

import { OvalSpinner } from '../../components/Loaders'
import { FieldArray, FormikProvider, useFormik } from 'formik'
//import { Grid } from 'react-loader-spinner'
import { getCountries, getCriteriaJson, prepareSegmentationProps } from './SegmentDataHelper'
import CountryDropdown from '../../components/CountryDropdown'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import { FaTrash } from 'react-icons/fa'
import SecondaryButton from '../../components/themeComponents/SecondaryButton'
import SaveSegmentModal from './SaveSegmentModal'
import { segmentationSchema } from '../../_helpers/validationSchema'
import * as _ from "lodash"
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/github.css'; 
import CodeEditor from './CodeEditor'
import { CustomOrm } from './CutsomOrm'
import sql from 'highlight.js/lib/languages/sql';
import { objectKeys } from 'react-querybuilder'
import { TbCloudSearch } from 'react-icons/tb'
import store from '../../store/store'
import UploadFIle from './UploadFIle'
import { useLocation } from 'react-router-dom'
hljs.registerLanguage('sql', sql);

function CreateSegment({ formikParent,isAnalyticsIntegrated, orgId ,closeSegmentModal,setSegmentId,orgToken}) {
    
    orgId = orgToken || store.getState()?.auth?.selectedOrg?.token
    const highlightedRef = useRef(null);
    
    const [selectedTarget, setSelectedTarget] = useState("");
    const [mainColor] = useToken('colors', ['primary.main']);
    const [canQuery, setCanQuery] = useState(false);
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [rawQuery, setRawQuery] = useState('');
    const [optForQuery, setOptForQuery] = useState(false);
    const [isCustomOrm, setIsCustomOrm] = useState(false);
    const [isFileUpload, setIsFileUpload] = useState(false);
    const [finalizedQuery, setFinalizedQuery] = useState({});
    const [percentage, setPercentage] = useState("100");
    const [combinedQuery, setCombinedQuery] = useState("");
    const [union, setUnion] = useState([""]);
    const [uploadedFileUrl, setUploadedFileUrl] = useState("")
    const [segmentType, setSegmentType] = useState("default")
    const segmentationPropsQuery = useQuery(['segmentationQuery'], {
        queryFn: async () => {
            let resp = await getSegmentationProps(orgToken);
            if (resp?.data?.targets && resp?.data?.targets?.length > 0) {

                formik.setValues({
                    "criteria_type": "intermediate",
                    "criteria_operation": "and",
                    "criteria": [
                        {
                            "criteria_type": "query",
                            "property": !_.isEmpty(resp?.data) && Object.keys(resp?.data['segmentationProps'][resp?.data?.targets[0].value])[0],
                            "target": resp?.data['targets'][0].value,
                            "operator": "",
                            "data_type": "",
                            "values": [
                                {
                                    "raw": 0
                                }
                            ]
                        }
                    ]

                })
            } else {
                console.warn('No targets found in response');
            }

            setCanQuery(resp.data.canQuery)
            return prepareSegmentationProps(resp.data);
        },
        cacheTime: 0,
        //retry: 0
        // enabled: false
    })
    const formik = useFormik({
        initialValues: {
        },
        // validationSchema: segmentationSchema,
        onSubmit: () => {
            onOpen()
        }

    })
    useEffect(() => {
        Object.keys(finalizedQuery).forEach((e) => {
            if (e != 0) {

                setCombinedQuery((finalizedQuery[e - 1]) + " " + "union" + " " + finalizedQuery[e] + " ")
            } else {
                setCombinedQuery(finalizedQuery["0"])
            }
            // console.log(combinedQuery)
            setRawQuery(combinedQuery)
           
            // console.log(rawQuery)
        })
        if (highlightedRef.current) {
            const highlighted = hljs.highlight(combinedQuery, { language: 'sql' }).value;
            highlightedRef.current.innerHTML = highlighted;
        }
        // generateQuery()
    }, [finalizedQuery,combinedQuery]);
    const queryObject = function () {
        var that = {};
        that.criteria_type = "query";
        that.target = segmentationPropsQuery?.data?.targets[0].value;
        that.property = "";
        that.operator = "";
        that.data_type = "";
        that.values = [{
            raw: null
        }];
        return that;
    };
    // let getCombinedQuery = ()=>{

    //     Object.keys(finalizedQuery).forEach((e)=>{
    //         setCombinedQuery( combinedQuery + " " + finalizedQuery[e] + " " + ((e == "0" || e <= Object.keys(finalizedQuery).length-1 ) ? "union" : ""))
    //         console.log(combinedQuery)
    //         setRawQuery(combinedQuery)
    //         // return combinedQuery; 

    //      }) 
    // }

    useEffect(() => {
        
        segmentationPropsQuery.refetch()
        if(window.location.href.includes("all-banks")){
            setSegmentType('optForQuery')
        }
        
        // console.log(segmentationPropsQuery)

    }, [])
    
    return (
        <Fragment>
            <Box w={'100%'} h={'100%'} overflow={'hidden'}>

                <Flex justifyContent={'space-between'}>
                    <Text as={'b'} fontSize={'2xl'}>Create Segment</Text>
                </Flex>

                <Box height={'90%'} overflow={'hidden'}>
                    {
                        !!segmentationPropsQuery.isLoading && <Box display={'grid'} placeItems={'center'} h={'100vh'} width={'100%'}>
                            <OvalSpinner height={'100px'} width={'100px'} color={mainColor} label="Preparing Segment Form...">
                            </OvalSpinner>
                        </Box>
                    }
                    {
                        !segmentationPropsQuery.isLoading && !segmentationPropsQuery?.data?.targets.length && 
                        <Text color={'red'} >
                            Error in fetching data
                        </Text>
                    }
                    {
                         !segmentationPropsQuery.isLoading && !!segmentationPropsQuery?.data?.targets.length && (
                            <Container maxW={'1230px'} bg={'white'} p={3} h={'calc(100% - 110px)'} overflowY={'scroll'} >
                                {
                                 !window.location.href.includes("all-banks") &&   !!canQuery &&
                                    <Flex gap={3} alignItems={'baseline'} >
                                        <RadioGroup defaultValue='default' display={'flex'} gap={'15px'} >
                                        <Radio value={"default"} name='default' checked={segmentType=='default'} onChange={(e) => {
                                            setSegmentType(e.target.value)
                                        }} >
                                            Default
                                        </Radio>
                                        <Radio value={"optForQuery"} name='optForQuery' onChange={(e) => {
                                            setSegmentType(e.target.value)
                                        }} >
                                            Raw query
                                        </Radio>
                                        <Radio value={"customOrm"} name='customOrm' onChange={(e) => {
                                            setSegmentType(e.target.value)
                                            
                                        }}>
                                        Custom ORM query
                                        </Radio>
                                        
                                        <Radio value={"fileUpload"} name='fileUpload' onChange={(e) => {
                                            setSegmentType(e.target.value)
                                        }} >
                                        
                                        Upload File
                                        </Radio>
                                        </RadioGroup>
                                        
                                    </Flex>
                                }
                                {
                                    segmentType == 'optForQuery' &&
                                    <>
                                        <CodeEditor audienceRange={percentage} setAudienceRange={setPercentage}  formik={formikParent}  orgId={orgId} sendQuery={setRawQuery} />
                                    </>
                                }
                                {
                                    segmentType == 'fileUpload'  && <>
                                    <UploadFIle orgId={orgId} uploadedFileUrl={uploadedFileUrl} setUploadedFileUrl={setUploadedFileUrl} />
                                    </>
                                }
                                {
                                    segmentType == 'customOrm'  &&
                                    <>
                                    {
                                        union.map((e, i) => (
                                            <CustomOrm setUnion={setUnion} finalizedQuery={finalizedQuery} setFinalizedQuery={setFinalizedQuery} setPercentage={setPercentage} orgId={orgId} sendQuery={setRawQuery} union={union} segmentationData={segmentationPropsQuery.data} i={i} />
                                        ))
                                    }
                                        {/*                                         
                                        <CustomOrm finalizedQuery={finalizedQuery} setFinalizedQuery={setFinalizedQuery} setPercentage={setPercentage} orgId={orgId} sendQuery={setRawQuery} segmentationData={segmentationPropsQuery.data} /> */}
                                        <VStack alignItems={'start'}>
                                            {/* {JSON.stringify(finalizedQuery)} */}
                                            {
                                                !!finalizedQuery[0] && <>
                                                    <FormLabel>Generated Query</FormLabel>
                                                    <Box
                                                        minW={'700px'}
                                                        maxW={'700px'}
                                                        ref={highlightedRef}
                                                        className="hljs" // Apply Highlight.js styles
                                                        w="100%"
                                                        p="4"
                                                        borderWidth="1px"
                                                        borderRadius="md"
                                                        bg="gray.100"
                                                        fontFamily="monospace"
                                                        overflow="auto"
                                                    >
                                                        <Text whiteSpace="pre-wrap">
                                                            {JSON.stringify(combinedQuery)} 
                                                        </Text>
                                                    </Box>
                                                </>
                                            }

                                        </VStack>
                                    </>
                                }
                                {/* {JSON.stringify(formik.values.criteria)} */}
                                {
                                    (segmentType == 'default') &&
                                    <>
                                        <Box height={'calc(100% - 50px)'}>
                                            <FormikProvider value={formik}>

                                                <FieldArray name='criteria' render={arrayHelpers => (
                                                    <Fragment>

                                                        {
                                                            formik?.values?.criteria.length && formik?.values?.criteria.map((criterion, index) => (
                                                                <VStack >

                                                                    {
                                                                        !!segmentationPropsQuery?.data?.targets && <Container overflowY={'scroll'} maxW={'1230px'} mb={4}>
                                                                            <Flex gap={3} o>

                                                                                <FormControl maxW={'350px'}>
                                                                                    <FormLabel color={'inputLabel'}>Select Target</FormLabel>
                                                                                    <Select name={`criteria[${index}].target`} onChange={(e) => {

                                                                                        setSelectedTarget(e.target.value.toLowerCase())
                                                                                        // console.log(segmentationPropsQuery.data.props[e.target.value.toLowerCase()][0])
                                                                                        let property = Object.keys(segmentationPropsQuery.data.props[e.target.value.toLowerCase()])[0]
                                                                                        formik.setFieldValue(`criteria[${index}]`, {
                                                                                            "criteria_type": "query",
                                                                                            "property": property,
                                                                                            "target": e.target.value.toLowerCase(),
                                                                                            "operator": "greater_than",
                                                                                            "data_type": !_.isEmpty(segmentationPropsQuery?.data?.props[e.target.value.toLowerCase()][property]['dataType']) && segmentationPropsQuery?.data?.props[e.target.value][property]['dataType'],
                                                                                            "values": [
                                                                                                {
                                                                                                    "raw": 0
                                                                                                }
                                                                                            ]
                                                                                        })
                                                                                        formik.setFieldValue(`criteria[${index}].target`, e.target.value.toLowerCase())
                                                                                        // console.log(segmentationPropsQuery?.data.props[criterion.target]?.[criterion.property])
                                                                                        // console.log(formik.values)
                                                                                    }} >
                                                                                        {
                                                                                            segmentationPropsQuery?.data.targets.map(target => (
                                                                                                <option key={target.value} value={target.value}>{target.key}</option>
                                                                                            ))
                                                                                        }
                                                                                    </Select>
                                                                                </FormControl>

                                                                                <FormControl maxW={'350px'}>
                                                                                    <FormLabel color={'inputLabel'}>Filter</FormLabel>
                                                                                    <Select value={formik.values.criteria_operation} onChange={(e) => formik.setFieldValue("criteria_operation", e.target.value)}>
                                                                                        <option value={'and'}>And</option>
                                                                                        <option value={'or'}>At Least One</option>
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </Flex>
                                                                        </Container>
                                                                    }


                                                                    <Flex gap={3} key={index} mt={3} alignItems={'start'} w={'100%'} >
                                                                        <Select maxW={'350px'} name={`criteria.${index}.property`} value={formik?.values?.criteria[index]?.property} onChange={(e) => {
                                                                            formik.setFieldValue(`criteria.${index}.property`, e.target.value);
                                                                            criterion.data_type = !_.isEmpty(segmentationPropsQuery?.data?.props[criterion.target][e.target.value]['dataType']) && segmentationPropsQuery?.data?.props[criterion.target][e.target.value]['dataType']
                                                                            formik.setFieldValue(`criteria.${index}.data_type`, segmentationPropsQuery?.data?.props[criterion.target][e.target.value]['dataType']);
                                                                            // console.log(segmentationPropsQuery?.data?.props[e.target.value], e.target.value)
                                                                        }
                                                                        }
                                                                            placeholder='Select Property'>
                                                                            {
                                                                                !!segmentationPropsQuery?.data?.props[criterion.target || selectedTarget] && Object.keys(segmentationPropsQuery?.data?.props[criterion.target || selectedTarget]).map(prop => {
                                                                                    const obj = segmentationPropsQuery?.data?.props[criterion.target || selectedTarget];
                                                                                    return <option key={prop} value={prop}>{obj[prop].name}</option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                        {!!criterion.property &&

                                                                            <Fragment>
                                                                                <Select maxW={'350px'} name={`criteria.${index}.operator`} value={formik.values.criteria[index].operator} onChange={(e) => formik.setFieldValue(`criteria.${index}.operator`, e.target.value)} placeholder='Select Operator'>
                                                                                    {
                                                                                        !!segmentationPropsQuery?.data.operators[criterion.target][formik.values.criteria[index]?.property] && segmentationPropsQuery?.data.operators[criterion.target][formik.values.criteria[index]?.property].map(operator => {
                                                                                            return <option key={operator.value} value={operator.value}>{operator.key}</option>
                                                                                        })
                                                                                    }
                                                                                </Select>

                                                                                <FieldArray name={`criteria.${index}.values`} render={(helper) => (

                                                                                    <Fragment>
                                                                                        <Flex direction={'column'}>
                                                                                            {
                                                                                                criterion.values.map((value, $idx) => {
                                                                                                    if (segmentationPropsQuery?.data.props[criterion.target][criterion.property]?.inputType !== 'dropdown') {
                                                                                                        return (
                                                                                                            <Flex gap={2} mb={2}>
                                                                                                                {
                                                                                                                    segmentationPropsQuery?.data.props[criterion.target || selectedTarget]?.[criterion.property]?.inputType === 'number' ? <NumberInput defaultValue={formik.values.criteria[index].values[$idx].raw} min={0} key={$idx} name={`criteria.${index}.values.${$idx}.raw`} onChange={(val) => formik.setFieldValue(`criteria.${index}.values.${$idx}.raw`, val)}>
                                                                                                                        <NumberInputField />
                                                                                                                        <NumberInputStepper>
                                                                                                                            <NumberIncrementStepper />
                                                                                                                            <NumberDecrementStepper />
                                                                                                                        </NumberInputStepper>
                                                                                                                    </NumberInput> : <Input type={segmentationPropsQuery?.data?.props[criterion.target || selectedTarget]?.[criterion.property].inputType} value={formik.values?.criteria[index]?.values[$idx]?.raw} key={$idx} name={`criteria.${index}.values.${$idx}.raw`} onChange={formik.handleChange}></Input>
                                                                                                                }
                                                                                                                <SecondaryButton label={'OR'} onClick={() => helper.push({ raw: null })}></SecondaryButton>
                                                                                                                {
                                                                                                                    $idx > 0 && <SecondaryButton label={'Remove'} onClick={() => helper.remove($idx)}></SecondaryButton>
                                                                                                                }

                                                                                                            </Flex>
                                                                                                        )
                                                                                                    }

                                                                                                    if (segmentationPropsQuery?.data.props[criterion.target || selectedTarget][criterion.property]?.inputType === 'dropdown') {
                                                                                                        if (criterion.property === 'country') {
                                                                                                            return <Flex gap={2} mb={2}>
                                                                                                                <CountryDropdown name={`criteria.${index}.values.${$idx}.raw`} value={formik.values.criteria[index].values[$idx].raw} onChange={(e) => formik.setFieldValue(`criteria.${index}.values.${$idx}.raw`, e.target.value)}></CountryDropdown>
                                                                                                                <SecondaryButton label={'OR'} onClick={() => helper.push({ raw: null })}></SecondaryButton>
                                                                                                                {
                                                                                                                    $idx > 0 && <SecondaryButton label={'Remove'} onClick={() => helper.remove($idx)}></SecondaryButton>
                                                                                                                }

                                                                                                            </Flex>
                                                                                                        } else {
                                                                                                            return <Flex gap={2} mb={2}><Select name={`criteria.${index}.values.${$idx}.raw`} value={formik.values.criteria[index].values[$idx].raw} onChange={(e) => formik.setFieldValue(`criteria.${index}.values.${$idx}.raw`, e.target.value)}>
                                                                                                                {
                                                                                                                    getCriteriaJson(criterion.property).map(prop => {
                                                                                                                        return <option key={prop.code} value={prop.name}>{prop.name}</option>
                                                                                                                    })
                                                                                                                }

                                                                                                            </Select>
                                                                                                                <SecondaryButton label={'OR'} onClick={() => helper.push({ raw: null })}></SecondaryButton>
                                                                                                                {
                                                                                                                    $idx > 0 && <SecondaryButton label={'Remove'} onClick={() => helper.remove($idx)}></SecondaryButton>
                                                                                                                }

                                                                                                            </Flex>
                                                                                                        }

                                                                                                    }

                                                                                                })
                                                                                            }
                                                                                        </Flex>

                                                                                    </Fragment>
                                                                                )}>

                                                                                </FieldArray>

                                                                            </Fragment>

                                                                        }
                                                                        {
                                                                            formik.values.criteria.length > 1 && <Icon as={FaTrash} cursor={'pointer'} onClick={() => arrayHelpers.remove(index)}></Icon>
                                                                        }
                                                                    </Flex>
                                                                </VStack>
                                                            ))
                                                        }
                                                        {
                                                            <PrimaryButton label={'Add'} onClick={() => arrayHelpers.push(new queryObject())} mt={2}></PrimaryButton>
                                                        }

                                                    </Fragment>

                                                )}>

                                                </FieldArray>
                                            </FormikProvider>
                                        </Box>
                                    </>
                                }

                                <Flex justifyContent={'flex-end'} gap={3}>
                                    <SecondaryButton label={'Clear'} onClick={() => 
                formik.setValues({
                    "criteria_type": "intermediate",
                    "criteria_operation": "and",
                    "criteria": [
                        {
                            "criteria_type": "query",
                            "property": !_.isEmpty(segmentationPropsQuery) && Object.keys(segmentationPropsQuery?.data['props'][segmentationPropsQuery?.data?.targets[0].value])[0],
                            "target": segmentationPropsQuery?.data['targets'][0].value,
                            "operator": "",
                            "data_type": "",
                            "values": [
                                {
                                    "raw": 0
                                }
                            ]
                        }
                    ]
                    
                })}></SecondaryButton>
                                    <PrimaryButton label={'Create'} onClick={formik.handleSubmit}></PrimaryButton>
                                </Flex>
                            </Container>
                        )
                    }
                </Box>
            </Box>
            <SaveSegmentModal uploadedFileUrl = {uploadedFileUrl} isFileUpload = {isFileUpload} formikParent={formikParent} setSegmentId={setSegmentId} orgToken={orgToken} closeSegmentModal={closeSegmentModal} percentage={percentage} isCustomOrm={isCustomOrm} isOpen={isOpen} raw_query={rawQuery} optForQuery={optForQuery} onClose={onClose} segmentData={formik.values} orgId={orgId} selectedTarget={selectedTarget} segmentType = {segmentType} ></SaveSegmentModal>
        </Fragment>
    )

}

const mapStatetoProps = (state) => {
    return {
        ...state,
        isAnalyticsIntegrated: state.auth.selectedOrg.isIntegrated,
        orgId: state.auth.selectedOrg.token
    }
}

export default connect(mapStatetoProps)(CreateSegment)
