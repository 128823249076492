

const getNumberOperators = () => {
    return [
        { key: 'Greater Than or Equal To', value: 'greater_than_or_equal_to' },
        { key: 'Greater Than', value: 'greater_than' },
        { key: 'Not Equals', value: 'not_equals' },
        { key: 'Equals', value: 'equals' },
        { key: 'Less Than', value: 'less_than' },
        { key: 'Less Than or Equal To', value: 'less_than_or_equal_to' }
    ];
};
const getEnumOperators = () => {
    return [
        { key: 'Is', value: 'equals' },
        { key: 'Is Not', value: 'not_equals' },
        { key: 'Contains', value: 'contains' },
        { key: 'Does Not Contain', value: 'not_contains' }
    ];
};

const getStringOperators = () => {
    return [
        { key: 'Contains Ignore Case', value: 'contains_ignore_case' },
        { key: 'Contains', value: 'contains' },
        { key: 'Is', value: 'equals' },
        { key: 'Is Not', value: 'not_equals' },
        { key: 'Does Not Contain', value: 'not_contains' },
        { key: 'Does Not Contain Ignore Case', value: 'not_contains_ignore_case' },
        { key: 'Starts With', value: 'starts_with' },
        { key: 'Ends With', value: 'ends_with' }
    ];
};

const getDateOperators = () => {
    return [
        { key: 'Is After', value: 'greater_than' },
        { key: 'Is', value: 'equals' },
        { key: 'Is Not', value: 'not_equals' },
        { key: 'Is After or On', value: 'greater_than_equals' },
        { key: 'Is Before', value: 'less_than' },
        { key: 'Is before or on', value: 'less_than_equals' }
    ];
};
let operatorsByDataTypes = {
    string: getStringOperators(),
    number: getNumberOperators(),
    text : getStringOperators(),
    boolean: getEnumOperators(),
    decimal: getNumberOperators(),
    datetime: getDateOperators(),
    date: getDateOperators(),
}
export const prepareOperatorsForSegmentation = (data) => {
    let operators = {};
    for (let key in data) {
        operators[key] = {};
        for (let subKey in data[key]) {      
            operators[key][subKey] = operatorsByDataTypes[data[key][subKey].dataType];
            if(!data[key][subKey].hasOwnProperty("inputType")) {
                data[key][subKey]['inputType'] = data[key][subKey].dataType
            }
        }
    }
    return operators;
}
export const prepareSegmentationProps = (data) => {
    if (!data) return {};
    let retVal = {
        targets: data?.targets || [],
        props: data?.segmentationProps || [],
        operators: prepareOperatorsForSegmentation(data?.segmentationProps)

    }
    return retVal;
}
const getDeviceTypes = () => {
    return [
        { name: 'Mobile', code: 'mobile' },
        { name: 'Desktop', code: 'desktop' },
        { name: 'Tablet', code: 'tablet' }
    ]
};
const getDataSources = () => {
    return [
        { name: 'CSV Upload', code: 'CSV' },
        { name: 'Store', code: 'STORE' },
        { name: 'Others', code: '' },
    ]
};

const getStatusTypes = (target) => {
    return [
        { name: 'Active', code: 'active' },
        { name: 'At Risk', code: 'at_risk' },
        { name: 'Lost', code: 'lost' }
    ]
}
export const getCountries = async () => {
    let res = fetch("/json/countries.json")
    return res
}
export const getCriteriaJson = (criteria) => {
    let retVal = {
        device: getDeviceTypes(),
        status: getStatusTypes(),
        data_source: getDataSources(),

        //fulfillment_status: fulfilmentStatus
    }
    return retVal[criteria]
}

export const getType = (target) => {
    return 'model';
}

export const getListingType = (target) => {
    if ( target === 'customers') {
        return 'customer'
    }
    return target;
}