import React, { Fragment } from 'react'
import { ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
     FormLabel, Input, Textarea, FormControl, Checkbox, Box, InputGroup, InputRightAddon, Select, FormErrorMessage } from '@chakra-ui/react'
import SecondaryButton from '../../../components/themeComponents/SecondaryButton'
import PrimaryButton from '../../../components/themeComponents/PrimaryButton'
import { useFormik, Field } from 'formik'
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from "react-flatpickr"
import moment from 'moment'
import { isDisabled } from '@testing-library/user-event/dist/utils'
import momemt from "moment"
import { saveFlowValidation } from '../../../_helpers/validationSchema'
export default function SaveFlowModal({ formActionProps, ruleName, ruleDescription, onSave, updateFormActionProps, timer, scheduler, uuid , actiontype}) {
    const formik = useFormik({

        initialValues: {
            ruleName: ruleName || 'Flow - ' + moment(new Date()).format("lll"),
            ruleDescription: ruleDescription,
            scheduler: { ...scheduler },
            timer: { ...timer }
        },
        validationSchema: saveFlowValidation,
        onSubmit: (values, action) => {
            let tempActionProps = { ...formActionProps };
            tempActionProps.scheduler = values.scheduler;
            updateFormActionProps(tempActionProps);
            onSave(formik)
        }
    })
    const setEnabledTime = (isEnabled) => {
        console.log("isEnabled", isEnabled)
        formik.setFieldValue("scheduler.isScheduled", isEnabled);
        !!isEnabled && formik.setFieldValue("scheduler.enableAtDateTime", new Date(moment().add(5, "minutes")))
    }
    const setDisabledTime = (isDisabled) => {
        formik.setFieldValue("scheduler.isDisableScheduled", isDisabled);
        !!isDisabled && formik.setFieldValue("scheduler.disableAtDateTime", new Date(moment().add(10, "minutes")))
    }
    const onDateSelect = (dateStr, formikProp) => {
        formik.setFieldValue(formikProp, dateStr[0])
    }
    return (
        <Fragment>
            <ModalOverlay></ModalOverlay>
            <ModalContent>
                <ModalHeader>Save Flow</ModalHeader>
                <ModalCloseButton></ModalCloseButton>
                <ModalBody>
                    <FormControl mb={3} isInvalid={formik.errors.ruleName && formik.touched.ruleName}>
                        <FormLabel color={'inputLabel'} mb={0}>Name</FormLabel>
                        <Input name='ruleName' value={formik.values.ruleName} onChange={formik.handleChange}></Input>
                        {
                            formik.errors.ruleName && formik.touched.ruleName && <FormErrorMessage>{formik.errors.ruleName}</FormErrorMessage>
                        }
                    </FormControl>
                    <FormControl mb={1}>
                        <FormLabel color={'inputLabel'} mb={0}>Description</FormLabel>
                        <Textarea name='ruleDescription' value={formik.values.ruleDescription} onChange={formik.handleChange}></Textarea>
                    </FormControl>

                    <Checkbox mt={5} name={'scheduler.isScheduled'} onChange={(e) => setEnabledTime(e.target.checked)} value={formik.values.scheduler.isScheduled}
                        isChecked={formik.values.scheduler.isScheduled}>
                        Schedule the Flow
                    </Checkbox>
                    <Box mt={2}>
                        {
                            !!formik.values.scheduler.isScheduled && <Fragment>
                                <FormControl pl={2}>
                                    <FormLabel color={'inputLabel'} mb={0}>Enable At</FormLabel>
                                    <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} w={'100%'} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                        <Flatpickr options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                            value={formik.values.scheduler.enableAtDateTime} defaultValue={formik.values.scheduler.enableAtDateTime}
                                            onChange={(props) => onDateSelect(props, "scheduler.enableAtDateTime")}></Flatpickr>
                                    </Box>
                                </FormControl>
                                <FormControl mt={2}>
                                    <Checkbox mt={2} name={'scheduler.isDisableScheduled'} onChange={(e) => setDisabledTime(e.target.checked)} value={formik.values.scheduler.isDisableScheduled} isChecked={formik.values.scheduler.isDisableScheduled}>
                                        Set Flow Disable time
                                    </Checkbox>
                                </FormControl>
                                {
                                    !!formik.values.scheduler.isDisableScheduled && <Fragment>
                                        <FormControl pl={2} mt={2}>
                                            <FormLabel color={'inputLabel'} mb={0}>Disable At</FormLabel>
                                            <Box border={'1px solid'} height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} w={'100%'} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                                <Flatpickr options={{ minDate: 'today', enableTime: true, dateFormat: 'M d Y, h:i K' }}
                                                    value={formik.values.scheduler.disableAtDateTime} defaultValue={formik.values.scheduler.disableAtDateTime}
                                                    onChange={(props) => onDateSelect(props, 'scheduler.disableAtDateTime')}></Flatpickr>
                                            </Box>
                                        </FormControl>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Box>
                    <FormControl mt={2}>
                        <FormLabel color={'inputLabel'}>Frequency Capping</FormLabel>
                        <InputGroup>
                            <Input name='timer.duration' value={formik.values.timer.duration} onChange={formik.handleChange}></Input>
                            <InputRightAddon p={0} bg={'transparent'}>
                                <Select value={formik.values.timer.durationType} onChange={(e) => formik.setFieldValue("timer.durationType", e.target.value)} minW={'300px'}>
                                    {
                                        [{ key: 'MINUTES', label: 'MINUTES' }, { key: 'HOURS', label: 'HOURS' }, { key: 'DAYS', label: 'DAYS' }].map(duration => (
                                            <option key={duration.key} value={duration.key}>{duration.label}</option>
                                        ))
                                    }
                                </Select>
                            </InputRightAddon>
                        </InputGroup>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <SecondaryButton label={'Cancel'} mr={2}></SecondaryButton>
                    <PrimaryButton label={!!uuid && !actiontype ? 'Update' : 'Save'} onClick={formik.handleSubmit} ></PrimaryButton>
                </ModalFooter>
            </ModalContent>
        </Fragment >
    )
}
