import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Flex, FormControl, FormLabel, Checkbox, Text, Box, useDisclosure, Slider, SliderFilledTrack, SliderTrack, SliderThumb, Tooltip, Divider, HStack, VStack, Stack } from "@chakra-ui/react";
import Select from '../../../components/themeComponents/react-chakra-select';
//import Select, { components } from "react-select";
import createReactClass from 'create-react-class';
import { useQuery, useQueryClient } from 'react-query';
import { fetchEmailChannels, getChanneldata, getPredefinedSegmentCount, getSegmentCounts, getSegmentList, getTotalSubscribers, getUploadedFiles, updateSegment } from '../../../Services/api';
import { prepareCSVList, prepareEmailProviderData, prepareSegmentList, prepareWhatsappProviderData } from '../../../_helpers/data_helper';
import { OvalSpinner } from '../../../components/Loaders';
import UploadCsvModal from './UploadCsvModal';
import { useDispatch, useSelector } from 'react-redux';
import { getTargetAudienceTypes } from '../../../_helpers/helpers';
import _ from 'lodash'
import { checkForInvalidCsv, extractSelectedCsvFromTargetAudience, calculateReach, updateCsvListAfterValidation } from '../../../_helpers/data_helper';
import CsvValidatorModal from './CsvValidatorModal';
import Reach from './Reach';
import { segmentDummyData } from "../components/segmentDummyData";
import { MdGraphicEq } from 'react-icons/md';
import { Radio, RadioGroup } from '@chakra-ui/react'
import { useLocation } from 'react-router-dom';

// import { segmentDummyData } from './segmentDummyData';
// import { Checkbox, CheckboxGroup } from '@chakra-ui/react'

function AudienceSelection({ totalReach, setTotalReach, selectedChannel, orgToken, orgId, reach, formik, showSliders, setShowSlider, audienceType, setAudienceType }) {


    const [reachToolTip, setReachToolTip] = useState(false)
    const [customSegmentCounts, setCustomSegmentCounts] = useState([])
    const queryClient = useQueryClient();
    const location = useLocation()
    const [sliderValue, setSliderValue] = useState({ CSV: [], segment: [] });
    // const sliderValue = {
    //     CSV:[],
    //     segment:[]
    // }
    const [actualReach, setActualReach] = useState({ CSV: [], segment: [] });

    const [selectedCsv, setSelectedCsv] = useState([])
    const invalidCsvCount = useSelector(state => state.campaign.invalidCsvCount)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isValidationModalOpen, onOpen: onValidationModalOpen, onClose: onValidationModalClose } = useDisclosure()
    const dispatch = useDispatch();
    const subscribersCountQuery = useQuery('subscriberCount', {
        queryFn: async () => {

            let res = await getTotalSubscribers(orgToken, selectedChannel);
            return res.data
        },
        //enabled: false,
        staleTime: 0
    })
    let segmentCount = {
        predefined: [],
        custom: [],
        rfm: []
    };
    const changeAudienceType = (value) => {

        setAudienceType(value)
        if (value == 'segment') {
            formik.setFieldValue("targetedAudience.includes.CSV", [])
        }
        if (value == 'csv') {
            formik.setFieldValue("targetedAudience.includes.segment", [])
        }
        console.log(formik.values.targetedAudience.includes)
    }
    const segmentCountQuery = useQuery('segmentCounts', {
        queryFn: async () => {
            let segmentCounts = await getSegmentCounts(orgToken)
            if (segmentCounts.status == 200) {
                segmentCount['custom'] = segmentCounts.data.data
                console.log(segmentCount)
                return prepareSegmentList(segmentCounts.data.data)
            }
        },
        enabled: true,
        staleTime: 0
    })
    const segmentQuery = useQuery('segments', {
        queryFn: async () => {
            //let predefined = await getSegmentList(orgToken, 'predefined', orgId)

            let rfm = await getSegmentList(orgToken, 'rfm', orgToken)
            let custom = await getSegmentList(orgToken, 'custom', orgToken)

            rfm.data.data = rfm.data.data.concat(custom?.data?.data)
            return prepareSegmentList(rfm.data.data)
        },
        enabled: true,
        staleTime: 0
    })
    const customSegmentQuery = useQuery('segmentsCustom', {
        queryFn: async () => {
            //let predefined = await getSegmentList(orgToken, 'predefined', orgId)
            // let custom = await updateSegment(orgToken, 'custom', orgId)
            let custom = await getSegmentList(orgToken, 'custom', orgId)

            return prepareSegmentList(custom.data)
        },
        enabled: false,
        staleTime: 0
    })

    const uploadedFilesQuery = useQuery('uploadedFiles', {
        queryFn: async () => {
            let res = await getUploadedFiles(orgToken, selectedChannel);
            return prepareCSVList(res?.data?.uploadedFiles)
        },
        enabled: false,
        staleTime: 0
    })

    const predefinedSegmentCountQuery = useQuery('predefinedSegmentCount', {
        queryFn: async () => {
            let res = await getPredefinedSegmentCount(orgToken);
            return res.data;
        },
        staleTime: 0,
        enabled: false
    })
    const onSegmentSelect = (selectedValues, e) => {

        let filteredSegments = [];

        if (!_.isEmpty(segmentCountQuery.data)) {
            segmentCountQuery?.data.forEach((segment) => {
                if (!_.isEmpty(segment?.data?.aggregates) && ((segment?.data?.id === e?.option?.data?.id && e?.action !== 'remove-value'))) {
                    filteredSegments.push(segment?.data);
                    setCustomSegmentCounts([...customSegmentCounts, filteredSegments[0]]);
                    setSliderValue((prevSliderValue) => ({
                        ...prevSliderValue,
                        segment: [...prevSliderValue?.segment, 100]
                    }));
                    setActualReach((prevSliderValue) => ({
                        ...prevSliderValue,
                        segment: [...prevSliderValue?.segment, segment?.data?.aggregates[`count_${selectedChannel !== 'email' ? 'phone' : selectedChannel}`]]
                    }));
                }



            });
        }
        let updatedCustomSegmentCounts = customSegmentCounts;
        if (!_.isEmpty(filteredSegments)) {
            updatedCustomSegmentCounts = [...updatedCustomSegmentCounts, filteredSegments[0]];
        }
        if (e?.action === 'remove-value' && updatedCustomSegmentCounts?.length > 0) {
            updatedCustomSegmentCounts = updatedCustomSegmentCounts.filter((value) => value.id !== e?.removedValue.id);


            setSliderValue((prevSliderValue) => ({
                ...prevSliderValue,
                segment: prevSliderValue.segment.filter((_, index) => customSegmentCounts[index]?.id !== e.removedValue.id)
            }));
            setActualReach((prevActualReach) => ({
                ...prevActualReach,
                segment: prevActualReach.segment.filter((_, index) => customSegmentCounts[index]?.id !== e.removedValue.id)
            }));
        }

        setCustomSegmentCounts(updatedCustomSegmentCounts);


        formik.setFieldValue('targetedAudience.includes.segment', _.isEmpty(updatedCustomSegmentCounts) ? selectedValues : updatedCustomSegmentCounts);


        if (e?.removedValue?.value === 'sendToAll') {

            queryClient.setQueryData(['segments'], (data) => {
                return data.map(datum => datum.value !== 'sendToAll' ? { ...datum, isDisabled: false } : datum);
            });
        }
        if (!!e && Array.isArray(e) && !!e.length) {
            let updatedSegments = e;
            console.log(updatedSegments)
            setCustomSegmentCounts(updatedSegments)
            console.log(customSegmentCounts)
        }
        if (e?.option?.value === 'sendToAll') {

            formik.setFieldValue('targetedAudience.includes', {
                value: 'sendToAll',
                label: 'Send to all',
                segmentType: "sendToAll"
            });
            formik.setFieldValue('targetedAudience.includes.segment', [{
                value: 'sendToAll',
                label: 'Send to all',
                segmentType: "sendToAll"
            }]

            );
            console.log(formik.values)
            queryClient.setQueryData(['segments'], (data) => {
                return data.map(datum => datum.value !== 'sendToAll' ? { ...datum, isDisabled: true } : datum);
            });
        }


        calculateReach(formik, dispatch);
    };


    const onCsvSelect = (selectedValues, e) => {

        if (e?.option?.data?.extraData?.validationData?.counts?.estimated_reach || (Array.isArray(e) && e[0]?.data?.extraData?.validationData?.counts?.estimated_reach)) {
            setSliderValue(prevSliderValue => ({
                ...prevSliderValue,
                "CSV": [...sliderValue['CSV'], 100]
            }));

            setActualReach(prevSliderValue => ({
                ...prevSliderValue,
                "CSV": [...actualReach['CSV'], e?.option?.data?.extraData?.validationData?.counts?.estimated_reach]
            }));
        }

        formik.setFieldValue("targetedAudience.includes.CSV", selectedValues);
        setTimeout(() => {
            checkForInvalidCsv(selectedValues, dispatch)
        }, 100)
    }
    const onCsvUpload = (file) => {
        onClose();
        let tempCSVList = [{ label: file?.fileName, value: file?.id, segmentType: 'CSV', data: file }, ...formik?.values?.targetedAudience?.includes?.CSV]
        formik.setFieldValue('targetedAudience.includes.CSV', tempCSVList)
        setTimeout(() => {
            checkForInvalidCsv(tempCSVList, dispatch)
        }, 100)
        // setSelectedCsv()
        queryClient.setQueryData(['uploadedFiles'], (data) => {
            return [{ label: file?.fileName, value: file?.id, data: file }, ...data];

        })
    }
    const emailProvidersQuery = useQuery(['gatways'], {
        queryFn: async () => {
            let emailProviders = await fetchEmailChannels(orgToken);
            //console.log(emailProviders)
            formik.setFieldValue("selectedSmtpUuid", (emailProviders?.data?.data[0]?.uuid))
            return emailProviders?.data?.data;
        },
        enabled: true
    });
    const whatsappProviderQuery = useQuery(['providers'], {
        queryFn: async () => {
            let whatsappProviders = await getChanneldata(orgToken);
            let defaultProvider = _.find(whatsappProviders.data.data.response, { isDefault: true })?.uuid
            formik.setFieldValue("selectedWhatsappProvider", defaultProvider)
            if(whatsappProviders?.data?.data?.response?.length == 1){
                formik.setFieldValue("selectedWhatsappProvider", whatsappProviders?.data?.data?.response[0]?.uuid)
            }

            return whatsappProviders?.data?.data?.response;
        },
        enabled: true
    });
    const onAllSubscribersCheckboxCheck = (e) => {
        if (!(formik.values.targetedAudience.includes.segment.length)) {
            formik.setFieldValue("targetedAudience.includes.segment", [JSON.parse(e.target.value)])
        }
    }

    const cofirmCsvValidationAndUpdate = (list) => {
        onValidationModalClose()
        let updatedSelectedList = updateCsvListAfterValidation(formik?.values?.targetedAudience?.includes?.CSV, list);
        let updatedCSVList = updateCsvListAfterValidation(uploadedFilesQuery?.data, list)
        formik.setFieldValue("targetedAudience.includes.CSV", updatedSelectedList);
        queryClient.setQueryData(['uploadedFiles'], (data) => {
            return updatedCSVList
        })
        checkForInvalidCsv(updatedSelectedList, dispatch);
    }

    const handleReachBiasing = (idx, type, total, v) => {
        sliderValue[type][idx] = v
        actualReach[type][idx] = type == 'CSV' ? Math.ceil((sliderValue[type][idx] / 100) * formik.values.targetedAudience.includes[type][idx].data.extraData.validationData.counts.estimated_reach) : Math.ceil((sliderValue[type][idx] / 100) * customSegmentCounts[idx]?.aggregates?.count_email)
        // let newValue = [...sliderValue.segment,v]
        // setSliderValue({...sliderValue,"segment":[newValue[newValue.length-1]]})

        // setSliderValue((prev)=>({...prev, [type]: {idx:v} }))
        // let AR = Math.ceil((sliderValue[total.data.id] / 100) * customSegmentCounts[idx]?.aggregates?.count_email)
        // sliderValue.segment[idx][JSON.stringify(total.data.id)] = v
        // let sliderValue1 = v
        // let newSegmentValues = [...sliderValue,]
        // setSliderValue([...sliderValue.segment,sliderValue1])  
        // setActualReach()
        // console.log(actualReach)

        formik.setFieldValue(`targetedAudience.includes[${type}][${idx}].actualReach`, actualReach[type][idx])
        formik.setFieldValue(`targetedAudience.includes[${type}][${idx}].targetRatio`, sliderValue[type][idx])
        console.log(formik.values.targetedAudience.includes[type])
    }
    useEffect(() => {
        emailProvidersQuery.refetch()
        if (location?.state?.data?.campaignData) {

            let savedCsv = []
            let selectedSegments = [];
            (!!segmentCountQuery?.data?.length || !!uploadedFilesQuery?.data?.length) &&
                !!location?.state?.data?.campaignData?.targetedAudience?.includes?.length &&
                location?.state?.data?.campaignData?.targetedAudience?.includes?.forEach((audience) => {
                    if (audience.segmentType === 'CSV') {
                        setAudienceType("csv")
                        console.log(_.find(uploadedFilesQuery.data, { data: { url: audience.selectedSegment } }))
                        savedCsv.push(_.find(uploadedFilesQuery.data, { data: { url: audience.selectedSegment } }))
                    }
                    if (audience.segmentType === 'custom') {
                        setAudienceType("segment")
                        let filteredSegment = _.find(segmentCountQuery?.data, { value: audience.selectedSegment })
                        !!filteredSegment && selectedSegments.push(filteredSegment)
                    }
                })

            onCsvSelect(savedCsv, savedCsv)

            onSegmentSelect(selectedSegments, selectedSegments)
        }
    }, [uploadedFilesQuery.data, segmentCountQuery.data])
    useEffect(() => {
        if (selectedChannel !== 'push') {
            !segmentQuery.isFetched && segmentQuery.refetch();
            !uploadedFilesQuery.isFetched && uploadedFilesQuery.refetch();
            //predefinedSegmentCountQuery.refetch();
        }
        subscribersCountQuery.refetch()
        calculateReach(formik, dispatch);
    }, [selectedChannel, totalReach])
    return (
        <Flex p={3} gap={5} maxH={'700px'} overflowY={'scroll'} direction={'column'}>
            <RadioGroup onChange={(e) => { changeAudienceType(e) }} value={audienceType}>
                <Stack direction='column' width={'200px'} >
                    {(selectedChannel !== 'push' && selectedChannel !== 'mobile' && selectedChannel !== 'inApp') &&
                        <Radio isChecked={audienceType == 'csv'} value='csv'>CSV</Radio>
                    }
                    <Radio isChecked={audienceType == 'segment'} value='segment'>Segment</Radio>
                </Stack>
            </RadioGroup>
            <input type='hidden'  {...formik.getFieldProps('targetedAudience.includes.segment')} />
            {
                (selectedChannel === 'push' || selectedChannel === 'mobile' || selectedChannel === 'inApp') && <FormControl>
                    <FormLabel color={'inputLable'}>Send to (Segments) </FormLabel>
                    <Select isMulti options={[{ label: 'All Subscribers', value: 'sendToAll', segmentType: 'sendToAll' }]} closeMenuOnSelect={false} value={formik?.values?.targetedAudience?.includes?.segment} onChange={onSegmentSelect} colorScheme='brand' isClearable={false}></Select>
                </FormControl>
            }

            {
                (selectedChannel !== 'push' && selectedChannel !== 'mobile' && selectedChannel !== 'inApp') &&
                <Fragment>

                    {
                        audienceType == "segment" &&
                        <FormControl>

                            <input type='hidden'  {...formik.getFieldProps('targetedAudience.includes.CSV')} />
                            <input type='hidden'  {...formik.getFieldProps('targetedAudience.includes.segment')} />
                            <FormLabel color={'inputLable'}>Send to (Segments)  </FormLabel>
                            {
                                !!segmentQuery.isLoading && <Box display={'grid'} placeItems={'center'}><OvalSpinner height={20} width={20}></OvalSpinner></Box>
                            }
                            {
                                !!segmentQuery?.data?.length && <Select isMulti isOptionDisabled={() => formik.values.targetedAudience?.includes?.segment?.length >= 1} options={segmentQuery?.data}
                                    value={formik.values.targetedAudience.includes.segment} onChange={onSegmentSelect} colorScheme='brand' isClearable={false}>

                                </Select>
                            }

                        </FormControl>
                    }
                    {
                        audienceType == "csv" &&

                        <FormControl>
                            <FormLabel color={'inputLable'}>Send to (CSV)</FormLabel>
                            {
                                !!uploadedFilesQuery.isLoading && <Box display={'grid'} placeItems={'center'}><OvalSpinner height={20} width={20}></OvalSpinner></Box>
                            }
                            {

                                <Fragment>
                                    {
                                        !!uploadedFilesQuery?.data?.length &&
                                        <Select isMulti options={(uploadedFilesQuery?.data)}
                                            isOptionDisabled={() => formik?.values?.targetedAudience?.includes?.CSV?.length >= 1}
                                            closeMenuOnSelect={false} value={formik?.values?.targetedAudience?.includes?.CSV} colorScheme='brand' onChange={onCsvSelect} isClearable={false}></Select>
                                    }
                                    {
                                        !!invalidCsvCount.length && <Text color={'danger'} onClick={onValidationModalOpen} float={'left'} mt={1} cursor={'pointer'}>You have {invalidCsvCount.length} invalid CSV. Click here to validate. </Text>
                                    }
                                    {

                                        formik?.values?.targetedAudience?.includes?.CSV?.length < 1 &&
                                        <Text color={'link'} float={'right'} mt={1} cursor={'pointer'} onClick={onOpen}>Add new CSV+</Text>
                                    }
                                </Fragment>

                            }
                            <UploadCsvModal isOpen={isOpen} onClose={onClose} channel={selectedChannel} orgToken={orgToken} onSelect={onCsvUpload}></UploadCsvModal>
                            <CsvValidatorModal isOpen={isValidationModalOpen} onClose={onValidationModalClose} csvList={formik.values.targetedAudience.includes.CSV} channel={selectedChannel} orgToken={orgToken} invalidCsv={invalidCsvCount} onConfirm={cofirmCsvValidationAndUpdate}></CsvValidatorModal>


                        </FormControl>
                    }
                    {
                        (selectedChannel === 'email') && <FormControl mt={3}>
                            <FormLabel color={'inputLabel'}>SMTP Settings </FormLabel>
                            {/* <Select options={smtpTypes} value={_.filter(smtpTypes, { 'key': formik.values.selectedSmtpType })} onChange={(e) => formik.setFieldValue("selectedSmtpType", e.key)}></Select> */}
                            <Select
                                options={prepareEmailProviderData(emailProvidersQuery.data)}
                                onChange={(e) => formik.setFieldValue("selectedSmtpUuid", e.value)}
                                value={_.filter(prepareEmailProviderData(emailProvidersQuery.data), { 'value': formik.values.selectedSmtpUuid })}
                            >
                            </Select>
                        </FormControl>
                    }
                    {
                        (selectedChannel === 'whatsapp') && <FormControl mt={3}>
                            <FormLabel color={'inputLabel'}>Whatsapp Setting</FormLabel>
                            {/* <Select options={smtpTypes} value={_.filter(smtpTypes, { 'key': formik.values.selectedSmtpType })} onChange={(e) => formik.setFieldValue("selectedSmtpType", e.key)}></Select> */}
                            <Select
                                options={prepareWhatsappProviderData(whatsappProviderQuery.data)}
                                onChange={(e) => formik.setFieldValue("selectedWhatsappProvider", e.value)}
                                value={_.filter(prepareWhatsappProviderData(whatsappProviderQuery.data), { 'value': formik?.values?.selectedWhatsappProvider })}
                            >
                            </Select>
                        </FormControl>
                    }

                </Fragment>
            }
            {
                <Box  >

                    <Reach selectedChannel={formik.values.selectedChannel} setTotalReach={setTotalReach} audience={formik.values.targetedAudience.includes} subscribersCountQuery={subscribersCountQuery} query={subscribersCountQuery} ></Reach>

                </Box>
            }
            <Box>


            </Box>
            {/* <Checkbox size={'lg'} colorScheme='brand'>
                    <Text color={'inputLabel'}>Exclude</Text>
                </Checkbox> */}
            {
                formik.values.campaignType !== ("a_b_test_v2") && (!_.isEmpty(formik?.values?.targetedAudience?.includes?.CSV) || !_.isEmpty(formik?.values?.targetedAudience?.includes?.segment)) && <Stack spacing={5} direction='row'>
                    <Checkbox colorScheme='red' value={showSliders} onChange={() => setShowSlider(!showSliders)}>
                        {formik.values.campaignType == ("control_group_v2") ? "Control Group Selection" : "Partial Audience Selection "}
                    </Checkbox>
                </Stack>
            }


            {
                !!showSliders && !_.isEmpty(sliderValue) && Object.keys(sliderValue).map((key, i) => (
                    <>

                        {
                            key == 'CSV' && !_.isEmpty(sliderValue[key]) &&

                            <Text>  Select Reach for {key}   </Text>

                        }
                        {
                            <VStack w={"100%"} alignItems={'start'} >
                                {
                                    !_.isEmpty(sliderValue[key]) && sliderValue[key].map((num, idx) => (
                                        <>
                                            {
                                                <Box w={'60%'} >
                                                    {
                                                        <Text>
                                                            Reach for  {key == 'segment' ? JSON.stringify(customSegmentCounts[idx]?.label) : JSON.stringify(formik.values.targetedAudience.includes?.CSV[idx]?.label)}
                                                        </Text>
                                                    }
                                                    <Slider onMouseOver={() => setReachToolTip(true)}
                                                        onMouseLeave={() => { setReachToolTip(false) }} aria-label='slider-ex-1' value={sliderValue[key][idx]} onChange={(v) => {
                                                            handleReachBiasing(idx, key, num, v);
                                                        }}

                                                    >
                                                        <SliderTrack>
                                                            <SliderFilledTrack />
                                                            <Tooltip
                                                                hasArrow
                                                                bg='teal.500'
                                                                color='white'
                                                                placement='top'
                                                                isOpen={reachToolTip}
                                                                label={`${JSON.stringify(sliderValue[key][idx])}%`}
                                                            >
                                                                <SliderThumb />
                                                            </Tooltip>
                                                        </SliderTrack>
                                                        <SliderThumb />
                                                    </Slider>

                                                    <Text>
                                                        {
                                                            !_.isEmpty(actualReach[key]) &&

                                                            <>
                                                                {actualReach[key][idx]} / {key == 'CSV' ? JSON.stringify(formik.values.targetedAudience.includes.CSV[idx]?.data?.extraData?.validationData?.counts?.estimated_reach) : JSON.stringify(customSegmentCounts[idx]?.aggregates[`count_${selectedChannel}`])}
                                                                {/* {JSON.stringify(sliderValue)} */}
                                                                {/* {JSON.stringify(actualReach)} */}

                                                            </>
                                                        }

                                                    </Text>

                                                </Box>
                                            }
                                        </>
                                    ))
                                }

                                {/* <Divider orientation='vertical'/> */}

                            </VStack>

                        }

                    </>


                ))

            }
            {/* {
                    !_.isEmpty(actualReach[key]) && 
                    
                    <>
                        {actualReach[key][idx]}
                    </>
                } */}

        </Flex>
    )
}

export default AudienceSelection
