import React, { Fragment, useState, useEffect } from 'react'
import { FormLabel, InputGroup, Input, InputRightAddon, Select, FormControl, FormErrorMessage, Text, Checkbox, Flex } from "@chakra-ui/react"
import FlowEditService from '../helpers/FlowEditService'
import { useFormik } from 'formik'
import { delayValidation } from '../../../_helpers/validationSchema'
import { getSegmentProps } from '../../../Services/api'
import DateTimePicker from '../../../components/themeComponents/DateTimePicker'
import { Box } from '@chakra-ui/react'
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import { NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper } from "@chakra-ui/react";
import *  as _ from "lodash"
export default function InspectorForDelay({ formData, selectedElement, updateFormData, validateFormData, showDateTimePicker, setShowDateTimePicker }) {
    const [timeOfTheDay, setTimeOfTheDay] = useState({
        hours: new Date().getHours(),
        minutes: new Date().getMinutes(),
        ampm: "PM"
    })
    let setDelayInFormData = (durationType, delayValue) => {
        let tempFormData = { ...formData };
        if (tempFormData.hasOwnProperty("undefined")) {
            delete tempFormData["undefined"]
        }

        !!selectedElement && (() => {
            let objectId = selectedElement.elementType === FlowEditService.elementTypes.initial ? 'beginsWith' : selectedElement.id;
            let secondsMultiplier;
            durationType === 'seconds' && (secondsMultiplier = 1);
            durationType === 'minutes' && (secondsMultiplier = 60);
            durationType === 'hours' && (secondsMultiplier = 60 * 60);
            durationType === 'days' && (secondsMultiplier = 60 * 60 * 24);
            durationType === 'weeks' && (secondsMultiplier = 60 * 60 * 24 * 7);
            tempFormData[objectId].wait = formik.values;
            tempFormData[objectId].wait.formInputs = {
                delayValue: delayValue, delayDuration: durationType
            }
            tempFormData[objectId].wait.triggerTime = currentDateTime
            tempFormData[objectId].wait.duration = Number(tempFormData[objectId].wait.formInputs.delayValue) * secondsMultiplier;
            updateFormData(tempFormData)
            validateFormData("beginsWith")

        })()

    }
    

    const handleHoursChange = (value) => {
        const num = Math.max(1, Math.min(12, Number(value) || 1));
        const formattedHours = String(num).padStart(2, "0"); // Ensure two-digit format
        setTimeOfTheDay((prev) => ({ ...prev, hours: formattedHours }));
    
        const updatedTime = `${formattedHours}:${timeOfTheDay.minutes} ${timeOfTheDay.ampm}`;
        setCurrentDateTime(updatedTime);
        handleChange("triggerTime", updatedTime);
    };
    
    const handleMinutesChange = (value) => {
        const num = Math.max(0, Math.min(59, Number(value) || 0));
        const formattedMinutes = String(num).padStart(2, "0"); // Ensure two-digit format
        setTimeOfTheDay((prev) => ({ ...prev, minutes: formattedMinutes }));
    
        const updatedTime = `${timeOfTheDay.hours}:${formattedMinutes} ${timeOfTheDay.ampm}`;
        setCurrentDateTime(updatedTime);
        handleChange("triggerTime", updatedTime);
    };
    const formik = useFormik({
        initialValues: { ...formData[selectedElement.id]?.wait },
        validationSchema: delayValidation,
    })
    const formatDateTime = (date) => {
        return new Intl.DateTimeFormat('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        }).format(date);
    };
    const [delaySegmentProps, setDelaySegmentProps] = useState([])
    const [currentDateTime, setCurrentDateTime] = useState("");
    const fetchSegmentProps = async () => {
        const segmentationProps = await getSegmentProps().catch(err => err);
        if (segmentationProps.status === 200) {
            let customerKey = Object.keys(segmentationProps.data.segmentationProps).find((key) =>
                key.includes("customer")
            );


            if (!customerKey) {
                customerKey = Object.keys(segmentationProps.data.segmentationProps)[0];
            }


            const datetimeProps = Object.values(segmentationProps.data.segmentationProps[customerKey]).filter(prop => prop.dataType === 'datetime');
            setDelaySegmentProps(datetimeProps);
        }

    };


    const handleChange = (fieldName, value) => {
        formData[selectedElement.id]['wait'] = formData[selectedElement?.id]?.wait
        formData[selectedElement.id]['wait'][fieldName] = value;

        //  console.log(formData[selectedElement.id])
        // tempFormData[fieldName] = value;    
        // updateFormData(tempFormData);
    };
    const setTimeFromString = (timeString) => {
        if (!!timeString) {
            const [time, ampm] = timeString.split(" ");
            const [hours, minutes] = time.split(":").map(Number);
    
            setTimeOfTheDay({
                hours: String(hours).padStart(2, "0"),  
                minutes: String(minutes).padStart(2, "0"),
                ampm
            });
    
            setCurrentDateTime(`${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} ${ampm}`);
        }
    };
    
    
    useEffect(() => {
        fetchSegmentProps();
        if (!!formData[selectedElement?.id]) {
            
            setShowDateTimePicker(!!formData[selectedElement?.id]['wait']["showDateTimePicker"])
            setTimeFromString(formData[selectedElement?.id]['wait']["triggerTime"]);
            setCurrentDateTime(formData[selectedElement?.id]['wait']["triggerTime"])
        }
    }, []);
    return (
        <Fragment>
            <FormControl>
                <FormLabel color={'inputLabel'} mt={2}>
                    Duration
                </FormLabel>
                <InputGroup>
                    <Input name='formInputs.delayValue' onChange={(e) => { formik.setFieldValue("formInputs.delayValue", e.target.value); setTimeout(() => { setDelayInFormData(formik?.values?.formInputs?.delayDuration, e.target.value) }, 100) }}
                        value={formik?.values?.formInputs?.delayValue}></Input>
                    <InputRightAddon p={0} bg={'transparent'}>
                        <Select minW={'150px'} name={`formInputs.delayDuration`} value={formik?.values?.formInputs?.delayDuration}
                            onChange={(e) => { formik.setFieldValue("formInputs.delayDuration", e.target.value); setDelayInFormData(e.target.value, formik?.values?.formInputs?.delayValue) }}>
                            {
                                FlowEditService.getDelayDuration().map(duration => (
                                    <option key={duration?.value} value={duration?.value}>{duration?.key}</option>
                                ))
                            }
                        </Select>

                    </InputRightAddon>

                </InputGroup>



            </FormControl>
            {
                !!formik.errors?.formInputs?.delayValue && <Text color={'danger'} fontSize={'sm'}>{formik.errors.formInputs.delayValue}</Text>
            }
            <FormControl mt={"10px"} >
                {
                    delaySegmentProps.length > 0 &&
                    <>
                        <FormLabel color={'inputLabel'} mt={2}>
                            Select Property
                        </FormLabel>
                        <Select
                            placeholder='Select property'
                            //   value={formData[selectedElement.id]?.nextActionCondition?.wait?.segmentProperty}
                            onChange={(e) => handleChange('segmentProperty', e.target.value)}
                        >
                            {delaySegmentProps.map((prop) => (
                                <option key={prop.value} value={prop.value}>
                                    {prop.value}
                                </option>
                            ))}
                        </Select>
                    </>
                }
               <Checkbox 
    isChecked={showDateTimePicker} 
    onChange={() => {
        const newShowDateTimePicker = !showDateTimePicker;
        setShowDateTimePicker(newShowDateTimePicker);
        handleChange("showDateTimePicker", newShowDateTimePicker);
    }} 
    value={showDateTimePicker} 
>  
</Checkbox> Time of the day
                {
                    showDateTimePicker && (
                        <>


                            {/* </FormControl> */}
                            <FormControl>
                                <FormLabel color={'inputLabel'} mt={2}>
                                    {/* <Checkbox onChange={() => setShowDateTimePicker(!showDateTimePicker)} value={showDateTimePicker} >  </Checkbox> Date and Time */}
                                </FormLabel>
                                {/* <Box 
        // style={{ position: 'relative', marginTop: '-20px' }}
        >

        <DateTimePicker 
        // style={{ position: 'relative', marginTop: '-20px' }}
        // onChange={(e) => }
            onDateSelect={(selectedDates, dateStr, instance) => handleChange('triggerTime', dateStr)} minDate={"today"}></DateTimePicker>
            </Box> */}
                                <Box height={10} fontSize={'md'} paddingInlineStart={4} paddingInlineEnd={4} display={'flex'} alignItems={'center'} maxWidth={'300px'} w={'100%'} ml={3} rounded={'md'} borderColor={'rgb(226, 232, 240)'}>
                                    {/* <Flatpickr 
    options={{ 
        enableTime: true, 
        noCalendar: true, 
        dateFormat: 'h:i K' 
    }}
    value={
        (!_.isEmpty(formData[selectedElement?.id]?.nextActionCondition['wait']?.triggerTime) && 
        formData[selectedElement?.id]?.nextActionCondition['wait']["triggerTime"]) || currentDateTime
    }
    onChange={(selectedDates) => {
        const formattedTime = selectedDates[0].toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        handleChange('triggerTime', formattedTime);
        handleChange('showDateTimePicker', showDateTimePicker);
    }}
/> */}
                                  <Flex gap="3px" align="center">
            <NumberInput
                value={timeOfTheDay.hours}
                onChange={handleHoursChange}
                min={0}
                max={23}
                step={1}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            :
            <NumberInput
                value={timeOfTheDay.minutes}
                onChange={handleMinutesChange}
                min={0}
                max={59}
                step={1}
            >
                <NumberInputField />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
            </NumberInput>
            :
            <Select
                value={timeOfTheDay.ampm}
                onChange={(e) =>{ setTimeOfTheDay({ ...timeOfTheDay, ampm: e.target.value })
                setCurrentDateTime(`${timeOfTheDay.hours}:${timeOfTheDay.minutes} ${timeOfTheDay.ampm}`)
                handleChange("triggerTime",currentDateTime)
                }}
            >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </Select>
        </Flex>
                                </Box>

                            </FormControl>
                        </>
                    )
                }



            </FormControl>

        </Fragment>
    )
}
