import { Box, FormLabel, HStack, Text, useToast, VStack } from '@chakra-ui/react'
import React from 'react'
import CampaignDraftDrawer from '../Campaigns/CampaignDraftDrawer'
import { useState, useEffect } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import store from '../../store/store'
import Select from '../../components/themeComponents/react-chakra-select'
import { prepareOrgList, prepareTargetedAudienceData } from '../../_helpers/data_helper'
import { useSteps } from '@chakra-ui/react'
import { targetAudienceSteps } from '../../_helpers/data_helper'
import CampaignStepper from '../Campaigns/components/CampaignStepper'
import OrgAndConfig from './OrgAndConfig'
import { ErrorMessage, useFormik } from 'formik'
import AudieceSelection from './AudieceSelection'
import * as _ from "lodash";
import { targetGroupCampaign } from '../../_helpers/formValues'
import TemplateSelectionTG from './TemplateSelectionTG'
import ScheduleCampaign from './ScheduleCampaign'
import { useLocation, useNavigate } from 'react-router-dom'
import { draftCampaign, draftCampaignAllbanks, getOrgList, publishCampaign, publishCampaignAllBanks, saveDraftAllBanks } from '../../Services/api'
import OrgAndConfigTG from './OrgAndConfigTG'

import Swal from 'sweetalert2';
import { useSelector } from 'react-redux'
// import AudieceSelection from './AudieceSelection'
export default function TargetGroup() {
  const [selectedOrgList, setSelectedOrgList] = useState([]);
  const [isOpen, setIsOpen] = useState(false)
  const [targetGroupData, setTargetGroupData] = useState({})
  const [emailConfiguration, setEmailConfiguration] = useState([]);
  const [whatsappConfiguration, setWhatsappConfiguration] = useState([]);
  const [whatsappSetting, setWhatsappSetting] = useState({});
  const [campaignName, setCampaignName] = useState("")
  const [scheduleData, setScheduleData] = useState({});
  const [selectedGatewayUuid, setSelectedGatewayUuid] = useState({})
  const [templatesData, setTemplatesData] = useState({});
  const [selectedSmtp, setSelectedSmtp] = useState({});
  const [reachForOrgs, setReachForOrgs] = useState({})
  const [orgList, setOrgList] = useState([])
  const [hasSmtpError, sethasSmtpError] = useState(false)
  const [targetRatiosError, setTargetRatiosError] = useState("");
  const location = useLocation();

  const initializeScheduleData = (token) => {
    setScheduleData(prev => ({
      ...prev,
      [token]: {
        scheduleStatus: 'NOW',
        scheduleAt: new Date(),
        repeatAfterDays: 1,
        repeatStopDateTime: new Date()
      }
    }));
  };

  const userDetails = useSelector(state => state.auth.user_data);

  const formik = useFormik({
    initialValues: targetGroupCampaign,
    enableReinitialize: true,
    onSubmit: () => { }
  })

  const navigate = useNavigate()
  const toast = useToast()
  const { activeStep, setActiveStep } = useSteps({
    index: 0, count: targetAudienceSteps.length
  })

  const saveDraft = async () => {

    let res = await saveDraftAllBanks(prepareFinalData())

    if (res?.data?.uuid) {

      toast({
        title: `Campaign Drafted successfully.`,
        status: 'success',
        duration: 3000,
        isClosable: true
      });
      navigate("/all-banks/list");

    }

  }
  const saveAndPublishCampaign = async (values, action) => {
    let campaignData = values;
    Swal.fire({
      title: 'Are you sure?',
      text: "You are about to save the campaign!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, save it!'
    }).then(async (result) => {
      if (result.isConfirmed) {

        const draftResponse = await draftCampaignAllbanks("", campaignData);
        if (campaignData?.campaigns[0]?.scheduleData?.scheduleStatus !== 'NOW') {
          toast({
            title: `Campaign published successfully.`,
            status: 'success',
            duration: 3000,
            isClosable: true
          });
          navigate("/all-banks/list");
        }
        if (draftResponse.data && campaignData?.campaigns[0]?.scheduleData?.scheduleStatus == 'NOW') {
          let publishResponse = await publishCampaignAllBanks(draftResponse.data, campaignData);
          if (!!publishResponse) {
            toast({
              title: `Campaign published successfully.`,
              status: 'success',
              duration: 3000,
              isClosable: true
            });
            navigate("/all-banks/list");
          }
        }
      }
    });
  };
  const fetchOrgList = async () => {
    try {

      const orgs = await getOrgList();
      setOrgList(orgs?.data || []);
      let filteredOrgs = []
      if (orgs?.data?.length > 0) {
        location?.state?.data?.forEach((dt) => {
          filteredOrgs = [...filteredOrgs, _.filter(prepareOrgList(orgs?.data), { value: dt.orgToken })[0]]
        })
        setSelectedOrgList(filteredOrgs);
      }
    } catch (err) {
      console.error('Error fetching organization list:', err);
    }
  };


  useEffect(() => {

    if (Array.isArray(formik.values.orgList) && formik.values.orgList.length > 0) {
      const obj = {};
      formik.values.orgList.forEach((org) => {
        obj[org] = { csv: [], segment: [] };
      });
      setTargetGroupData(obj);
    }
    formik?.values?.orgList.forEach((org) => {
      if (!location?.state?.data) {

        initializeScheduleData(org)
      }
    })


    if (Object.values(selectedSmtp).some((smtp) => !smtp) && formik.values.selectedChannel == 'email') {
      sethasSmtpError(true);
    } else {
      sethasSmtpError(false);
    }

  }, [formik.values.orgList, selectedSmtp]);


  let prepareFinalData = () => {
    let retVal = {};


    let firstKeyScheduleData = scheduleData[Object.keys(scheduleData)[0]];
    let modifyScheduleData = (org) => {
      let retVal = {}
      let templateData = {
        headerUuid: templatesData[org]?.header?.uuid || "",
        footerUuid: templatesData[org]?.footer?.uuid || "",
        bodyUuid: templatesData[org]?.body?.uuid || ""
      }
      firstKeyScheduleData?.targetRatios?.forEach((datum, i) => {

        firstKeyScheduleData.targetRatios[i]['templateData'] = templateData
      })
      retVal = firstKeyScheduleData;
      return retVal;
    }

    formik.values.orgList.forEach((org) => {
      let targetedAudience = prepareTargetedAudienceData(targetGroupData[org], "ALL BANKS", formik.values.selectedChannel, firstKeyScheduleData)
      if (!!formik?.values?.reachPercentage) {

        targetedAudience['includes'].map((audience) => {
          audience['actualReach'] = Math.ceil((formik.values.reachPercentage / 100) * audience.estimateReach)
        })
      }
      retVal[org] = {
        campaignName: formik.values.campaignName,
        orgToken: org,
        selectedSmtpUuid: selectedSmtp[org],
        targetedAudience: targetedAudience,
        reachPercentage: formik.values.reachPercentage,
        selectedChannel: formik.values.selectedChannel,
        // selectedSmtpType: "promotional",
        selectedTempName:
          formik.values.selectedChannel == "email"
            ? templatesData[org]?.body?.label || ""
            : templatesData[org]?.label || "",
        addCountryCode: true,
        broadcastType: "ALL_BANKS",
        selectedTempHeader: templatesData[org]?.header?.uuid || "",
        selectedTempFooter: templatesData[org]?.footer?.uuid || "",
        selectedTemp:
          formik.values.selectedChannel == "email"
            ? templatesData[org]?.body?.uuid || ""
            : templatesData[org]?.uuid || "",
        broadcastBatchWise: "true",
        broadcast: "ALL_BANKS",
        addToContactList: true,
        message: "",
        isUnicode: false,
        sentSmsCount: 1,
        coupon: "",
        mediaUrl: "",
        scheduleData: modifyScheduleData(org),
        approvedTemplateId: "",
        selectedGateway: selectedGatewayUuid[org] || "",
        subjectLine: formik?.values?.subjectLine
      };


      retVal[org] = { ...retVal[org] };
    });

    console.log(Object.values(retVal));
    let data = {
      createdBy: userDetails.email,
      campaignName: formik.values?.campaignName,
      selectedChannel: formik.values?.selectedChannel,
      campaigns: Object.values(retVal),
    }
    return data;
  };

  useEffect(() => {
    console.log(reachForOrgs)
  }, [targetGroupData, templatesData, scheduleData, reachForOrgs]);
  useEffect(() => {
    fetchOrgList();
    let orgs = [];

    if (location?.state?.data) {

      let campaignData = location?.state?.data;

      formik.setFieldValue("selectedChannel", campaignData[0]?.selectedChannel);
      formik.setFieldValue("campaignName", campaignData[0]?.campaignName);

      if (!!campaignData?.length) {
        campaignData.forEach((dt) => {
          let org = dt.orgToken;
          let scheduleData = dt.scheduleData;
          // setSelectedSmtp((prev)=>({...prev,[org]: dt.selectedSmtpUuid}))

          setScheduleData(prev => ({
            ...prev,
            [org]: {
              scheduleStatus: scheduleData.scheduleStatus,
              scheduleAt: scheduleData.scheduleAt,
              repeatAfterDays: scheduleData.repeatAfterDays,
              repeatStopDateTime: scheduleData.repeatStopDateTime,
              targetRatios: scheduleData.targetRatios
            }
          }));

          // setReachForOrgs((prev) => ({ ...prev, [org]: scheduleData }));

          // setTargetGroupData((prev) => ({ ...prev, [org]:_.filter(campaignData.includes,)}))


        });


        setSelectedOrgList(orgs);
      }
    }
  }, []);


  return (
    <Box marginLeft={'1rem'} overflowY={'scroll'} padding={'2rem'} borderRadius={'1vw'} h={"100%"} bg={'#EEEEEE'} alignItems={'baseline'} border={'1px solid #bebcbc'} >
      <Box  >

        <CampaignStepper steps={targetAudienceSteps} activeStep={activeStep}></CampaignStepper>
        <Box mt={'2rem'} >

          {
            activeStep === 0 &&
            <>
              <OrgAndConfig whatsappConfiguration = {whatsappConfiguration} setWhatsappSetting = {setWhatsappSetting} whatsappSetting={whatsappSetting}  setWhatsappConfiguration = {setWhatsappConfiguration} location={location} selectedSmtp={selectedSmtp} emailConfiguration={emailConfiguration} setEmailConfiguration={setEmailConfiguration} setSelectedSmtp={setSelectedSmtp} selectedOrgList={selectedOrgList} setSelectedOrgList={setSelectedOrgList} formik={formik} />
              {
                !!formik.values?.orgList?.length && !!hasSmtpError &&
                <Text color={'red'}>SMTP settings not found. </Text>
              }
            </>
            // <OrgAndConfigTG/>
          }
        </Box>
        {
          activeStep === 1 &&
          <AudieceSelection location={location} reachForOrgs={reachForOrgs} setReachForOrgs={setReachForOrgs} setTargetGroupData={setTargetGroupData} targetGroupData={targetGroupData} formik={formik} />
        }
        {
          activeStep === 2 &&
          <TemplateSelectionTG whatsappSetting={whatsappSetting} location={location} targetGroupData={targetGroupData} selectedSmtpUuid={selectedSmtp} selectedGatewayUuid={selectedGatewayUuid} setSelectedGatewayUuid={setSelectedGatewayUuid} setTemplatesData={setTemplatesData} templatesData={templatesData} formik={formik} />
        }
        {
          activeStep === 3 && <>
            {
              formik.values.orgList.map((org, i) => (
                i == 0 &&
                <ScheduleCampaign setTargetRatiosError={setTargetRatiosError} targetRatiosError={targetRatiosError} location={location} scheduleData={scheduleData} setScheduleData={setScheduleData} initializeScheduleData={initializeScheduleData} orgToken={org} setTargetGroupData={setTargetGroupData} targetGroupData={targetGroupData} formik={formik} />
              ))
            }
          </>
        }
        <HStack pos={"fixed"} bottom={'2rem'} right={'2rem'} >
          {activeStep > 0 && <PrimaryButton label={'Back'} onClick={() => { setActiveStep(activeStep - 1) }} />}
          <PrimaryButton
            isDisabled={
              (activeStep === 0 &&
                (!formik.values.selectedChannel ||
                  !formik.values.campaignName ||
                  _.isEmpty(formik.values.orgList))) ||

              (activeStep === 1 &&
                (!(_.sum(Object.values(reachForOrgs)) > 0) ||
                  Object.values(targetGroupData).some(entry =>
                    _.isEmpty(entry.csv) && _.isEmpty(entry.segment)
                  ))) ||

              (activeStep === 2 &&
                formik.values.selectedChannel === 'email' &&
                (_.isEmpty(templatesData) || Object.values(templatesData).some(template => {
                  return !template?.body?.body;
                }))) ||

              (activeStep === 3 && !!targetRatiosError) ||
              (activeStep === 3 && location?.state?.approval_status !== 'APPROVED')
            }
            label={activeStep === 3 ? 'Publish' : 'Save & Continue'}
            onClick={() => {
              if (activeStep === 3) {
                saveAndPublishCampaign(prepareFinalData(), {});
              } else {
                setActiveStep(activeStep + 1);
              }
            }}
          />

          {
            activeStep === 3 &&
            <PrimaryButton label={'Save Draft'} onClick={() => saveDraft()} />
          }
        </HStack>
      </Box>
    </Box>

  )
}
