import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Alert,
  AlertIcon,
  Spinner,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { getFlowAnalyticsReport } from "../../Services/api";
import * as _ from "lodash";
import DateRangeSelection from "../../components/DateRangeSelection";
import moment from "moment";

const FlowDetailedAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { uuid } = useParams();
  const [flowName, setFlowName] = useState("");
  
  const getFlowAnalytics = async (dateRange) => {
    try {
      setLoading(true);
      const res = await getFlowAnalyticsReport(uuid,dateRange);
      setAnalyticsData(res?.data?.analytics || {});
      setFlowName(res?.data?.ruleName)
    } catch (err) {
      setError("Failed to fetch analytics data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });

const onDateChange = (date) => {
  setDateRange({ startDate: date.startDate, endDate: date.endDate });
};

// React to changes in dateRange
useEffect(() => {
  if (dateRange.startDate && dateRange.endDate) {
    getFlowAnalytics(dateRange);
  }
}, [dateRange]);

  useEffect(() => {
    getFlowAnalytics(dateRange);
  }, []);

  if (loading)
    return (
      <Box textAlign="center" mt={10}>
        <Spinner size="xl" />
      </Box>
    );

  if (error)
    return (
      <Box p={6}>
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );

  return (
    <Box p={6}>
      
      
      <Box mb={6}>
        <Heading size="md" mb={2}>
          {flowName}
        </Heading>
        <Box color="gray.600" fontSize="sm">
          All KPIs you need to track & improve each interaction across touchpoints.
          <DateRangeSelection
            dateRange={dateRange}
            onDateChange={onDateChange}
            // isLoading={widgetQuery.isLoading}
          />
        </Box>
      </Box>

      <SimpleGrid columns={{ base: 2, md: 4, lg: 4 }} spacing={4} mb={8}>
        {analyticsData?.brief && (
          <>
            <StatBox label="Communication sent" value={analyticsData.brief.Sent} />
            <StatBox label="Clicks" value={analyticsData.brief.Clicked} />
            <StatBox label="Revenue" value={analyticsData.brief.Revenue} currency={analyticsData.brief.currency} />
            <StatBox label="CTR" value={`${analyticsData.brief.Clicked === 0 ? 0 : ((analyticsData.brief.Clicked / analyticsData.brief.Sent) * 100).toFixed(2)}%`} />
          </>
        )}
      </SimpleGrid>

      {/* Details Table */}
      <Heading size="md" mb={4}>
        Details
      </Heading>
      <TableContainer border="1px solid #ddd" borderRadius="md" shadow="md">
        <Table variant="simple">
          <Thead bg="gray.100">
            <Tr>
              <Th>Type</Th>
              <Th>Templates</Th>
              <Th>Sent</Th>
              <Th>Delivered</Th>
              <Th>Clicks</Th>
              <Th>Unique Clicks</Th>
              <Th>Opened</Th>
              <Th>Bounced</Th>
              <Th>CTR</Th>
            </Tr>
          </Thead>
          <Tbody>
            {analyticsData?.details[ Object.keys(analyticsData.details).filter((e)=> analyticsData.details[e].length > 0)]?.length > 0 ? (
              analyticsData.details[ Object.keys(analyticsData.details).filter((e)=> analyticsData.details[e].length > 0)].map((channel, index) => (
                <Tr key={index}>
                  <Td> { _.capitalize(Object.keys(analyticsData.details).filter((e)=>   analyticsData.details[e].length > 0) )}</Td>
                  <Td >
                    {channel?.template?.name}
                  </Td>
                  <Td>{channel?.all?.totalSent || "NA" }</Td>
                  <Td>{channel?.all?.totalDelivered || "NA" }</Td>
                  <Td>{channel?.all?.totalClicked || "NA" }</Td>
                  <Td>{channel?.all?.uniqueClicked || "NA" }</Td>
                  <Td>{channel?.all?.totalOpened || "NA" }</Td>
                  <Td>{channel?.all?.totalBounced || "NA" }</Td>

                  <Td>{ !_.isEmpty(Math.ceil(channel?.template?.all?.totalClicked/channel?.template?.all?.totalSent))  &&  Math.ceil(channel?.template?.all?.totalClicked/channel?.template?.all?.totalSent)?.toFixed(2) || "NA" }</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={9} textAlign="center">
                  No analytics data available.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

// Reusable Stat Box Component
const StatBox = ({ label, value, currency }) => (
  <Stat border="1px solid #ddd" borderRadius="md" p={4} shadow="md">
    <StatLabel>{label}</StatLabel>
    <StatNumber>{currency ? `${currency} ${value}` : value}</StatNumber>
  </Stat>
);

export default FlowDetailedAnalytics;
