import { Text, Box, TableContainer, Flex, Table, useToken, list } from '@chakra-ui/react'
import React, { Fragment, useState, useEffect } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient } from 'react-query'
import { getFlowsList } from '../../Services/api'
import { OvalSpinner } from '../../components/Loaders'
import TableHead from '../Campaigns/components/TableHead'
import { prepareFlowListData } from '../../_helpers/data_helper'
import FlowListTable from './components/FlowListTable'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from '@ajna/pagination'
import hexToRgba from 'hex-to-rgba'
import FlowListFilter from './components/FlowListFilter'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
function FlowsList() {
    const navigate = useNavigate();
    const [mainColor] = useToken('colors', ['primary.main'])
    const [totalCount, setTotalCount] = useState(0);
    const [campaignFilter, setCampaignFilter] = useState({});
    const queryClient = useQueryClient()
    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 10, isDisabled: false }, limits: { outer: 1, inner: 1 } })
    const flowQuery = useQuery(['flowsList', currentPage, campaignFilter], {
        queryFn: async () => {
            let res = await getFlowsList(currentPage, campaignFilter.keyword, campaignFilter.status);
            setTotalCount(res?.data?.count ?? 0);
            return prepareFlowListData(res.data)
        },
        cacheTime: 0,
        retry: 1,
        //staleTime: 0,
        enabled: false
        // /enabled: false
    })
    console.log(flowQuery,"FQ")

    const toggleStatus = (status, uuid) => {
        const currentState = queryClient.getQueryState("flowsList")
        console.log(currentState)
        let updatedData = flowQuery.data.map(item => {
            if (item.uuid === uuid) {
                item.status = !!status ? 'true' : 'false'
                return item
            }
            return item
        })
        queryClient.setQueryData(['flowsList'], (data) => {
            return updatedData
        })


    }
    useEffect(() => {
        flowQuery.refetch()
    }, [])
    return (
        <Fragment>
            <Box h={'100%'} overflow={'hidden'} display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={1}>
                    <Text fontSize={'xl'} as={'b'}>Flows </Text>
                    <OutlinedButton mb={'5px'}  label={'Create Campaign'} onClick={() => navigate("/flows/predefined/list")}></OutlinedButton>
                </Box>
                    <FlowListFilter onFilter={(dt) => setCampaignFilter(dt)}></FlowListFilter>
                <TableContainer bg={'white'} position={'relative'}  p={3} overflowY={'auto'}>
                    {
                        !!flowQuery.isLoading && <Flex justifyContent={'center'} alignItems={'center'} h={'200px'}><OvalSpinner width={50} height={50}></OvalSpinner></Flex>
                    }
                    <Table>
                        {
                             !flowQuery?.isLoading && !!flowQuery.data?.length > 0 ? (
                               <>
                                 <Fragment>
                                   <TableHead zIndex={"999"} headersProps={['Name', 'Timer', 'Created', 'Actions']}></TableHead>
                                   <FlowListTable list={flowQuery.data} refetch={() => flowQuery.refetch()}></FlowListTable>
                                 </Fragment>
                               </>
                             ) : (
                               <Flex direction="column" justifyContent="center" alignItems="center" py={10}>
                                 <Text fontSize="lg" fontWeight="bold" color="gray.600">
                                   No campaigns found for the selected date range.
                                 </Text>
                                 <Text fontSize="sm" color="gray.500" mt={2}>
                                   Create a campaign to automate your workflows and improve performance.
                                 </Text>
                                 <PrimaryButton
                                   label="Create Campaign Now"

                                   mt={4}
                                   onClick={() => navigate("/flows/predefined/list")}
                                   size="md"
                                 />
                               </Flex>
                             )

                        }
                    </Table>

                </TableContainer>
                {
                    !!flowQuery.isLoading && !!flowQuery?.data?.length && <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
                        <PaginationContainer alignItems={'center'} justifyContent="end" mt={2}>
                            <PaginationPrevious mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} minH={8} height={8} minW={8}>&lt;</PaginationPrevious>
                            <PaginationPageGroup height={8} >
                                {
                                    pages.map(page => (
                                        <PaginationPage minH={8} key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                                    ))
                                }
                            </PaginationPageGroup>
                            <PaginationNext ml={2} w={8} minH={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
                        </PaginationContainer>
                    </Pagination>
                }
            </Box>
        </Fragment>

    )
}

export default FlowsList
