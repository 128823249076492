import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Input,
  Checkbox,
  IconButton,
  Text,
  Textarea,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { useParams, useNavigate } from "react-router-dom";
import ImageGallery from "../../components/ImageGallery";
import {
  getContentPersonalization,
  createOrEditContentPersonalization,
  deleteContentPersonalization,
} from "../../Services/api";

const ContentPersonalization = () => {
  const { id: routeId } = useParams();
  const navigate = useNavigate();

  const [editId, setEditId] = useState(null);
  const [step, setStep] = useState(1);
  const [campaignForm, setCampaignForm] = useState({
    default_image_url: "",
    setup: [],
    audience: [],
    schedule_start_at: false,
    schedule_stop_at: false,
    schedule_start_at: "",
    schedule_stop_at: "",
  });
  const [basicInfo, setBasicInfo] = useState({
    name: "",
    description: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [section, setSection] = useState("setup"); // to toggle between setup and audience

  useEffect(() => {
    if (routeId) {
      setEditId(routeId);
      fetchExistingRecord(routeId);
    } else {
      setEditId(null);
    }
  }, [routeId]);

  const fetchExistingRecord = async (id) => {
    try {
      const response = await getContentPersonalization(id);
      const data = response.data;
      const parsed = data.campaign_data;

      setCampaignForm((prev) => ({
        ...prev,
        default_image_url: data.default_image_url || "",
        schedule_start_at: data.schedule_start_at || "",
        schedule_stop_at: data.schedule_stop_at || "",
        setup: parsed.setup || [],
        audience: parsed.audience || [],
      }));

      setBasicInfo({
        name: parsed.name || "",
        description: parsed.description || "",
      });

      setErrorMessage("");
    } catch (err) {
      setErrorMessage("Failed to load existing record.");
    }
  };

  const handleInputChange = (section, index, key, value) => {
    if (section === "default") {
      setCampaignForm((prev) => ({
        ...prev,
        [key]: value,
      }));
      return;
    }

    const updatedSection = [...campaignForm[section]];
    updatedSection[index] = { ...updatedSection[index], [key]: value };
    setCampaignForm((prev) => ({
      ...prev,
      [section]: updatedSection,
    }));
  };

  const addKeyValuePair = (section) => {
    setCampaignForm((prev) => ({
      ...prev,
      [section]: [
        ...prev[section],
        {
          utm_content: "",
          banner_image_url: "",
          tag: section === "audience" ? "" : undefined, // remove 'utm_content' for audience
        },
      ],
    }));
  };

  const removeKeyValuePair = (section, index) => {
    const updatedSection = campaignForm[section].filter((_, i) => i !== index);
    setCampaignForm((prev) => ({
      ...prev,
      [section]: updatedSection,
    }));
  };

  const handleCheckboxChange = (field) => {
    setCampaignForm((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const validateStep1 = () => {
    if (!campaignForm.default_image_url.trim()) {
      setErrorMessage("Default image URL is mandatory.");
      return false;
    }
    if (campaignForm.schedule_start_at && !campaignForm.schedule_start_at) {
      setErrorMessage("Please provide a Start Date/Time or uncheck 'Schedule Start'.");
      return false;
    }
    if (campaignForm.schedule_stop_at && !campaignForm.schedule_stop_at) {
      setErrorMessage("Please provide a Stop Date/Time or uncheck 'Schedule Stop'.");
      return false;
    }
    if (section == 'setup') {
        for (const item of campaignForm.setup) {
          if (!item.utm_content.trim() || !item.banner_image_url.trim()) {
            setErrorMessage("All Setup UTM Campaign fields must be filled.");
            return false;
          }
        }
        campaignForm.audience = []
    } else {
        for (const item of campaignForm.audience) {
          if (!item.tag.trim() || !item.banner_image_url.trim()) {
            setErrorMessage("All Audience fields must be filled.");
            return false;
          }
        }
        campaignForm.setup = []
    }
    setErrorMessage("");
    return true;
  };

  const validateStep2 = () => {
    if (!basicInfo.name.trim()) {
      setErrorMessage("Name is required.");
      return false;
    }
    if (!basicInfo.description.trim()) {
      setErrorMessage("Description is required.");
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const handleNext = () => {
    if (!validateStep1()) return;
    setStep(2);
  };

  const handleSave = async () => {
    if (!validateStep2()) return;
    setLoading(true);

    try {
      const campaignDataObj = {
        setup: campaignForm.setup,
        audience: campaignForm.audience,
      };

      const payload = {
        default_image_url: campaignForm.default_image_url,
        schedule_start_at: campaignForm.schedule_start_at
          ? campaignForm.schedule_start_at
          : null,
        schedule_stop_at: campaignForm.schedule_stop_at
          ? campaignForm.schedule_stop_at
          : null,
        campaignData: campaignDataObj,
        name: basicInfo.name,
        description: basicInfo.description,
      };

      if (editId) {
        await createOrEditContentPersonalization(editId, payload, false);
      } else {
        await createOrEditContentPersonalization(null, payload, true);
      }

      navigate("/content-personalization");
    } catch (error) {
      setErrorMessage("Something went wrong while saving.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={6}>
      <Box borderWidth={1} p={4} borderRadius="lg" mb={6}>
        {step === 1 && (
          <>
            <h2 className="text-xl font-semibold">
              {editId
                ? "Edit Content Personalization - Step 1"
                : "Create Content Personalization - Step 1"}
            </h2>

            {errorMessage && (
              <Text color="red.500" mt={2} mb={2}>
                {errorMessage}
              </Text>
            )}

            {/* Select Section (Setup or Audience) */}
            <RadioGroup onChange={setSection} value={section}>
              <Stack direction="row">
                <Radio value="setup">Setup UTM Campaign</Radio>
                <Radio value="audience">Audience</Radio>
              </Stack>
            </RadioGroup>

            {/* Default Image Section */}
            <Box mt={4} mb={4}>
              <h3 className="text-lg font-medium">Default Image</h3>
              <Box display="flex" gap={2} alignItems="center" mt={2}>
                <Input
                  placeholder="Enter default image URL"
                  value={campaignForm.default_image_url}
                  onChange={(e) =>
                    handleInputChange("default", null, "default_image_url", e.target.value)
                  }
                />
                <ImageGallery
                  setImageData={(image) =>
                    handleInputChange("default", null, "default_image_url", image?.mediaFileUrl || "")
                  }
                  renderComponent={<Button>Select Default Image</Button>}
                />
              </Box>
            </Box>

            {/* Dynamic Section Based on Radio Selection */}
            <Box mt={4}>
              {section === "setup" && (
                <>
                  <h3 className="text-lg font-medium">Setup UTM Campaign</h3>
                  {campaignForm.setup.map((item, index) => (
                    <Box key={index} display="flex" gap={2} alignItems="center" mb={2}>
                      <Input
                        placeholder="UTM Content"
                        value={item.utm_content}
                        onChange={(e) =>
                          handleInputChange("setup", index, "utm_content", e.target.value)
                        }
                      />
                      <Input
                        placeholder="Banner Image URL"
                        value={item.banner_image_url}
                        onChange={(e) =>
                          handleInputChange("setup", index, "banner_image_url", e.target.value)
                        }
                      />
                      <ImageGallery
                        setImageData={(image) =>
                          handleInputChange("setup", index, "banner_image_url", image?.mediaFileUrl || "")
                        }
                        renderComponent={<Button>Select Banner Image</Button>}
                      />
                      <IconButton
                        aria-label="Remove"
                        icon={<DeleteIcon />}
                        onClick={() => removeKeyValuePair("setup", index)}
                      />
                    </Box>
                  ))}
                  <Button leftIcon={<AddIcon />} onClick={() => addKeyValuePair("setup")}>
                    Add UTM Content
                  </Button>
                </>
              )}

              {section === "audience" && (
                <>
                  <h3 className="text-lg font-medium">Audience</h3>
                  {campaignForm.audience.map((item, index) => (
                    <Box key={index} display="flex" gap={2} alignItems="center" mb={2}>
                      <Input
                        placeholder="Audience Tag"
                        value={item.tag}
                        onChange={(e) =>
                          handleInputChange("audience", index, "tag", e.target.value)
                        }
                      />
                      <Input
                        placeholder="Banner Image URL"
                        value={item.banner_image_url}
                        onChange={(e) =>
                          handleInputChange("audience", index, "banner_image_url", e.target.value)
                        }
                      />
                      <ImageGallery
                        setImageData={(image) =>
                          handleInputChange("audience", index, "banner_image_url", image?.mediaFileUrl || "")
                        }
                        renderComponent={<Button>Select Banner Image</Button>}
                      />
                      <IconButton
                        aria-label="Remove"
                        icon={<DeleteIcon />}
                        onClick={() => removeKeyValuePair("audience", index)}
                      />
                    </Box>
                  ))}
                  <Button leftIcon={<AddIcon />} onClick={() => addKeyValuePair("audience")}>
                    Add Audience
                  </Button>
                </>
              )}
            </Box>

            {/* Schedule Section */}
            <Box mt={4}>
              <Checkbox
                isChecked={campaignForm.schedule_start_at}
                onChange={() => handleCheckboxChange("schedule_start_at")}
              >
                Schedule Start
              </Checkbox>
              {campaignForm.schedule_start_at && (
                <Input
                  type="datetime-local"
                  value={campaignForm.schedule_start_at}
                  onChange={(e) =>
                    handleInputChange("default", null, "schedule_start_at", e.target.value)
                  }
                />
              )}
            </Box>

            <Box mt={4}>
              <Checkbox
                isChecked={campaignForm.schedule_stop_at}
                onChange={() => handleCheckboxChange("schedule_stop_at")}
              >
                Schedule Stop
              </Checkbox>
              {campaignForm.schedule_stop_at && (
                <Input
                  type="datetime-local"
                  value={campaignForm.schedule_stop_at}
                  onChange={(e) =>
                    handleInputChange("default", null, "schedule_stop_at", e.target.value)
                  }
                />
              )}
            </Box>

            <Box mt={4}>
              <Button onClick={handleNext} colorScheme="blue">
                Next
              </Button>
            </Box>
          </>
        )}

        {step === 2 && (
          <>
            <h2 className="text-xl font-semibold">
              {editId
                ? "Edit Content Personalization - Step 2"
                : "Create Content Personalization - Step 2"}
            </h2>

            <Box mt={4}>
              <Text>Name</Text>
              <Input
                value={basicInfo.name}
                onChange={(e) => setBasicInfo({ ...basicInfo, name: e.target.value })}
                placeholder="Enter Campaign Name"
              />
            </Box>

            <Box mt={4}>
              <Text>Description</Text>
              <Textarea
                value={basicInfo.description}
                onChange={(e) => setBasicInfo({ ...basicInfo, description: e.target.value })}
                placeholder="Enter Campaign Description"
              />
            </Box>

            <Box mt={4}>
              <Button onClick={handleSave} colorScheme="green" isLoading={loading}>
                Save
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ContentPersonalization;
