import React, { Fragment } from 'react';
import { Tr, Td, Box, Text, Flex, Icon, useToast } from '@chakra-ui/react';
import { FiEdit } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import moment from 'moment';
import Swal from 'sweetalert2';
import { deleteSmsTemplate } from '../../Services/api';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

export default function SmsListTable({ list, setPreviewMsg, previewData }) {
    const toast = useToast();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const mutation = useMutation(
        (uuid) => deleteSmsTemplate(uuid),
        {
            onSuccess: () => {
                // Invalidate the `smsTemplates` query and refetch
                queryClient.invalidateQueries('templates'); // Ensure this matches the query key
                toast({ title: 'Template deleted successfully', status: 'success' });
            },
            onError: (error) => {
                toast({ title: 'Oops!', description: 'Something went wrong: ' + error.message, status: 'error' });
            }
        }
    );

    const deleteTemplate = (uuid) => {
        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to delete the template?",
            showCancelButton: true,
            confirmButtonText: 'Okay',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                console.log('Attempting to delete template with UUID:', uuid);
                mutation.mutate(uuid); // Perform deletion
            } else {
                console.log('Cancel button clicked');
            }
        }).finally(() => {
            Swal.close();
        });
    };

    return (
        <Fragment>
            {list.length > 0 && list.map(item => (
                <Tr key={item.uuid} bg={item?.uuid === previewData?.uuid ? 'gray.200' : ''}>
                    <Td borderRight={'1px solid #ebebeb'} cursor={'pointer'} w={'80%'} onClick={() => setPreviewMsg(item)}>
                        <Box>
                            <Text title='Preview'>{item.templateName}</Text>
                            <Text fontSize={'0.6vw'} color={'light'}>{moment(item.createdDate).format("MMM D, YYYY h:mm A")}</Text>
                        </Box>
                    </Td>
                    <Td>
                        <Flex gap={3}>
                            <Icon as={FiEdit} cursor={'pointer'} onClick={() => navigate("/sms/create/"+item.uuid, { state: item })} />
                            <Icon as={FaTrash} cursor={'pointer'} onClick={() => deleteTemplate(item.uuid)} />
                        </Flex>
                    </Td>
                </Tr>
            ))}
        </Fragment>
    );
}
