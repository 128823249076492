import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { Flex, FormControl, FormLabel, Checkbox, Text, Box, useDisclosure, Slider, SliderFilledTrack, SliderTrack, SliderThumb, Tooltip, Divider, HStack, VStack, Stack, Icon } from "@chakra-ui/react";
import Select from '../../components/themeComponents/react-chakra-select';
//import Select, { components } from "react-select";
import createReactClass from 'create-react-class';
import { useQuery, useQueryClient } from 'react-query';
import { getPredefinedSegmentCount, getSegmentCounts, getSegmentList, getTotalSubscribers, getUploadedFiles, getUploadedFilesAllBanks, updateSegment, getSegmentListAllBanks, refreshSegment } from '../../Services/api';
import { prepareAllBankCSVList, prepareCSVList, prepareSegmentList } from '../../_helpers/data_helper';
import { OvalSpinner } from '../../components/Loaders';
import UploadCsvModal from '../../Pages/Campaigns/components//UploadCsvModal';
import { useDispatch, useSelector } from 'react-redux';
// import { getTargetAudienceTypes } from '../../_helpers/helpers';
import _ from 'lodash'
import { checkForInvalidCsv, extractSelectedCsvFromTargetAudience, calculateReach, updateCsvListAfterValidation } from '../../_helpers/data_helper';
import store from '../../store/store';
import CsvValidatorModal from '../../Pages/Campaigns/components/CsvValidatorModal';
import Reach from '../Campaigns/components/Reach';
import CreateSegmentModal from './CreateSegmentModal';
import { IoRefresh } from 'react-icons/io5';
// import Reach from './Reach';

// import { MdGraphicEq } from 'react-icons/md';
// import { segmentDummyData } from './segmentDummyData';
// import { Checkbox, CheckboxGroup } from '@chakra-ui/react'

function AudienceSelectionTG({isSegmentModalOpen, setIsSegmentModalOpen, segmentQuery, setSegmentsList, segmentsList, targetGroupData, setTargetGroupData, selectedChannel, orgToken, orgId, reach, formik, showSliders, setShowSlider, index, uploadedFilesQuery, segmentCountQuery, setReachForOrgs, reachForOrgs , location}) {

    const [totalReach, setTotalReach] = useState(0)
    const [reachToolTip, setReachToolTip] = useState(false)
    const [customSegmentCounts, setCustomSegmentCounts] = useState([])
    const queryClient = useQueryClient();
    const [sliderValue, setSliderValue] = useState({ CSV: [], segment: [] });
    
    const [showReadyState, setShowReadyState] = useState(false)
    // const sliderValue = {
    //     CSV:[],
    //     segment:[]
    // }
    const [actualReach, setActualReach] = useState({ CSV: [], segment: [] });
    const [orgList, setOrgList] = useState(store.getState()?.org?.orgList)
    const [selectedCsv, setSelectedCsv] = useState([])
    const invalidCsvCount = useSelector(state => state.campaign.invalidCsvCount)
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [segmentId, setSegmentId] = useState("")
    const { isOpen: isValidationModalOpen, onOpen: onValidationModalOpen, onClose: onValidationModalClose } = useDisclosure()
    const dispatch = useDispatch();
    const subscribersCountQuery = useQuery('subscriberCount', {
        queryFn: async () => {
            let res = await getTotalSubscribers(orgToken.org_token, selectedChannel);
            return res.data
        },
        //enabled: false,
        staleTime: 0
    })


    const customSegmentQuery = useQuery('segmentsCustom', {
        queryFn: async () => {
            //let predefined = await getSegmentList(orgToken, 'predefined', orgId)
            // let custom = await updateSegment(orgToken, 'custom', orgId)
            let custom = await getSegmentList(orgToken.org_token, 'custom', orgId)

            return prepareSegmentList(custom.data)
        },
        enabled: false,
        staleTime: 0
    })




    const predefinedSegmentCountQuery = useQuery('predefinedSegmentCount', {
        queryFn: async () => {
            let res = await getPredefinedSegmentCount(orgToken.org_token);
            return res.data;
        },
        staleTime: 0,
        enabled: false
    })
    const onSegmentSelect = (selectedValues, e) => {
        
        let filteredSegments = [];

        if (!_.isEmpty(segmentCountQuery.data)) {

            !!segmentCountQuery?.data.data[index]?.result?.data?.length && segmentCountQuery?.data?.data[index]?.result.data?.forEach((segment) => {

                if (!_.isEmpty(segment.aggregates) && segment?.id === e?.option?.data?.id && e?.action !== 'remove-value') {

                    filteredSegments.push(segment);

                    setCustomSegmentCounts([...customSegmentCounts, filteredSegments[0]]);

                    setTargetGroupData((prevData) => ({
                        ...prevData,
                        [orgToken.org_token]: {
                            ...prevData[orgToken.org_token],
                            segment: filteredSegments,
                        },
                    }));
                }
                if (!_.isEmpty(segment?.data?.aggregates) && segment?.data?.id === e?.option?.data?.id && e.action !== 'remove-value') {

                    filteredSegments.push(segment);

                    setCustomSegmentCounts([...customSegmentCounts, filteredSegments[0]]);

                    setTargetGroupData((prevData) => ({
                        ...prevData,
                        [orgToken.org_token]: {
                            ...prevData[orgToken.org_token],
                            segment: filteredSegments,
                        },
                    }));
                }
                if (!_.isEmpty(segment.aggregates)  || Array.isArray(e) ) {
                    
                    let selectedSegment = _.filter(selectedValues,{id: segment?.id  } )
                    if(!!selectedSegment.length){
                        filteredSegments.push(selectedSegment[0]);
                    }

                    setCustomSegmentCounts([...customSegmentCounts, filteredSegments[0]]);

                    setTargetGroupData((prevData) => ({
                        ...prevData,
                        [orgToken.org_token]: {
                            ...prevData[orgToken.org_token],
                            segment: filteredSegments,
                        },
                    }));
                }

                if (!_.isEmpty(segment?.data?.aggregates) &&Array.isArray(e ) ){
                    let selectedSegment = _.filter(selectedValues, {data: {id: segment?.data?.id}}   )
                    filteredSegments.push(selectedSegment);

                    setCustomSegmentCounts([...customSegmentCounts, filteredSegments[0]]);

                    setTargetGroupData((prevData) => ({
                        ...prevData,
                        [orgToken.org_token]: {
                            ...prevData[orgToken.org_token],
                            segment: filteredSegments,
                        },
                    }));
                }
              
              
            });
        }

        let updatedCustomSegmentCounts = customSegmentCounts;
        if (!_.isEmpty(filteredSegments)) {
            updatedCustomSegmentCounts = [...updatedCustomSegmentCounts, filteredSegments[0]];
        }
        if (e.action === 'remove-value' && updatedCustomSegmentCounts.length > 0) {
            updatedCustomSegmentCounts = updatedCustomSegmentCounts.filter((value) => value.id !== e.removedValue.id);
            setTargetGroupData((prevData) => ({
                ...prevData,
                [orgToken.org_token]: {
                    ...prevData[orgToken.org_token],
                    segment: selectedValues,
                },
            }))

            // setSliderValue((prevSliderValue) => ({
            //     ...prevSliderValue,
            //     segment: prevSliderValue.segment.filter((_, index) => customSegmentCounts[index]?.id !== e.removedValue.id)
            // }));
            // setActualReach((prevActualReach) => ({
            //     ...prevActualReach,
            //     segment: prevActualReach.segment.filter((_, index) => customSegmentCounts[index]?.id !== e.removedValue.id)
            // }));
        }

        setCustomSegmentCounts(updatedCustomSegmentCounts);

        formik.setFieldValue('targetedAudience.includes.segment', _.isEmpty(updatedCustomSegmentCounts) ? selectedValues : updatedCustomSegmentCounts);

        if (e?.option?.value === 'sendToAll') {
            setTargetGroupData((prevData) => ({
                ...prevData,
                [orgToken.org_token]: {
                    ...prevData[orgToken.org_token],
                    segment: selectedValues,
                },
            }))
            if (e?.removedValue === 'sendToAll') {
                setTargetGroupData((prevData) => ({
                    ...prevData,
                    [orgToken.org_token]: {
                        ...prevData[orgToken.org_token],
                        segment: selectedValues,
                    },
                }))
                // queryClient.setQueryData(['segments'], (data) => {
                //     return data.map(datum => datum.value !== 'sendToAll' ? { ...datum, isDisabled: true } : datum);
                // });
            }

            // if (e?.removedValue?.value === 'sendToAll') {
            //     queryClient.setQueryData(['segments'], (data) => {
            //         return data.map(datum => datum.value !== 'sendToAll' ? { ...datum, isDisabled: false } : datum);
            //     });
            // }


        };
        console.log(targetGroupData)
        calculateReach(formik, dispatch);
    }

    const onCsvSelect = (selectedValues, e) => {

        
        if (e?.option?.data?.extraData?.validationData?.counts || Array.isArray(e) && e?.[0].data?.extraData?.validationData?.counts) {
            setTargetGroupData((prevData) => ({
                ...prevData,
                [orgToken.org_token]: {
                    ...prevData[orgToken.org_token],
                    csv: selectedValues,
                },
            }));
        }
        if (e?.action == 'remove-value') {
            setTargetGroupData((prevData) => ({
                ...prevData,
                [orgToken.org_token]: {
                    ...prevData[orgToken.org_token],
                    csv: selectedValues,
                },
            }));
        }
        console.log("TG data", targetGroupData, JSON.stringify(selectedValues))
        formik.setFieldValue("targetedAudience.includes.CSV", selectedValues);
        setTimeout(() => {
            checkForInvalidCsv(selectedValues, dispatch)
        }, 100)
        calculateReach(formik, dispatch);
    }
    const onCsvUpload = (file) => {
        onClose();
        let tempCSVList = [{ label: file?.fileName, value: file?.id, segmentType: 'CSV', data: file }, ...targetGroupData[orgToken.org_token].csv]
        formik.setFieldValue('targetedAudience.includes.CSV', tempCSVList)
        setTimeout(() => {
            checkForInvalidCsv(tempCSVList, dispatch)
        }, 100)
        // setSelectedCsv()
        queryClient.setQueryData(['uploadedFiles'], (data) => {
            return [{ label: file?.fileName, value: file?.id, data: file }, ...data];

        })
    }

    const onAllSubscribersCheckboxCheck = (e) => {
        if (!(formik.values.targetedAudience.includes.segment.length)) {
            formik.setFieldValue("targetedAudience.includes.segment", [JSON.parse(e.target.value)])
        }
    }

    const cofirmCsvValidationAndUpdate = (list) => {

        onValidationModalClose()
        let updatedSelectedList = updateCsvListAfterValidation(formik?.values?.targetedAudience?.includes?.CSV, list, orgToken.org_token);
        // let updatedCSVList = updateCsvListAfterValidation(uploadedFilesQuery?.data, list, orgToken)
        formik.setFieldValue("targetedAudience.includes.CSV", updatedSelectedList);
        // queryClient.setQueryData(['uploadedFiles'], (data) => {
        //     return updatedCSVList
        // })
        checkForInvalidCsv(updatedSelectedList, dispatch);
    }


    // useEffect(()=>{
    //     calculateReach(formik,dispatch,subscribersCountQuery)
    // },[subscribersCountQuery])
    useEffect(() => {
        console.log(segmentsList)
    }, [segmentsList])
    useEffect(() => {
        if (selectedChannel !== 'push') {
            !segmentQuery.isFetched && segmentQuery.refetch();
            // !uploadedFilesQuery.isFetched && uploadedFilesQuery.refetch();
            //predefinedSegmentCountQuery.refetch();
        }
        
        setReachForOrgs(prevState => ({
            ...prevState,
            [orgToken.org_token]: totalReach
        }));
        

    }, [selectedChannel, totalReach, reach])
    useEffect(()=>{
        if(!!location?.state?.data[0]?.targetedAudience.includes?.length){
            let csvArr = []
            let segmentArr = []
    
            location?.state?.data.forEach((cam,i)=>{
                
                cam.targetedAudience.includes.forEach((dt)=>{
                
                    if(dt.segmentType=='CSV' && cam.orgToken == uploadedFilesQuery[index].org_token){
                        
                        csvArr.push( _.filter(prepareCSVList(uploadedFilesQuery[index]?.data?.uploadedFiles),{data: {url:dt.selectedSegment }})[0])
                        if(!!csvArr?.length &&  !!csvArr[0]){
                            setTimeout(()=>{
                                onCsvSelect(csvArr,csvArr)
                            },1500)
                        }
                    }
                    
                    if(dt.segmentType=='custom'){
                        !!segmentQuery.data && segmentArr.push( _.filter( segmentCountQuery?.data?.data[index]?.result?.data,{ id:dt.selectedSegment })[0])
                        setTimeout(() => {
                            onSegmentSelect(segmentArr,segmentArr)
                        }, 1500);
                    }
                }
                )
            }
                )
        
            }
        
    },[segmentQuery.data, uploadedFilesQuery[index].data,uploadedFilesQuery.org_token,index])
useEffect(()=>{
    
    // if(!!location?.state?.data[0]?.targetedAudience.includes?.length){
    //     let csvArr = []
    //     let segmentArr = []

    //     location?.state?.data.forEach((cam)=>{
    //         cam.targetedAudience.includes.forEach((dt)=>{
            
    //             if(dt.segmentType=='CSV'){

    //                 csvArr.push( _.filter(prepareCSVList(uploadedFilesQuery[index]?.data?.uploadedFiles),{data: {url:dt.selectedSegment }})[0])
    //                 if(!!csvArr?.length &&  !!csvArr[0]){
                        
    //                     onCsvSelect(csvArr,csvArr)
    //                 }
    //             }
                
    //             if(dt.segmentType=='custom'){
                    
    //                 !!segmentQuery.data && segmentArr.push( _.filter( segmentCountQuery?.data?.data[index]?.result?.data,{data: {id:dt.selectedSegment }})[0])
    //                 onSegmentSelect(segmentArr,segmentArr)
    //             }
    //         }
    //         )
    //     }
    //         )
    
    //     }
            
        
        
},[segmentQuery])
    return (
        <Flex p={3} gap={5}  direction={'column'}>
            {/* {
                index == 0 && <>
                    <Text cursor={'pointer'} textAlign={'end'} textDecoration={'underline'} float={'right'} onClick={() => setIsSegmentModalOpen(true)

                    }> Create Segment +

                    </Text>
                    {
                        !!segmentId &&
                        <Icon as={IoRefresh} onClick={() => refreshSegment(orgId, segmentId)} />
                    }
                    {
                        !!showReadyState && <Text>Ready</Text>
                    }
                </>
            } */}
            <input type='hidden'  {...formik.getFieldProps('targetedAudience.includes.segment')} />
            {
                (selectedChannel === 'push') && <FormControl>
                    <FormLabel color={'inputLable'}>Send to (Segments) 

                         </FormLabel>

                    <Select isMulti options={[{ label: 'All Subscribers', value: 'sendToAll', segmentType: 'sendToAll' }]} closeMenuOnSelect={false} value={formik?.values?.targetedAudience?.includes?.segment} onChange={onSegmentSelect} colorScheme='brand' isClearable={false}></Select>
                    {/* <Text onClick={() => setIsSegmentModalOpen(true) } >Create Segment</Text> */}
                    <Icon as={IoRefresh} onClick={() => refreshSegment(orgId, segmentId)} />
                </FormControl>
            }

            {
                (selectedChannel !== 'push') &&
                <Fragment>
                    <FormControl  >
                    {/* {JSON.stringify(segmentQuery)} */}
                        <input type='hidden'  {...formik.getFieldProps('targetedAudience.includes.CSV')} />
                        <input type='hidden'  {...formik.getFieldProps('targetedAudience.includes.segment')} />
                        <FormLabel color={'inputLable'}>Send to (Segments)  
                        
                        <Text color={'link'} as={'span'} ml={'15px'}  mt={1} cursor={'pointer'} onClick={ ()=> segmentQuery.refetch()} >Refresh <Icon as={IoRefresh} ></Icon> </Text>

                        </FormLabel>
                        {/* <Text onClick={()=>setIsSegmentModalOpen(true)} textDecoration={'underline'} cursor={'pointer'} >Create Segment</Text> */}
                        <CreateSegmentModal formik={formik} orgId={orgToken?.org_token} orgToken={orgToken?.org_token} isOpen={isSegmentModalOpen}
                            setSegmentId={setSegmentId}
                            onClose={() => {
                                setIsSegmentModalOpen(false)
                                segmentQuery.refetch()
                                !!segmentId &&
                                setTargetGroupData(
                                    prevState => ({
                                        ...prevState,
                                        [orgToken.org_token]:  segmentQuery?.data.filter(data => data.id === segmentId)
                                    }))
                                
                                    
                            }
                            } />
                        {
                            !!segmentQuery.isLoading && <Box display={'grid'} placeItems={'center'}><OvalSpinner height={20} width={20}></OvalSpinner></Box>
                        }
                        {
                            !segmentQuery?.data || !segmentQuery?.data[index]?.result.data?.length && <Text>No Segments Found</Text>
                        }


                        {
                            // !!segmentsList[index] && 
                            
                            !!segmentQuery?.data && !!segmentQuery?.data[index]?.result.data?.length &&
                            <>
                                <Select isMulti value={(targetGroupData[orgToken.org_token]?.segment)}
                                    options={prepareSegmentList(segmentQuery?.data[index]?.result?.data)} isOptionDisabled= {()=>targetGroupData[orgToken.org_token]?.segment.length >=1}  onChange={onSegmentSelect} colorScheme='brand' isClearable={false}>
                                </Select>
                            </>
                        }


                    </FormControl>

                    <FormControl>
                        <FormLabel color={'inputLable'}>Send to (CSV)</FormLabel>
                        {
                            !!uploadedFilesQuery.isLoading && <Box display={'grid'} placeItems={'center'}><OvalSpinner height={20} width={20}></OvalSpinner></Box>
                        }
                        {

                            <Fragment>
                                {

                                    <Select isMulti options={!!uploadedFilesQuery[index]?.data && prepareCSVList(uploadedFilesQuery[index]?.data?.uploadedFiles)}
                                    isOptionDisabled= {()=>targetGroupData[orgToken.org_token]?.csv.length >=1}
                                        closeMenuOnSelect={false}
                                        value={ !!targetGroupData[orgToken?.org_token] && (targetGroupData[orgToken?.org_token]['csv'])}
                                        colorScheme='brand' onChange={onCsvSelect} isClearable={false}></Select>

                                }
                                {
                                    !!invalidCsvCount.length && <Text color={'danger'} onClick={onValidationModalOpen} float={'left'} mt={1} cursor={'pointer'}>You have {invalidCsvCount.length} invalid CSV. Click here to validate. </Text>
                                }

                                <Text color={'link'} float={'right'} mt={1} cursor={'pointer'} onClick={onOpen}>Add new CSV+</Text>
                            </Fragment>

                        }
                        <UploadCsvModal isOpen={isOpen} onClose={onClose} channel={selectedChannel} orgToken={orgToken.org_token} onSelect={onCsvUpload}></UploadCsvModal>
                        {/* {JSON.stringify(uploadedFilesQuery[index].org_token)} */}
                        <CsvValidatorModal isOpen={isValidationModalOpen} onClose={onValidationModalClose} csvList={formik.values.targetedAudience.includes.CSV} channel={selectedChannel} orgToken={uploadedFilesQuery[index].org_token} invalidCsv={invalidCsvCount} onConfirm={cofirmCsvValidationAndUpdate}></CsvValidatorModal>
                    </FormControl>
                </Fragment>
            }
            <Box>
                <Reach selectedChannel={selectedChannel} subscribersCountQuery={subscribersCountQuery} setTotalReach={setTotalReach} audience={targetGroupData[orgToken.org_token]} query={subscribersCountQuery} ></Reach>
            </Box>
        </Flex>
    )
}

export default AudienceSelectionTG
