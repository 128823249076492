import React, { useState } from "react";
import {
  Tbody,
  Tr,
  Td,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Textarea,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { deleteContentPersonalization, getWebsiteCode } from "../../Services/api";
import moment from "moment";

function ContentPersonalizationTable({ list, refetch }) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [websiteCode, setWebsiteCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const toast = useToast();

  const handleDelete = async (uuid) => {
    try {
      await deleteContentPersonalization(uuid);
      refetch();
    } catch (error) {
      console.error("Error deleting content personalization:", error);
    }
  };

  const handleShowWebsiteCode = async (uuid) => {
    setLoading(true);
    try {
      const response = await getWebsiteCode(uuid);
      setWebsiteCode(response.data.websiteBlockCode);
      onOpen();
    } catch (error) {
      console.error("Error fetching website code:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    setCopySuccess(true);
    toast({
      title: "Code copied.",
      description: "The website code has been copied to your clipboard.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <>
      <Tbody>
        {list.map((item) => (
          <Tr key={item.uuid}>
            <Td>{item.name}</Td>
            <Td>{item.description}</Td>
            <Td>{moment(item.created_at).format('LL')} {moment(item.created_at).format('LT')} </Td>
            <Td>
            <Button
                size="sm"
                colorScheme="teal"
                onClick={() => handleShowWebsiteCode(item.uuid)}
                mr={2}
              >
                Show website code
              </Button>
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => navigate(`/content-personalization/edit/${item.uuid}`)}
                mr={2}
              >
                Edit
              </Button>
              <Button
                size="sm"
                colorScheme="red"
                onClick={() => handleDelete(item.uuid)}
                mr={2}
              >
                Delete
              </Button>

            </Td>
          </Tr>
        ))}
      </Tbody>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Website Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loading ? (
              <Spinner />
            ) : (
              <Textarea
                value={websiteCode}
                isReadOnly
                height="300px"
                fontFamily="monospace"
                fontSize="sm"
              />
            )}
          </ModalBody>
          <ModalFooter>
            <CopyToClipboard text={websiteCode} onCopy={handleCopy}>
              <Button colorScheme="blue" mr={3} isDisabled={!websiteCode}>
                Copy Code
              </Button>
            </CopyToClipboard>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ContentPersonalizationTable;
