import React, { useEffect, useState } from 'react';
import { Box, Input, Button, Text, FormControl, FormLabel, VStack, HStack, Checkbox, Image, useToast, Textarea, SimpleGrid, Grid } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../Services/axiosInstance';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import InappPreview from './InappPreview';
import Select from '../../components/themeComponents/react-chakra-select';
import * as _ from "lodash"
import ImageGallery from '../../components/ImageGallery';
import ColorPicker from '../../components/themeComponents/ColorPicker';
export default function MobileInappTemplate() {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const toast = useToast();
  const layoutIds = [
    { label: "Header", value: "001" },
    { label: "Footer", value: "002" },
    { label: "Modal", value: "003" },
    { label: "Full Screen", value: "004" }
  ]
  const templateTypes = [
    { label: "Text", value: "Text Only" },
    { label: "Image", value: "Image Only" }, ,
    { label: "Image & Text", value: "Image & Text" },

  ]
  const initialValues = {
    templateName: '',
    layoutId: '001',
    templateType: {
      type: 'image&text',
      pushType: 'inApp'
    },
    templateTitle: 'Title goes here',
    templateImageUrl: [''],
    templateMessage: 'Message goes here', 
    templateDisplayName: 'Template Name',
    templateDescription: 'Description goes here',
    notificationDetails: {
      buttonOrientation: 'horizontal',
      templateOrientation: 'top',
      templateBackground: '#ffffff',
      titleColor: '#000000',
      titleFontSize: '14',
      descriptionFontSize: '12',
      descriptionFontColor: '#060101',
      isclose: true,
      imageOrientation: 'fullLayout',
      textAllignment: 'center',
    },
    buttons: [],
    targetPlatform: ['android']
  };
  const buttonOrientation = [
    { label: 'Horizontal', value: 'horizontal' },
    { label: 'Vertical', value: "vertical" }


  ]
  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      Swal.fire({
        title: uuid ? 'Update Template' : 'Create Template',
        text: uuid ? 'Do you want to update this template?' : 'Do you want to save this template?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      }).then((result) => {
        if (result.isConfirmed) {
          uuid ? updateTemplate(values) : createTemplate(values);
        }
      });
    },
  });

  const createTemplate = (data) => {
    axiosInstance.post('/rest/v1/push/android/template', data)
      .then(() => {
        toast({ title: 'Template created successfully', status: 'success' });
        navigate('/inapp/list');
      });
  };

  const updateTemplate = (data) => {
    axiosInstance.put(`/rest/v1/push/android/template/${uuid}`, data)
      .then(() => {
        toast({ title: 'Template updated successfully', status: 'success' });
        navigate('/inapp/list');
      });
  };

  useEffect(() => {
    if (uuid) {
      axiosInstance.get(`/rest/v1/push/android/template/${uuid}`)
        .then(({ data }) => {
          formik.setValues({ ...data.data });
        });
    }
  }, [uuid]);

  const addButton = () => {
    formik.setFieldValue('buttons', [
      ...formik.values.buttons,
      { buttonName: '', buttonUrl: '', buttonColor: '#000000', buttonFontColor: '#FFFFFF', buttonFontSize: '14' }
    ]);
  };

  const removeButton = (index) => {
    const buttons = [...formik.values.buttons];
    buttons.splice(index, 1);
    formik.setFieldValue('buttons', buttons);
  };

  return (
    <Grid templateColumns="repeat(2, 1fr)" gap={6} p={4}>

      <Box bg={'#fff'} p={'1rem'} >
        <Text fontSize={'1.5vw'} fontWeight={'500'} >
          Create Template
        </Text>
        <form onSubmit={formik.handleSubmit}>
          <FormControl mb={4}>
            <FormLabel>Template Name</FormLabel>
            <Input {...formik.getFieldProps('templateName')} />
            <FormLabel>
              Template Background
              <Box w={'300px'} mt={'10px'} >

            <ColorPicker value={formik.values?.notificationDetails?.templateBackground} onColorSelect={(val) => formik.setFieldValue("notificationDetails.templateBackground", val)}></ColorPicker>
              </Box>
            </FormLabel>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Template Title</FormLabel>
            <Input {...formik.getFieldProps('templateTitle')} />
            <Box w={'300px'} mt={'10px'} >

            <ColorPicker value={formik.values?.notificationDetails?.titleColor} onColorSelect={(val) => formik.setFieldValue("notificationDetails.titleColor", val)}></ColorPicker>
            </Box>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Template Description</FormLabel>
            
            <Textarea {...formik.getFieldProps('templateDescription')} onChange={(e)=>formik.setFieldValue("templateMessage",e.target.value)} />
              <Box w={'300px'} mt={'10px'} >

            <ColorPicker value={formik.values?.notificationDetails?.descriptionFontColor} onColorSelect={(val) => formik.setFieldValue("notificationDetails.descriptionFontColor", val)}></ColorPicker>
              </Box>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Layout</FormLabel>
            <Select
              value={_.filter(layoutIds, { value: formik.values.layoutId })}
              onChange={(e) => formik.setFieldValue('layoutId', e.value)}
              name={'layoutId'}
              options={layoutIds} >

            </Select>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Template Type</FormLabel>

            <Select
              value={_.filter(templateTypes, { value: formik.values.templateType.type })}
              onChange={(e) => formik.setFieldValue('templateType.type', e.value)}
              name={'templateType.type'}
              options={templateTypes} >

            </Select>
          </FormControl>

          <FormControl mb={4}>
            <FormLabel>Template Image URL</FormLabel>
            {formik.values.templateImageUrl.map((url, index) => (
              <HStack key={index} mb={2}>
                <Input value={url} onChange={(e) => {
                  const updatedUrls = [...formik.values.templateImageUrl];
                  updatedUrls[index] = e.target.value;
                  formik.setFieldValue('templateImageUrl[0]', updatedUrls);
                }} />

              </HStack>

            ))}
            
            <ImageGallery
              setImageData={(value) =>
                formik.setFieldValue(
                  "templateImageUrl[0]",
                  value.mediaFileUrl
                )
              }
              renderComponent={
                <Text
                  cursor={"pointer"}
                  textDecoration={"underline"}
                  fontSize={"0.8vw"}
                >
                  Add Image+
                </Text>
              }
            />
          </FormControl>
          <FormControl mb={4}>
            
            
              <Checkbox isChecked={formik.values?.notificationDetails?.isclose} onChange={(e)=>formik.setFieldValue("notificationDetails.isclose",e?.target?.checked)}  >Close Button</Checkbox>
            
          </FormControl>
          <FormControl mb={4}>
            <FormLabel>Button Orientation</FormLabel>
            <Select
              value={_.filter(buttonOrientation, { value: formik.values?.notificationDetails?.buttonOrientation })}
              options={buttonOrientation}
              onChange={(e) => formik.setFieldValue("notificationDetails?.buttonOrientation", e.value)}
            >

            </Select>
          </FormControl>

          <Button onClick={addButton} mb={4}>Add Button</Button>
          {formik.values.buttons.map((btn, index) => (
  <Box key={index} border='1px solid #ccc' p={2} my={2} borderRadius="md">
    <FormControl mb={2}>
      <FormLabel>Button Name</FormLabel>
      <Input 
        value={btn.buttonName} 
        placeholder='Button Name' 
        onChange={(e) => formik.setFieldValue(`buttons[${index}].buttonName`, e.target.value)} 
      />
    </FormControl>

    <FormControl mb={2}>
      <FormLabel>Button URL</FormLabel>
      <Input 
        value={btn.buttonUrl} 
        placeholder='Button URL' 
        onChange={(e) => formik.setFieldValue(`buttons[${index}].buttonUrl`, e.target.value)} 
      />
    </FormControl>

    <FormControl mb={2}>
      <FormLabel>Button Background Color</FormLabel>
      <ColorPicker 
        value={btn.buttonColor} 
        onColorSelect={(val) => formik.setFieldValue(`buttons[${index}].buttonColor`, val)} 
      />
    </FormControl>

    <FormControl mb={2}>
      <FormLabel>Button Font Color</FormLabel>
      <ColorPicker 
        value={btn.buttonFontColor} 
        onColorSelect={(val) => formik.setFieldValue(`buttons[${index}].buttonFontColor`, val)} 
      />
    </FormControl>

    <FormControl mb={2}>
      <FormLabel>Button Font Size</FormLabel>
      <Input 
        type="number" 
        value={btn.buttonFontSize} 
        onChange={(e) => formik.setFieldValue(`buttons[${index}].buttonFontSize`, e.target.value)} 
      />
    </FormControl>

    <Button colorScheme='red' onClick={() => removeButton(index)}>Remove</Button>
  </Box>
))}

        </form>
        <PrimaryButton label={uuid ? 'Update Template' : 'Create Template'} colorScheme='blue' onClick={formik.handleSubmit}>
        </PrimaryButton>
      </Box>
      <Box>
        <InappPreview formData={formik.values} type={formik.values.layoutId} />
      </Box>
    </Grid>
  );
}
