import React, { useEffect } from 'react'
import { Card, Image, Stack, CardBody, Heading, CardFooter, Button, Text, Box, Icon, UnorderedList, ListItem, OrderedList, VStack, Divider } from '@chakra-ui/react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { Link } from 'react-router-dom';
import * as _ from "lodash"

export default function TemplateCard({ content }) {
    return (
        <Link to={ !_.isEmpty(content.navigateTo.pathname)  && content.navigateTo.pathname} state={content.navigateTo.state}  >
        <Card
            direction={{ base: 'column', sm: 'row' }}
            overflow='hidden'
            borderRadius={'5px'}
            variant='outline'
            height={'17vh'}
            width={'33vw'}
            bg={'#ebebeb'}
            border={'1px solid #DACFF0'}
            _hover={{
                border: "1px solid #3182CE",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
            }}
        >
            <Box width={'25%'} display={'flex'} bg={'#ebebeb'} justifyContent={'center'} alignItems={'center'} >
                <Icon
                        color={'#5B4FA5'}
                        objectFit={'cover'}
                        width={'4vw'}
                        height={'auto'}
                        as={content.icon}
                />
            </Box>
            <Divider orientation='vertical' height={'80%'} mt={'3%'} />
            <Stack width={'75%'} >
                <CardBody width={'100%'} display={'flex'}
                    padding={'1rem'}
                    flexDirection={'column'} >
                    <Text fontSize={'1vw'}>{content.title}</Text>
                    {
                        !!content.comingSoon &&
                        <Text padding={'0.3rem'} borderRadius={'4px'} bg={'#7E80E7'} fontSize={"0.5vw"} position={'absolute'} right={"20px"} color={"white"}  width={'fit-content'} >
                        COMING SOON
                    </Text>
                    }
                    <Text fontSize={'0.8vw'} width={'80%'} >
                        {content.description}
                    </Text>
                    <VStack mt={'1vh'} alignItems={'self-start'} >
                        </VStack>
                </CardBody>
                <CardFooter display={'flex'} p={0} width={'inherit'} justifyContent={'end'}>
                        {/* <PrimaryButton right={'1vw'} borderRadius={'1vw'} height={'1.5vh'} 
                        padding={'0.8vw 0.8vw'} position={'absolute'} bottom={'0.8vw'} fontSize={'0.7vw'} label={content.btnLabel} /> */}
                </CardFooter>

            </Stack>
        </Card>
                    </Link>
    )
}
