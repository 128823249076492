import React, { useEffect, useState } from 'react'
// import AudienceSelection from "../Campaigns/components/AudienceSelection"
import { useQuery, useQueryClient } from 'react-query';
import { getPredefinedSegmentCount, getSegmentCounts, getSegmentList, getTotalSubscribers, getUploadedFiles, getUploadedFilesAllBanks, updateSegment, getSegmentListAllBanks, getSegmentCountsAllBanks } from '../../Services/api';
import AudienceSelectionTG from './AudienceSelectionTG'
import { Box, Flex, Text, VStack } from '@chakra-ui/react'
import { getTemplateRatioIncrementer, prepareSegmentList } from '../../_helpers/data_helper';
import store from '../../store/store';
import { calcLength } from 'framer-motion';
import * as _ from "lodash"
import Select from '../../components/themeComponents/react-chakra-select';
import SegmentList from '../Audience/SegmentList';
import SegmentListModal from './SegmentListModal';
export default function AudieceSelection({ formik, targetGroupData, setTargetGroupData, setReachForOrgs, reachForOrgs, location }) {
  const [segmentsList, setSegmentsList] = useState({})
  const orgList = store.getState().org.orgList
  const [segmentsListModalOpen, setSegmentsListModalOpen] = useState(false)
  const [isSegmentModalOpen, setIsSegmentModalOpen] = useState(false)
  const [selectedOrgToken, setSelectedOrgToken] = useState("")
  const uploadedFilesQuery = useQuery('uploadedFiles', {
    queryFn: async () => {
      let res = await getUploadedFilesAllBanks(formik.values.orgList, formik.values.orgList, formik.values.selectedChannel);
      console.log("res", res);

      return res.data;
    },
    enabled: true,
    staleTime: 0
  })
  const segmentQuery = useQuery('segments', {
    queryFn: async () => {
      let custom = await getSegmentListAllBanks('custom', formik.values.orgList)
      return custom.data.data
    },
    enabled: true,
    staleTime: 0
  })
  const getOrgName = (token) => {

    let orgName = orgList.filter((e) => e.token == token)
    return orgName[0]?.name || ''
  }
  let segmentCount = {
    predefined: [],
    custom: [],
    rfm: []
  };
  const segmentCountQuery = useQuery('segmentCounts', {
    queryFn: async () => {

      let segmentCounts = await getSegmentCountsAllBanks(formik.values.orgList)
      if (segmentCounts.status == 200) {
        segmentCount['custom'] = segmentCounts.data
      }
      return segmentCounts.data
    },
    enabled: true,
    staleTime: 0
  })
  const openCreateSegmentModal = () => {
    setIsSegmentModalOpen(true)
  }
  useEffect(()=>{
    if(!!location?.state?.data[0]){
      
      formik.setFieldValue("reachPercentage",location?.state?.data[0]?.reachPercentage)
    }
  },[])

  return (
    <>
    <Flex bg={'#fff'} padding={'1rem'} margin={"10px"}  alignItems={'center'} justifyContent={'space-between'} >
      
      <Text>
        Reach : {Math.ceil((formik?.values?.reachPercentage/100)*_.sum(Object.values(reachForOrgs)))}/{_.sum(Object.values(reachForOrgs))}
        <Box   w={'250px'} >

        <Select  onChange={(v)=>formik.setFieldValue("reachPercentage", v.value)} value = {_.filter(getTemplateRatioIncrementer(),{value: formik?.values?.reachPercentage})}  options={getTemplateRatioIncrementer()} ></Select>
        
        </Box>

      </Text>
      <Text justifySelf={'end'} color={'link'} float={'right'}  width={'fit-content'}  ml={'15px'}  cursor={'pointer'}textAlign={'end'} onClick={openCreateSegmentModal} >
        Create Segment +
      </Text>
    </Flex>
      
      {
        uploadedFilesQuery?.data?.length > 0 && uploadedFilesQuery.data.map((e, i) => (
          <>
            {
              !!e.org_token &&
              <>
                <Box bg={'#fff'} padding={'1rem'} margin={"10px"} >
                <Text float={'right'} width={'fit-content'} color={'link'}  ml={'15px'}  mt={1} cursor={'pointer'} textAlign={'end'} onClick={()=>{
                  setSelectedOrgToken(e?.org_token)
                  setSegmentsListModalOpen(true)}} >
        View Segment List
      </Text>
                  {/* {JSON.stringify(uploadedFilesQuery?.data)} */}
                  <Text fontSize={'1vw'} w={"100%"} >
                    Select Audience For  <Box as={'span'} fontWeight={'500'} > {getOrgName(e.org_token)}</Box>
                  </Text>
                  <AudienceSelectionTG location={location} isSegmentModalOpen={isSegmentModalOpen} setIsSegmentModalOpen={setIsSegmentModalOpen} reachForOrgs={reachForOrgs} setReachForOrgs={setReachForOrgs} segmentCountQuery={segmentCountQuery} segmentQuery={segmentQuery} setSegmentsList={setSegmentsList} uploadedFilesQuery={uploadedFilesQuery.data} targetGroupData={targetGroupData} setTargetGroupData={setTargetGroupData} selectedChannel={"email"} index={i} orgId={e.org_token} orgToken={e} formik={formik} showSliders={false} ></AudienceSelectionTG>
                </Box>

                <SegmentListModal onClose={()=>setSegmentsListModalOpen(false)} isOpen={segmentsListModalOpen} orgId={selectedOrgToken}/>
              </>
            }
          </>
        ))
      }
    </>
  )
}
