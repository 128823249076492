import React, { useEffect, useState } from 'react'
import FacebookLoginButton from './FacebookLoginButton';
import axiosInstance from '../../Services/axiosInstance';
import FacebookPages from "./FacebookPages"
import Swal from "sweetalert2";
import { Box, Icon, Image, Text, useToast } from '@chakra-ui/react';
import { BiLogOut } from 'react-icons/bi';
export default function FacebookAuth() {
    const [isfbLoggedIn, setIsFbLoggedIn] = useState(false);
    const [fbUser, setFbUser] = useState({});
    const toast = useToast()
    let saveFbCode = () => {
        let url = process.env.REACT_APP_API_ENDPOINT + window.location.pathname + window.location.search;
        axiosInstance.get(url).then((res) => {
            console.log(res?.data);
        }).catch((e) => {
            console.log(e)
        })
    }
    let checkFbCredentials = () => {
        let url = process.env.REACT_APP_API_ENDPOINT + "/fb/fbmessenger/credentials?restful=true&channel=facebook";
        axiosInstance.get(url).then((res) => {
            console.log(res?.data);
            setIsFbLoggedIn(res?.data?.loggedIn);
        })
    }
    let getFbUserDetails = () => {
        let url = "/fb/userinfo";
        axiosInstance.get(url).then((res) => {
            setFbUser(res?.data)

        }).catch((error) => {

            toast({ title: 'Oops!', description: error?.response?.data?.message, status: 'error' });
        })


    }
    const logoutFromFb = () => {


        Swal.fire({
            title: "Are you sure?",
            text: "You will be logged out from Facebook!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, logout!",
        }).then((result) => {
            if (result.isConfirmed) {
                axiosInstance.post(`/fb/fbmessenger/clearfblogin`, {})
                    .then(res => {
                        checkFbCredentials();
                        console.log(res.data);
                        toast({ title: res.data.status, description: res.data.message, status: res.data.status });

                    })
                    .catch(error => {

                    });
            }
        });

    }

    useEffect(() => {
        checkFbCredentials();
        // if(!!isfbLoggedIn){
        getFbUserDetails()
        // }
        if (!isfbLoggedIn) {
            if (window.location.search.includes("code")) {
                saveFbCode();
            }
        }

    }, [])
    return (
        <>

            {
                !isfbLoggedIn &&
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <FacebookLoginButton />
                </div>
            }
            {
                !!isfbLoggedIn &&
                <>
                    {
                        <>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                                <div class="profile-card">
                                    <img id="profile-pic" class="profile-pic" src={fbUser?.picture?.data?.url} alt="Profile Picture" />
                                    <div id="user-name" class="user-name"></div>
                                    <button class="logout-btn" onClick={logoutFromFb}>
                                        <img src='/images/flows/white/meta.svg' height={'30px'} width={'30px'} />
                                        Logout from Meta
                                    </button>
                                </div>
                            </div>

                        </>

                    }
                    {/* <FacebookPages /> */}
                </>
            }

        </>
    )
}
