import { useNavigate } from "react-router-dom";
import { Button, Icon } from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import OutlinedButton from "./OutlinedButton";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    
    <Icon cursor={'pointer'}  mr={'15px'}   onClick={() => navigate(-1)} height={'30px'} color={'gray.500'} width={'30px'} as={IoArrowBackCircleOutline} />
    
      
    
  );
};

export default BackButton;