import {
  Grid,
  GridItem,
  Text,
  Box,
  Container,
  useToken,
  useToast,
  Flex,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import moment from "moment";
import hexToRgba from "hex-to-rgba";

import DateRangeSelection from "../components/DateRangeSelection";
import Widget from "../components/themeComponents/Widget";
import { CiMoneyBill } from "react-icons/ci";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdOutlineCampaign } from "react-icons/md";
import { FiShoppingCart } from "react-icons/fi";
import { DougnutCanvas, PieCanvas } from "../components/Charts";
import { OvalSpinner } from "../components/Loaders";

import {
  getWidgetsData,
  getCampaignRevenue,
  getEvents,
  getSubscribersCount,
  getRevenueBreakdown,
  getRevenueStats,
  fetchPowerBiUrl,
} from "../Services/api";

import {
  prepareRevenueBreakdownData,
  prepareEventsChartData,
  prepareAllEvents,
  prepareDefaultsEvents,
  prepareRevenueStats,
  prepareSubscribersCountData
} from "../_helpers/data_helper";

import {
  getEventsData,
  getRevenueData,
  topPerformingCampaign,
} from "./dashboardDummyData";
import { useParams } from "react-router-dom";

function Dashboard() {
  const org = JSON.parse(localStorage.selectedOrg)
  const white_label = useSelector((state) => state.auth.white_label) || sessionStorage.getItem("white_label");
  const entityName = white_label?.entityName || white_label?.data?.entityName;
  const isDemoAccount = useSelector((state) => state.auth.user_data?.extraData?.is_demo_account);
  
  const [stripTable, primary, heading] = useToken("colors", ["stripTable", "primary.main", "heading"]);
  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(15, "days"),
    endDate: moment(),
  });
  const {url,name} = useParams()
  const queryClient = useQueryClient();
  const [powerBiData, setPowerBiData] = useState(null);
  const toast = useToast();
  const [iframeSrc, setIframeSrc] = useState("")

  // Queries
  const widgetQuery = useQuery(["revenueData", dateRange], {
    queryFn: () => getWidgetsData(org.token, dateRange),
    onError: (error) => toast({ title: error?.message || "Something went wrong" }),
    cacheTime: 0,
  });

  const campaignRevenueQuery = useQuery(["campaignRevenue", dateRange], {
    queryFn: async () => {
      let res = await getCampaignRevenue(org.token, dateRange);
      if (isDemoAccount) {
        res = topPerformingCampaign();
      }
      return prepareRevenueBreakdownData(res?.data, isDemoAccount);
    },
    onError: (error) => toast({ title: error?.message || "Something went wrong" }),
    cacheTime: 0,
  });

  const subscribersQuery = useQuery(["subscribersCount", dateRange], {
    queryFn: async () => {
      let res = await getSubscribersCount(org.token, dateRange);
      return prepareSubscribersCountData(res?.data, isDemoAccount);
    },
    cacheTime: 0,
  });

  const powerBiUrlQuery = useQuery(["powerBiUrl"], {
    queryFn: async () => {
      
      let response = await fetchPowerBiUrl();
      try{
        setPowerBiData(JSON.parse(response.data.powerbiUrl) );
      }catch(e){
        setPowerBiData(response.data.powerbiUrl);
      }
      
    },
    onError: (error) => toast({ title: error?.message || "Error fetching Power BI URL" }),
    retry: 0,
    enabled: true,
  });

  useEffect(() => {
    if(!!url){
      setIframeSrc(atob(url.replaceAll("mkt","/"))); 
      powerBiUrlQuery.refetch();
    }else{
      powerBiUrlQuery.refetch();
    }
  }, []);

  useEffect(()=>{
    
    if(Array.isArray(powerBiData)){
      
      try{
        setIframeSrc(atob(url?.replaceAll("mkt","/"))  || powerBiData[0].url?.replaceAll("mkt","/"))
      }catch(e){
        setIframeSrc( powerBiData[0]?.url?.replaceAll("mkt","/"))
      }
    }else{
      setIframeSrc(powerBiData)
    }
  },[powerBiData,url,name])
  
  
  const onDateChange = (date) => {
    setDateRange({ startDate: date.startDate, endDate: date.endDate });
  };

  if (powerBiUrlQuery.isLoading) {
    return <OvalSpinner height="60px" label="Loading..." />;
  }

  return (
    <Fragment>
      {iframeSrc ? (
        <iframe
          src={iframeSrc}
          style={{ width: "100%", height: "100%", border: "none", overflowY: "scroll" }}
          title="Power BI"
        />
      ) : (
        <>
          <DateRangeSelection
            dateRange={dateRange}
            onDateChange={onDateChange}
            isLoading={widgetQuery.isLoading}
          />
          <Container maxW="unset" m="0" w="100%">
            <Box display="flex" justifyContent="space-between" my={4}>
              <Text as="b" fontSize="xl">Dashboard</Text>
            </Box>
            <Grid templateColumns="repeat(4, 1fr)" gap="0.8vw">
              <GridItem>
                <Widget
                  value={isDemoAccount ? 329832012 : widgetQuery?.data?.data?.total_Revenue}
                  title={`Revenue by ${entityName}`}
                  icon={CiMoneyBill}
                  isLoading={widgetQuery.isFetching || widgetQuery.isLoading}
                />
              </GridItem>
              <GridItem>
                <Widget
                  value={isDemoAccount ? 23092332 : widgetQuery?.data?.data?.average_orders_value}
                  title="Order value per Customer"
                  icon={BiMoneyWithdraw}
                  isLoading={widgetQuery.isFetching || widgetQuery.isLoading}
                />
              </GridItem>
              <GridItem>
                <Widget
                  value={isDemoAccount ? 392832 : widgetQuery?.data?.data?.orders}
                  title={`Orders by ${entityName}`}
                  icon={FiShoppingCart}
                  isLoading={widgetQuery.isFetching || widgetQuery.isLoading}
                />
              </GridItem>
              <GridItem>
                <Widget
                  value={isDemoAccount ? 872 : widgetQuery?.data?.data?.campaign_sent}
                  title="Campaign Sent"
                  icon={MdOutlineCampaign}
                  isLoading={widgetQuery.isFetching || widgetQuery.isLoading}
                />
              </GridItem>
            </Grid>
            <Grid gridTemplateColumns="1fr 1fr 1fr" gap="1vw" mt="0.6vw">
              <GridItem>
                <Box bg="white" p="0.8vw">
                  <Text as="b" color="heading" fontSize="0.9vw">Revenue Breakdown</Text>
                  {campaignRevenueQuery.isLoading ? (
                    <OvalSpinner height={20} color={primary} />
                  ) : (
                    <DougnutCanvas dataPoints={campaignRevenueQuery.data.chartData} />
                  )}
                </Box>
              </GridItem>
              <GridItem>
                <Box bg="white" p="0.8vw">
                  <Text as="b" color="heading" fontSize="0.9vw">Top Performing Campaigns</Text>
                  {campaignRevenueQuery.isLoading ? (
                    <OvalSpinner height={20} color={primary} />
                  ) : (
                    <PieCanvas dataPoints={campaignRevenueQuery.data.bestPerformingCampaigns} />
                  )}
                </Box>
              </GridItem>
            </Grid>
          </Container>
        </>
      )}
    </Fragment>
  );
}

export default Dashboard;
