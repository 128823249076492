import {
  Text,
  Alert,
  AlertIcon,
  FormLabel,
  Select,
  Checkbox,
  Input,
  FormControl,
  Box,
  VStack,
  HStack,
  useToast,
} from "@chakra-ui/react";
import React, { Fragment, useEffect, useState } from "react";
import { getSegmentProps, getSegmentViewDetails } from "../../../Services/api";
import { eventValueOperators } from "../../../_helpers/data_helper";
import { Form } from "react-router-dom";
import * as _ from "lodash"
const allOperators = {
  text: [
    { key: "equals", label: "Equals" },
    { key: "not_equals", label: "Not Equals" },
    { key: "contains", label: "Contains" },
    { key: "not_contains", label: "Does Not Contain" },
    { key: "starts_with", label: "Starts With" },
    { key: "ends_with", label: "Ends With" },
  ],
  string:[
    { key: "equals", label: "Equals" },
    { key: "not_equals", label: "Not Equals" },
    { key: "contains", label: "Contains" },
    { key: "not_contains", label: "Does Not Contain" },
    { key: "starts_with", label: "Starts With" },
    { key: "ends_with", label: "Ends With" },
  ],
  number: [
    { key: "equals", label: "Equals" },
    { key: "not_equals", label: "Not Equals" },
    { key: "greater_than", label: "Greater Than" },
    { key: "less_than", label: "Less Than" },
    { key: "greater_than_or_equals", label: "Greater Than or Equals" },
    { key: "less_than_or_equals", label: "Less Than or Equals" },
  ],
  int: [
    { key: "equals", label: "Equals" },
    { key: "not_equals", label: "Not Equals" },
    { key: "greater_than", label: "Greater Than" },
    { key: "less_than", label: "Less Than" },
    { key: "greater_than_or_equals", label: "Greater Than or Equals" },
    { key: "less_than_or_equals", label: "Less Than or Equals" },
  ],
  date: [
    { key: "equals", label: "Equals" },
    { key: "before", label: "Before" },
    { key: "after", label: "After" },
    { key: "between", label: "Between" },
  ],
  time: [
    { key: "equals", label: "Equals" },
    { key: "before", label: "Before" },
    { key: "after", label: "After" },
    { key: "between", label: "Between" },
  ],
  datetime: [
    { key: "equals", label: "Equals" },
    { key: "before", label: "Before" },
    { key: "after", label: "After" },
    { key: "between", label: "Between" },
  ],
  email: [
    { key: "equals", label: "Equals" },
    { key: "not_equals", label: "Not Equals" },
    { key: "contains", label: "Contains" },
    { key: "not_contains", label: "Does Not Contain" },
  ],
  checkbox: [
    { key: "is", label: "Is" },
    { key: "is not", label: "Is Not" }
    
  ]
};

export default function InspectorForCondition({
  formData,
  selectedElement,
  updateFormData,
  validateFormData,
  isCustomAttribute,
  setIsCustomAttribute,
  segmentAttributes,
  setSegmentAttributes
}) {
  const [segmentProps, setSegmentProps] = useState([]);
  const [segmentationRawProps, setSegmentationRawProps] = useState({});
  const inputTypes = [
    "text",
    "number",
    "date",
    "time",
    "datetime",
    "email",
    "checkbox",
    "string",
    "int",
    
  ];
  const [inputTypesState, setInputTypesState] = useState([]);
  const toast = useToast()
  const orgId = JSON.parse(localStorage.selectedOrg)?.token
  useEffect(() => {
    const fetchSegmentProps = async () => {
      try {
        const segmentationProps = await getSegmentProps() || {};
        
        if(segmentationProps.status === 200 && !segmentationProps?.data?.segmentationProps || !segmentationProps?.data ){
          toast({title: 'Oops!', description: 'Something went wrong while fetching the data', status: 'error'});
        }
        if (segmentationProps.status === 200 && !!segmentationProps.data.segmentationProps) {
          let customerKey = Object.keys(segmentationProps.data.segmentationProps).find((key) =>
            key.includes("customer")
          );
  
          
          if (!customerKey) {
            customerKey = Object.keys(segmentationProps.data.segmentationProps)[0];
          }
  
          setSegmentationRawProps(segmentationProps.data.segmentationProps[customerKey]);
  
          if (customerKey) {
            setSegmentProps(
              Object.keys(segmentationProps.data.segmentationProps[customerKey]).map((prop) => {
                return segmentationProps.data.segmentationProps[customerKey][prop].value;
              })
            );
          }
        }
      } catch (error) {
        toast({title: 'Oops!', description: 'Something went wrong while fetching the data', status: 'error'});
      }
    };
  
    fetchSegmentProps();
  }, []);
  
  
  useEffect(() => {
    if (selectedElement && formData[selectedElement.id]) {
      const criteria = formData[selectedElement.id]?.nextActionCondition?.criteria || [];
      try{

        const newInputTypesState = criteria.map(
          (criterion) => segmentationRawProps[criterion.property]?.inputType 
        );
        
        setInputTypesState(newInputTypesState);
      }catch(e){
        toast({title: 'Oops!', description: 'Something went wrong while fetching the data', status: 'error'});
      }
    }
  }, [selectedElement, formData, segmentationRawProps]);
  
 
  const getPreviousElementActionData = (currentElementId) => {
    const previousElementId =
      formData[currentElementId]?.previousElementIds?.[0];
    if (
      previousElementId &&
      formData[previousElementId]?.actionData?.availableActionEvents
    ) {
      return formData[previousElementId].actionData.availableActionEvents;
    }
    return [];
  };

  const handleAttributeChange = (index, field, value,dataType) => {
    
    const updatedAttributes = [
      ...formData[selectedElement.id].nextActionCondition.criteria,
    ];

    updatedAttributes[index][field] = value;
    updatedAttributes[index]["key"] = "customer"
    if (field === "property") {
      
      const newInputTypesState = [...inputTypesState];
      newInputTypesState[index] =
        segmentationRawProps[value]?.inputType || dataType
      setInputTypesState(newInputTypesState );
    }
    const updatedFormData = {
      ...formData,
      [selectedElement.id]: {
        ...formData[selectedElement.id],
        nextActionCondition: {
          ...formData[selectedElement.id].nextActionCondition,
          criteria: updatedAttributes,
        },
      },
    };
    updateFormData(updatedFormData);
  };

  const handleRemoveAttribute = (index) => {
    const updatedAttributes = [
      ...formData[selectedElement.id].nextActionCondition.criteria,
    ];
    updatedAttributes.splice(index, 1);

    const newInputTypesState = [...inputTypesState];
    newInputTypesState.splice(index, 1);
    setInputTypesState(newInputTypesState);

    const updatedFormData = {
      ...formData,
      [selectedElement.id]: {
        ...formData[selectedElement.id],
        nextActionCondition: {
          ...formData[selectedElement.id].nextActionCondition,
          criteria: updatedAttributes,
        },
      },
    };
    updateFormData(updatedFormData);
  };

  const handleAddAttribute = () => {
    getSegmentAttributes()
    const updatedAttributes = [
      ...formData[selectedElement.id].nextActionCondition.criteria,
      { key: "", operator: "", value: "" , type: "", inputType: ""},
    ];

    const newInputTypesState = [...inputTypesState];
    setInputTypesState(newInputTypesState);

    const updatedFormData = {
      ...formData,
      [selectedElement.id]: {
        ...formData[selectedElement.id],
        nextActionCondition: {
          ...formData[selectedElement.id].nextActionCondition,
          criteria: updatedAttributes,
        },
      },
    };
    updateFormData(updatedFormData);
  };
  const getSegmentAttributes = async () =>{
    let segmentId = formData['beginsWith'].nextActionCondition.criteria[0].selectedSegmentId
    console.log(segmentId)
    let res = await getSegmentViewDetails(orgId, segmentId)
    setSegmentAttributes(res?.data?.data?.aggregates?.keys)
    
    
  }
  const renderAttributes = (attributes) => {
    return attributes.map((attribute, index) => (
      index !=0 &&
      <Box key={index} width={'100%'} borderRadius="md" mb={4}>
        
        <Text>
         Attributes Type
          </Text> 
        <Select
            value={attribute?.type}
            onChange={(e) => handleAttributeChange(index,"type", e.target.value)}
          >
            {
              ["predefined","custom"].map((type)=>{
                return <option key={type} value={type}>{type}</option>
              })
            }
          </Select>
        <HStack width={'100%'} justify="space-between">
          <Text
            onClick={() => handleRemoveAttribute(index)}
            cursor="pointer"
            fontSize="sm"
            textDecoration="underline"
            color="red.500"
          >
            Remove-
          </Text>
        </HStack>
        <FormControl>
          <FormLabel>Attribute</FormLabel>
          {attribute?.type == "custom" ? (
            <>
            {
              segmentAttributes?.length > 0 &&  <Select
              mt={2}
              value={attribute.property}
              placeholder="Select Attribute"
              onChange={(e) =>
                handleAttributeChange(index, "property", e.target.value, _.find(segmentAttributes,{label : e.target.value}).type)
              }
            >
              {segmentAttributes.map((key) => (
                <option key={key?.label} value={key?.label}>
                  {key?.label}
                </option>
              ))}
            </Select>
            }
            {
               !segmentAttributes?.length > 0 &&
               <>

              <Input
              type="text"
              placeholder="Enter Custom Attribute"
              mt={2}
              value={attribute.property}
              onChange={(e) =>
                handleAttributeChange(index, "property", e.target.value)
              }
              />
               <FormLabel mt={2} >Input Type</FormLabel>
              <Select
              mt={4}
              value={attribute?.inputType }
              placeholder="Select"
              onChange={(e) => {
                const newInputTypesState = [...inputTypesState];
                newInputTypesState[index] = e.target.value;
                setInputTypesState(newInputTypesState);
                handleAttributeChange(index, "inputType", e.target.value);
              }}
              >
                {inputTypes.map((key) => (
                  <option key={key} value={key}>
                    {key}
                  </option>
                ))}
              </Select>
              </>
              }
            </>
          ) : (
            <Select
              mt={2}
              value={attribute.property}
              placeholder="Select Attribute"
              onChange={(e) =>
                handleAttributeChange(index, "property", e.target.value)
              }
            >
              {segmentProps.map((key) => (
                <option key={key} value={key}>
                  {segmentationRawProps[key]?.name || key}
                </option>
              ))}
            </Select>
          )}
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Operator </FormLabel>
          <Select
            value={attribute.operator}
            placeholder="Select Operator"
            onChange={(e) =>
              handleAttributeChange(index, "operator", e.target.value)
            }
          >
            {
            !!allOperators[inputTypesState[index] || "text"] &&
            allOperators[inputTypesState[index] ||  "text" ].map((operator) => (
              <option key={operator.key} value={operator.key}>
                {operator.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Value</FormLabel>
          <Input
            type={inputTypesState[index] || attribute?.inputType }
            placeholder="Enter Value"
            value={attribute.value}
            onChange={(e) =>
              handleAttributeChange(index, "value", e.target.value)
            }
          />
        </FormControl>
      </Box>
    ));
  };

  return (
    <VStack spacing={6} align="start" width={'100%'} >
      {!!formData[selectedElement?.id]?.previousElementIds && formData[selectedElement?.id]?.previousElementIds?.length < 1 ? (
        <Alert status="error">
          <AlertIcon />
          You need to connect condition with an action first.
        </Alert>
      ) : (
        <VStack align="start" spacing={4} width={'100%'} >
          <FormControl>
            <FormLabel>
              { !!formData[selectedElement?.id]?.nextActionCondition?.criteria[0]?.property && formData[selectedElement?.id]?.nextActionCondition?.criteria[0]?.property.toUpperCase()}{" "}
              is
            </FormLabel>
            <Select
              value={
                formData[selectedElement?.id]?.nextActionCondition?.criteria[0]
                  ?.value?.[0]
              }
              placeholder="Select"
              onChange={(e) => {
                const tempFormData = { ...formData };
                tempFormData[selectedElement.id].nextActionCondition.criteria[0].value = [
                  e.target.value,
                ];
                tempFormData[selectedElement.id].nextActionCondition.criteria[0].key =
                  "channel";
                updateFormData(tempFormData);
              }}
            >
              {getPreviousElementActionData(selectedElement.id).map((dt) => (
                <option value={dt.code} key={dt.code}>
                  {dt.label}
                </option>
              ))}
            </Select>
          </FormControl>
          {
          !!formData[selectedElement?.id]?.nextActionCondition?.criteria &&
          renderAttributes(
            formData[selectedElement?.id]?.nextActionCondition?.criteria
          )}
          
          <Text
            onClick={handleAddAttribute}
            cursor="pointer"
            fontSize="sm"
            textDecoration="underline"
            color="blue.500"
          >
            Add+
          </Text>
        </VStack>
      )}
    </VStack>
  );
}
