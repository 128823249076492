import React, { Fragment, useEffect, useState } from 'react'
import { Container, Text, useSteps, Box, FormControl, FormLabel, Input, Flex, Checkbox, useToast } from '@chakra-ui/react'
import CampaignStepper from './components/CampaignStepper'
import { campaignSteps, prepareDraftCampaignData, prepareTargetedAudienceData } from '../../_helpers/data_helper'
import Select from '../../components/themeComponents/react-chakra-select'
import AudienceSelection from './components/AudienceSelection'
import PrimaryButton from "./../../components/themeComponents/PrimaryButton"
import { useQuery } from 'react-query'
import { connect, useDispatch } from 'react-redux'
import { draftCampaign, getCampaign, publishABCampaign, publishCampaign } from '../../Services/api'
import { OvalSpinner } from '../../components/Loaders'
import { Radio, RadioGroup } from '@chakra-ui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { broadcastCampaign as initialValues } from '../../_helpers/formValues'
import _ from "lodash"
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import TemplateSelection from './components/TemplateSelection'
import ScheduleCampaign from './components/ScheduleCampaign'
import { UPDATE_REACH } from '../../store/Reducers/CampaignReducer'
import { scheduleCampaign as scheduleCampaignValidationSchema } from '../../_helpers/validationSchema'
import SmsCampaignForm from './components/SmsCampaignForm'
import TemplateSelectionAB from './components/TemplateSelectionAB'
import { sendAbTest,saveMultipleSchedule } from '../../Services/api'
import TemplateselectionBroadCast from './components/TemplateselectionBroadCast'
import { FaCommentsDollar } from 'react-icons/fa'
import Swal from 'sweetalert2'
import { IoEllipseSharp } from 'react-icons/io5'
function CreateCampaign({ org, reach }) {
    const [showSliders, setShowSlider] = useState(false)
    const [totalReach, setTotalReach] = useState(0)
    // console.log(reach,"reach in main modal")
    const location = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [targetRatios, setTargetRatios] = useState({
        "0": 100
    });
    const [isValidRatios, setIsValidRatios] = useState(true);
    const [audienceType,setAudienceType ] = useState("segment")
    const { id } = useParams();
    const { activeStep, setActiveStep } = useSteps({
        index: 0, count: campaignSteps.length
    })
    const toast = useToast();
    const [errors, setErrors] = useState([]);
    const campaignQuery = useQuery('campaign', {
        queryFn: async () => {
            if(!!id){
                let res = await getCampaign(org.token, id);
                let data = prepareDraftCampaignData(res.data)
                formik.setValues(data)
                return data;
            }
        },
        onError: (error) => toast({ title: error?.message || 'Something went wrong' }),
        cacheTime: 0,
        retry: 0
    })
    const saveAbTest = async (values, action) => {
        try {
            values.broadcastType = "a_b_test_v2";
            values.broadcast = "a_b_test_v2";
            values['uuid'] = window.location.pathname.split("/")[2];
            
            let campaignData = { ...values };
            let targetedAudience = {};
    
            if (!_.isEmpty(campaignData.targetedAudience.includes.CSV) || !_.isEmpty(campaignData.targetedAudience.includes.segment)) {
                targetedAudience = prepareTargetedAudienceData(campaignData.targetedAudience, values.broadcastType, values.selectedChannel);
            }
            campaignData.targetedAudience = targetedAudience;
    
            const abTestResp = await sendAbTest(org.token, campaignData);
            
            if (abTestResp.data && abTestResp.status == '200') {
                let publishResponse = await publishABCampaign(abTestResp.data, campaignData.scheduleData, campaignData.scheduleStatus, abTestResp.data[0].orgId, values?.uuid);
                console.log('publish response', publishResponse);
                
                action.setSubmitting(false);
                toast({ title: `Navigating to login page.`, status: 'success', duration: 1000, isClosable: true });
                setIsSubmitting(false);
                navigate("/campaign/list");
            }
        } catch (err) {
            toast({ title: `Oops!`, description: err?.response?.data?.message || err?.message, status: 'error', duration: 1000, isClosable: true });
            setIsSubmitting(false); 
        }
    };
    
    const saveMultiSchedule = async (values, action) => {
        try {
            values.broadcastType = "BATCH_WISE_CONTROL_GROUP";
            values.broadcast = "BATCH_WISE_CONTROL_GROUP";
            values['uuid'] = window.location.pathname.split("/")[2];
            
            let campaignData = { ...values };
            let targetedAudience = {};
    
            if (!_.isEmpty(campaignData.targetedAudience.includes.CSV) || (!_.isEmpty(campaignData.targetedAudience.includes)) || !_.isEmpty(campaignData.targetedAudience.includes.segment)) {
                targetedAudience = prepareTargetedAudienceData(campaignData.targetedAudience, values.broadcastType, values.selectedChannel);
            }
            campaignData.targetedAudience = targetedAudience;
    
            const multiScheduleGroup = await saveMultipleSchedule(org.token, campaignData);
            let hasError = false;
    
            if (multiScheduleGroup.data && multiScheduleGroup.status == '200') {
                multiScheduleGroup?.data.forEach(datum => {
                    if (datum.result == 'failure') {
                        Swal.fire({ title: "Error", text: datum.message, icon: "error", confirmButtonText: "Okay" });
                        hasError = true;
                        setIsSubmitting(false);
                        return;
                    }
                });
                if (!hasError) {
                    action.setSubmitting(false);
                    toast({ title: `Navigating to login page.`, status: 'success', duration: 1000, isClosable: true });
                    setIsSubmitting(false);
                    navigate("/campaign/list");
                }
            }
        } catch (err) {
            toast({ title: `Oops!`, description: err?.response?.data?.message || err?.message, status: 'error', duration: 1000, isClosable: true });
            setIsSubmitting(false); 
        }
    };
    
    const saveAndPublishCampaign = async (values, action, isDraft) => {
        try {
            values['uuid'] = window.location.pathname.split("/")[2];
            
            if (values.campaignType == "multiple_schedule_v2" && !isDraft) {
                saveMultiSchedule(values, action);
                return;
            }
            
            if (values.campaignType == 'control_group_v2') {
                values['broadcastType'] = "CONTROL_GROUP";
                values['campaignType'] = "CONTROL_GROUP";
                values['broadcast'] = "CONTROL_GROUP";
            }
    
            let campaignData = { ...values };
            let targetedAudience = {};
            
            if (!_.isEmpty(campaignData.targetedAudience.includes.CSV) || !_.isEmpty(campaignData.targetedAudience.includes.segment)) {
                targetedAudience = prepareTargetedAudienceData(campaignData.targetedAudience, values.broadcastType, values.selectedChannel);
            }
            campaignData.targetedAudience = targetedAudience;
    
            const draftResponse = await draftCampaign(campaignData);
            
            if (!!isDraft) {
                setIsSubmitting(false);
                navigate('/campaign/list');
            }
    
            if (draftResponse.data && draftResponse.data.result === 'success' && !isDraft) {
                let publishResponse = await publishCampaign(org.token, draftResponse.data.id, campaignData.scheduleData);
                console.log('publish response', publishResponse);
                
                action.setSubmitting(false);
                toast({ title: `${publishResponse?.data?.result || publishResponse.data.status}. Navigating to login page.`, status: 'success', duration: 1000, isClosable: true });
                setIsSubmitting(false);
                navigate("/campaign/list");
            }
        } catch (err) {
            
            toast({ title: `Oops!`, description: err?.response?.data?.message || err?.message, status: 'error', duration: 1000, isClosable: true });
            setIsSubmitting(false); 
        }
    };
    
    const handleSubmit = async (values, action, isDraft) => {
        console.log(values, "value on handle submit");
        setIsSubmitting(true);
        try {
            saveAndPublishCampaign(values, action, isDraft);
        } catch (err) {
            setErrors(err.errors);
            setIsSubmitting(false);
            console.log('validation error', err.errors);
        }
    };
    
    
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: handleSubmit
    })
    useEffect(() => {
        if(location.state){
            if(location?.state?.data?.campaignData){

                formik.setValues(location.state.data.campaignData)
            }else{
                formik.setValues({...initialValues,...location.state})
            }
            
        }
    },[])
    // if (campaignQuery.isLoading) return <OvalSpinner height={20} width={20}></OvalSpinner>
    // if (!!_.isEmpty(campaignQuery.data)) return toast({ title: 'Not a valid campaign' })
     return (
        <Container maxW={'1230px'} p={4} height={'100%'} display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            {formik.isSubmitting}
            <CampaignStepper steps={campaignSteps} activeStep={activeStep}></CampaignStepper>
            {
                activeStep === 0 &&
                <Fragment>
                    <Box maxW={'930px'} w={'100%'} bg={'white'} m={'0 auto'} mt={5} p={3}>
                        <Text color={'heading'} as={'b'}>Campaign Information</Text>
                        <Flex p={3} gap={5}>
                            <FormControl>
                                <FormLabel color={'inputLable'}>Campaign Name</FormLabel>
                                <Input type='text' {...formik.getFieldProps('campaignName')}></Input>
                            </FormControl>
                            <FormControl>
                                <FormLabel color={'inputLable'}>Tags (Coming Soon)</FormLabel>
                                <Input type='text' disabled></Input>
                            </FormControl>
                        </Flex>
                    {/* {JSON.stringify(activeStep,formik.values.campaignType)} */}
                    </Box>
                    <Box maxW={'930px'} w={'100%'} bg={'white'} m={'0 auto'} height={'20vw'} overflowY={'scroll'} mt={5} p={3}>
                        <Text color={'heading'} as={'b'}>Audience Selection</Text>
                        <AudienceSelection setTotalReach={setTotalReach} totalReach={totalReach} audienceType={audienceType} setAudienceType={setAudienceType} selectedChannel={formik.values.selectedChannel} showSliders={showSliders} setShowSlider={setShowSlider} orgToken={org.token} orgId={org.id} reach={reach} formik={formik}></AudienceSelection>
                    </Box>
                </Fragment>
            }
            {
                activeStep === 1  && ( formik.values.campaignType !='a_b_test_v2') && <TemplateSelection const isValidRatios={isValidRatios} setIsValidRatios={setIsValidRatios} selectedChannel={formik.values.selectedChannel} orgToken={org.token} targetRatios={targetRatios} setTargetRatios={setTargetRatios} formik={formik}></TemplateSelection>
            }
            {
                activeStep === 1 &&   formik.values.campaignType == 'a_b_test_v2' && <TemplateSelectionAB selectedChannel={formik.values.selectedChannel} orgToken={org.token} reach={reach} formik={formik}></TemplateSelectionAB>
            }
            {
                // activeStep === 1 &&   formik.values.campaignType == "multiple_schedule_v2" && <TemplateselectionBroadCast selectedChannel={formik.values.selectedChannel} orgToken={org.token} reach={reach} formik={formik}></TemplateselectionBroadCast>
            }
            {
                // activeStep == 1 && formik.values.campaignType !== 'a_b_test_v2' && formik.values.selectedChannel == 'sms' && <SmsCampaignForm selectedChannel={formik.values.selectedChannel} formik={formik} />
            }
            {
                activeStep === 2 && <ScheduleCampaign selectedChannel={formik.values.selectedChannel} formik={formik} errors={errors}></ScheduleCampaign>
            }

<Flex h={'5rem'} bg={'white'} w={'calc(100% - 15rem)'} pos={'fixed'} right={'0'} bottom={0} justifyContent={'flex-end'} alignItems={'center'} px={4}>
    <OutlinedButton label={'Back'} mr={2} onClick={() => { (activeStep === 0) ? navigate("/campaign/list") : setActiveStep(activeStep - 1) }} />
    {
    
        <OutlinedButton ml={"5px"} mr={"10px"}  label={'Save Draft'} onClick={() =>handleSubmit(formik.values,formik, true)} ></OutlinedButton>
    }
    {
        (activeStep === 2) && (
            <PrimaryButton 
                label={'Publish'} 
                isDisabled={
                    !(
                    formik.values?.scheduleData?.scheduleStatus === 'SCHEDULED' && 
                        formik.values?.scheduleData?.scheduleAt
                    ) &&
                        !(
                            formik.values.campaignType!=="a_b_test_v2" && !_.isEmpty(formik?.values?.scheduleData?.targetRatios) && !_.isEmpty(formik?.values?.scheduleData?.targetRatios[0]?.templateData?.name)  && formik.values?.scheduleData?.targetRatios?.every((key,i) => !!key?.scheduleAt))
                        
                      &&
                    !(formik.values?.scheduleData?.scheduleStatus == 'NOW' ) 
                } 
                onClick={formik.handleSubmit} 
                isLoading={isSubmitting} 
                type={'submit'}
            />
        )
}
    {
        (activeStep !== 2 && formik.values.selectedChannel !== 'email') && (
            <PrimaryButton label={'Next'} isDisabled={!totalReach ||  (activeStep == 1 &&
                _.sum(Object.values(targetRatios)) != 100
            ) } onClick={() => setActiveStep(activeStep + 1)} />
        )
    }
    {
        (activeStep !== 2 && formik.values.selectedChannel === 'email') && (
            <PrimaryButton label={'Next' } isDisabled={
                (activeStep == 0    &&
                !totalReach)
                || 
                (activeStep == 1 &&
                    _.sum(Object.values(targetRatios)) != 100
                )

            
            } onClick={() => setActiveStep(activeStep + 1)} />
        )
    }
    
</Flex>

        </Container>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
        org: state.auth.selectedOrg,
        reach: state.campaign.reach
    }
}
export default connect(mapStateToProps)(CreateCampaign)
