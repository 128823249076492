import React, { useEffect, useRef, useState } from 'react'
import { Box, Text, Flex, FormControl, FormLabel, AspectRatio, Image, FormHelperText, Icon, useDisclosure, Slider, SliderTrack, SliderFilledTrack, SliderThumb, SliderMark, VStack, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, NumberInput } from '@chakra-ui/react'
import Select from '../../../components/themeComponents/react-chakra-select'
import { DeviceFrameset } from 'react-device-frameset'
import { useQuery } from 'react-query'
import { fetchEmailChannels, fetchTemplate, getSmsGatways, saveEmailChannels } from '../../../Services/api'
import { getTemplateRatioIncrementer, prepareGatewayList, prepareTemplateList, selectProvidersValue, smtpTypes } from '../../../_helpers/data_helper'
import { BsBox2Fill, BsSend } from 'react-icons/bs'
import * as htmltoImage from "html-to-image"
import html2canvas from 'html2canvas'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import TemplatePreviewModal from './TemplatePreviewModal'
import _ from "lodash";
import MobilePreview from './MobilePreview'
import PrimaryButton from '../../../components/themeComponents/PrimaryButton'
import { BiTrash } from 'react-icons/bi'
import SendTestModal from '../../../components/SendTestModal'
import { useLocation } from 'react-router-dom'
import ABTestMobilePreview from './ABTestMobilePreview'

function TemplateSelection({ selectedChannel, orgToken, formik, targetRatios, setTargetRatios, isValidRatios, setIsValidRatios }) {

    const { isOpen, onClose, onOpen } = useDisclosure()
    const [isPerviewGenerating, setIsPreviewGenerating] = useState(false)
    const [template, setTemplate] = useState({})
    const [isTestModalOpen, setIsTestModalOpen] = useState(false)
    const tempBodyRef = useRef(null)
    const location = useLocation()
    const templateQuery = useQuery(['fetchTemplate'], {
        queryFn: async () => {
            
            let res = await fetchTemplate(selectedChannel || formik?.values?.selectedChannel, orgToken, selectedGatewayUuid, formik.values.selectedWhatsappProvider);

            if (!location?.state?.data?.campaignData) {
                
                formik.setFieldValue("selectedTempName", prepareTemplateList(selectedChannel, res?.data)[0]?.label)

                formik.setFieldValue(`scheduleData.targetRatios[${0}].templateData.uuid`, prepareTemplateList(selectedChannel, res?.data)[0].uuid);
                formik.setFieldValue(`scheduleData.targetRatios[${0}].templateData.name`, prepareTemplateList(selectedChannel, res?.data)[0]?.label);

                formik.setFieldValue("selectedTemp", prepareTemplateList(selectedChannel, res?.data)[0].uuid)
                // formik.setFieldValue("selectedTemp1", prepareTemplateList(selectedChannel, res?.data)[0].uuid)
            }
            // formik.setFieldValue("selectedTempName",res?.data[0]?.name)

            return prepareTemplateList(selectedChannel, res?.data)
        }
    })


    const handleTargetRatioChange = (temp, value) => {
        if (temp > 5) {
            console.error("Cannot adjust more than 6 templates");
            return;
        }

        const clampedValue = Math.min(Math.floor(value), 100);

        setTargetRatios((prevRatios) => {
            const newRatios = { ...prevRatios };
            newRatios[temp] = clampedValue; // Update the value for the selected template

            // Calculate the total sum of ratios
            const adjustedSum = Object.values(newRatios).reduce((acc, val) => acc + val, 0);

            // If the sum is not 100, set the flag to false
            if (adjustedSum !== 100) {
                setIsValidRatios(false);
            } else {
                setIsValidRatios(true);
            }

            // Update Formik fields
            Object.keys(newRatios).forEach((ratioKey, i) => {
                formik.setFieldValue(`targetedAudience.includes[0].targetRatio${i + 1}`, newRatios[ratioKey]);
                formik.setFieldValue(`scheduleData.targetRatios[${i}].ratio`, newRatios[ratioKey]);
            });

            return newRatios;
        });

        // Update campaign type based on the first targetRatio
        if (targetRatios[0] < 100) {
            formik.setFieldValue("campaignType", "multiple_schedule_v2");
            formik.setFieldValue("broadcastType", "multiple_schedule_v2");
            formik.setFieldValue("broadcast", "multiple_schedule_v2");
        } else {
            formik.setFieldValue("campaignType", "BROADCAST_V2");
            formik.setFieldValue("broadcastType", "BROADCAST_V2");
            formik.setFieldValue("broadcast", "BROADCAST_V2");
        }
    };



    const [templates, setTemplates] = useState([""])
    // const [template, setTemplate] = useState({
    //     "0": {},
    //     "1": {},
    //     "2": {},
    //     "3": {},
    //     "4": {},
    //     "5": {},
    // });


    // console.log(templateQuery,"templateQuery")
    async function generateEmailPreview() {
        setIsPreviewGenerating(true);
        try {
            const tempBody = tempBodyRef.current;
            const canvas = await htmltoImage.toPng(tempBody);
            // const dataUrl = canvas.toDataURL('image/png');
            formik.setFieldValue('tempImg', canvas)
            setIsPreviewGenerating(false);
        } catch (error) {
            console.log('Error in generating preview', error)
        }
    }
    const [selectedGatewayUuid, setSelectedGatewayUuid] = useState("");

    const emailProvidersQuery = useQuery(['gatways'], {
        queryFn: async () => {
            let emailProviders = await fetchEmailChannels(orgToken);
            console.log(emailProviders)
            return emailProviders?.data?.data;
        },
        enabled: true
    });
    const gatewayQuery = useQuery(['gatways'], {
        queryFn: async () => {
            if (selectedChannel == 'sms') {
                let gatewayData = await getSmsGatways();
                console.log(gatewayData)
                return gatewayData.data;
            }

        },
        enabled: true
    });
    const selectTempData = (e, i) => {
        
        if (Object.keys(targetRatios).length > 1) {
            
            formik.setFieldValue(`selectedTempName`, e.label);
            formik.setFieldValue(`scheduleData.targetRatios[${i}].templateData.uuid`, e.uuid);
            formik.setFieldValue(`scheduleData.targetRatios[${i}].templateData.name`, e.label);
            // formik.setFieldValue(`selectedTempName${i + 1}`, e.label);
            if (selectedChannel === 'email') {
                formik.setFieldValue(`tempBody`, e.body)
                // generateEmailPreview(e.body)
            }
        }
        // else {
        //     formik.setFieldValue("selectedTemp", e.uuid);
        //     formik.setFieldValue("message", e.message);
        //     formik.setFieldValue("attachment", e.attachment);
        //     formik.setFieldValue("buttons", e.buttons);
        //     formik.setFieldValue("selectedTempName", e.label);
        //     if (selectedChannel === 'email') {
        //         formik.setFieldValue('tempBody', e.body)
        //         // generateEmailPreview(e.body)
        //     }
        // }
        if (selectedChannel === 'whatsapp') {
            selectTemplate(e, i)
        }
        if(selectedChannel == 'inApp' || 'mobile'){
     
            formik.setFieldValue("selectedTemp", e.uuid);
            formik.setFieldValue("selectedTempName", e.label);
        }
        // formik.setFieldValue("tempBody", e.body)
    }
    const selectTemplate = (e, i) => {
        if (selectedChannel == 'whatsapp') {
            setTemplate(e?.dt)
        } else {
            setTemplate(templateQuery.data.filter(template => template.uuid == e.uuid))
        }
    }
    const handleRemoveTargetRatio = (indexToRemove) => {
        setTargetRatios((prevRatios) => {
            const newRatios = { ...prevRatios };


            const removedValue = newRatios[indexToRemove];


            delete newRatios[indexToRemove];


            const remainingIndices = Object.keys(newRatios).map(Number);


            const totalRemaining = remainingIndices.reduce((acc, idx) => acc + newRatios[idx], 0);

            remainingIndices.forEach((idx) => {
                newRatios[idx] += totalRemaining > 0
                    ? Math.floor((newRatios[idx] / totalRemaining) * removedValue)
                    : Math.floor(removedValue / remainingIndices.length);
            });


            const adjustedSum = Object.values(newRatios).reduce((acc, val) => acc + val, 0);
            const adjustment = 100 - adjustedSum;
            if (adjustment !== 0 && remainingIndices.length > 0) {
                newRatios[remainingIndices[remainingIndices.length - 1]] += adjustment;
            }


            const updatedTargetRatios = remainingIndices.map((idx) => newRatios[idx]);
            const updatedTargetedAudience = updatedTargetRatios.map((ratio, i) => ({
                [`targetRatio${i + 1}`]: ratio
            }));


            formik.setFieldValue("targetedAudience.includes[0]", updatedTargetedAudience);
            formik.setFieldValue("scheduleData.targetRatios", updatedTargetRatios.map((ratio) => ({ ratio })));



            return newRatios;
        });
    };

    useEffect(() => {
        templateQuery.refetch();
        gatewayQuery.refetch()
    }, [])
    useEffect(() => {
        templateQuery.refetch();
        gatewayQuery.refetch()
    }, [formik.values.selectedChannel])
    useEffect(() => {
        if (location?.state?.data?.campaignData) {
            let obj = {}
            
            !!location?.state?.data?.campaignData?.scheduleData?.targetRatios?.length &&location?.state?.data?.campaignData?.scheduleData?.targetRatios.map((temp, i) => {
                if (location?.state?.data?.campaignData?.scheduleData?.targetRatios.length < 2) {
                    let selectedTemp = _.find(templateQuery.data, { uuid: location.state.data.campaignData.selectedTemp })
                    !!selectedTemp && selectTempData(selectedTemp, i)
                } else {
                    // formik.setFieldValue(`scheduleData.targetRatios`, location?.state?.data?.campaignData?.scheduleData?.targetRatios[i].templateData?.name)
                    formik.setFieldValue("selectedTempName", location?.state?.data?.campaignData?.scheduleData?.targetRatios[i].templateData?.name)
                    formik.setFieldValue(`scheduleData.targetRatios[${i}].templateData.uuid`, location?.state?.data?.campaignData?.scheduleData?.targetRatios[i]?.templateData?.uuid);
                    formik.setFieldValue(`scheduleData.targetRatios[${i}].ratio`, location?.state?.data?.campaignData?.scheduleData?.targetRatios[i]?.ratio);
                    formik.setFieldValue("campaignType", "multiple_schedule_v2");
                    formik.setFieldValue("broadcastType", "multiple_schedule_v2");
                    formik.setFieldValue("broadcast", "multiple_schedule_v2");
                }
                obj[i] = location?.state?.data?.campaignData?.scheduleData?.targetRatios[i]?.ratio;
            })
            
            if( !_.isEmpty(obj) && !_.isEmpty(obj['0']) ){
                setTargetRatios(obj)
                
            }else{
                setTargetRatios({"0":100})
            }
            if (gatewayQuery?.data) {
                formik.setFieldValue("selectedGateway", location?.state?.data?.campaignData.selectedGateway)
                setSelectedGatewayUuid(location?.state?.data?.campaignData.selectedGateway)
            }
        }
    }, [templateQuery.data])
    useEffect(() => {
        console.log("templates", formik.values.scheduleData)
    }, [formik.values.scheduleData])
    return (
        <>
            {
                (
                    <>

                        <Box w={'100%'} m={'0 auto'} mt={5} p={3} id='screenshot' bg={'white'} height={"550px"} overflowY={'scroll'} >
                            <Text color={'heading'} as={'b'}>Template Information  </Text>
                            {
                                selectedChannel !=='inApp' && selectedChannel !== 'mobile' &&

                                <Text mb={'15px'} float={'right'} cursor={'pointer'} textDecoration={'underline'} onClick={() => { setIsTestModalOpen(true) }} >Send Test Communication
                                <Icon as={BsSend} cursor={'pointer'} title={`Send Test ${formik.values.selectedChannel}`} ml={'10px'} />
                            </Text>
                            }

                            {
                                <FormControl>
                                    {

                                        selectedChannel == 'sms' &&
                                        <>
                                            <FormLabel fontWeight={'600'} color={'inputLabel'}>Select Provider</FormLabel>
                                            {!!gatewayQuery?.data && gatewayQuery?.data && <Select onChange={(e) => {
                                                formik.setFieldValue("selectedGateway", e.value)
                                                setSelectedGatewayUuid(e.value)
                                                templateQuery.refetch()
                                            }}
                                                value={_.filter(prepareGatewayList(gatewayQuery?.data?.gatewayList), { value: formik.values.selectedGateway })}
                                                options={prepareGatewayList(gatewayQuery?.data?.gatewayList)} />}
                                        </>
                                    }

                                </FormControl>
                            }
                            {/* {
                                (selectedChannel == 'inApp' || selectedChannel == 'mobile' ) && <>
                                  <FormControl>
                                                <FormLabel color={'inputLabel'}>Template  </FormLabel>
                                                <Select options={(templateQuery?.data) }
                                                    
                                                ></Select>
                                                

                                            </FormControl>
                                </>
                            } */}
                            <VStack gap={12} mt={4}>
                                <SendTestModal isOpen={isTestModalOpen} onClose={() => setIsTestModalOpen(false)} orgToken={orgToken} uuid={formik.values.selectedTemp} selectedSmtpUuid={formik?.values?.selectedSmtpUuid} formik={formik} channel={formik?.values?.selectedChannel} />
                                {
                                    selectedChannel == 'sms' && !!selectedGatewayUuid && Object.keys(targetRatios).map((template, index) => (

                                        <Box p={3} bg={'white'} w={'60%'} >
                                            <FormControl>
                                                <FormLabel color={'inputLabel'}>Template </FormLabel>
                                                <Select
                                                    value={targetRatios["0"] == 100 ? _.filter(templateQuery?.data, { uuid: formik.values[`selectedTemp`] }) : _.filter(templateQuery?.data, { uuid: formik.values?.scheduleData?.targetRatios[`${index}`]?.templateData?.uuid })}
                                                    options={templateQuery?.data} onChange={(e) => selectTempData(e, index)}
                                                ></Select>
                                                {
                                                    !!formik.values.selectedTemp && <Box float={'right'} title='Preview' onClick={onOpen} cursor={'pointer'}>
                                                    </Box>
                                                }
{
                                                    !!formik.values.selectedTemp && <Box float={'right'} title='Preview' onClick={()=>{
                                                        setTemplate(_.find(templateQuery?.data, { uuid: formik.values.scheduleData.targetRatios[index]?.templateData?.uuid || formik.values[`selectedTemp`] }))
                                                        onOpen()
                                                        }} cursor={'pointer'}>
                                                        <Icon as={FaEye} />
                                                    </Box>
                                                }
                                            </FormControl>

                                            {
                                                (formik.values.campaignType.toLowerCase() == "broadcast" || formik.values.campaignType == "multiple_schedule_v2" || formik.values.campaignType == "BROADCAST_V2") &&
                                                <>
                                                    <FormLabel mt={2} fontWeight={'600'} display={'flex'} color={'inputLabel'}>Target Ratio 
                                                        <Box width={"300px"} >

                                                            <Select value={_.filter(getTemplateRatioIncrementer(), { value: targetRatios[index] })} options={getTemplateRatioIncrementer()} onChange={(e) => { handleTargetRatioChange(index, e.value) }}
                                                            ></Select>
                                                        </Box>
                                                    </FormLabel>
                                                    {/* <Slider
                                                        value={targetRatios[index]}
                                                        min={0}
                                                        max={100}
                                                        onChange={(value) => handleTargetRatioChange(index, value)}
                                                    >
                                                        <SliderTrack>
                                                            <SliderFilledTrack />
                                                        </SliderTrack>
                                                        <SliderThumb bg='black' />
                                                        <SliderMark value={targetRatios[index]} mt='2' ml='-2.5' color='black'>
                                                            {targetRatios[index]}%
                                                        </SliderMark>
                                                    </Slider> */}
                                                </>
                                            }

                                        </Box>
                                    ))

                                }
                                {!isValidRatios && (
                                    <Text color="red.500" mt={2}>
                                        The sum of all target ratios must be 100%.
                                    </Text>
                                )}


                                {
                                    selectedChannel !== 'sms' && !!Object.keys(targetRatios).length && Object.keys(targetRatios).map((template, index) => (

                                        <Box p={3} bg={'white'} w={'60%'} >
                                            <FormControl>
                                                
                                                <FormLabel color={'inputLabel'}>Template  </FormLabel>
                                                {
                                                    !!targetRatios["0"] && targetRatios["0"] == 100 &&
                                                    <Select
                                                    value={_.filter(templateQuery?.data, { uuid: formik.values[`selectedTemp`] })}
                                                    options={templateQuery?.data} onChange={(e) => selectTempData(e, index)}
                                                ></Select>
                                                }
                                                {
                                                    !!targetRatios["0"] && targetRatios["0"] !== 100 &&
                                                    <Select
                                                    value={ _.filter(templateQuery?.data, { uuid: formik.values.scheduleData.targetRatios[index]?.templateData?.uuid || formik.values[`selectedTemp`] })}
                                                    options={templateQuery?.data} onChange={(e) => selectTempData(e, index)}
                                                ></Select>
                                                }
                                                
                                                {
                                                    !!formik.values.selectedTemp && <Box float={'right'} title='Preview' onClick={()=>{
                                                        setTemplate(_.find(templateQuery?.data, { uuid: formik.values.scheduleData.targetRatios[index]?.templateData?.uuid || formik.values[`selectedTemp`] }))
                                                        onOpen()
                                                        }} cursor={'pointer'}>
                                                        <Icon as={FaEye} />
                                                    </Box>
                                                }

                                            </FormControl>
                                            {
                                                    selectedChannel !=='inApp' && selectedChannel !== 'mobile' &&
                                                (formik.values.campaignType == "Broadcast" || formik.values.campaignType == "multiple_schedule_v2" || formik.values.campaignType == "BROADCAST_V2") &&
                                                <>
                                                    <FormLabel mt={2} fontWeight={'600'} color={'inputLabel'}>Target Ratio 
                                                        {/* <NumberInput width={"100px"} type="number" value={targetRatios[index]} onChange={(value) => { handleTargetRatioChange(index, value) }} >
                                                            <NumberInputField />
                                                            <NumberInputStepper>
                                                                <NumberIncrementStepper />
                                                                <NumberDecrementStepper />
                                                            </NumberInputStepper>

                                                        </NumberInput> */}
                                                            
                                                        <Box width={"300px"} >

                                                            <Select value={_.filter(getTemplateRatioIncrementer(), { value: targetRatios[index]})} options={getTemplateRatioIncrementer()} onChange={(e) => { handleTargetRatioChange(index, e.value) }}
                                                            ></Select>
                                                        </Box>
                                                    </FormLabel>
                                                    {/* <Slider
                                                        value={targetRatios[index]}
                                                        min={0}
                                                        minStepsBetweenThumbs = {'5'}
                                                        step={5}
                                                        max={100}
                                                        onChange={(value) => handleTargetRatioChange(index, value)}
                                                    >
                                                        <SliderTrack>
                                                            <SliderFilledTrack />
                                                        </SliderTrack>
                                                        <SliderThumb bg='black' />
                                                        <SliderMark value={targetRatios[index]} mt='2' ml='-2.5' color='black'>
                                                            {targetRatios[index]}%
                                                        </SliderMark>
                                                    </Slider> */}
                                                </>
                                            }
                                            {
                                                index != 0 &&
                                                <Icon as={BiTrash} mt={'15px'} fontSize={'20px'} float={'right'} cursor={'pointer'} onClick={() => handleRemoveTargetRatio(index)} />
                                            }
                                        </Box>
                                    ))

                                }


                                {/* <Box ratio={3 / 4} w={'40vw'} maxW={'530px'} bg="white" overflow={'scroll'} maxH={'450px'} >
                    <Box transform={'scale(0.8)'} dangerouslySetInnerHTML={{ __html: formik.values.tempBody }}></Box>
                </Box> */}
                                {/* {
                    selectedChannel == 'whatsapp' ? <MobilePreview channel={'whatsapp'} message={formik.values.message} /> :
                        <TemplatePreviewModal isOpen={isOpen} onClose={onClose} tempBody={formik.values.tempBody}></TemplatePreviewModal>
                } */}
                                <Box>
                                    
                                    {
                                        selectedChannel == 'whatsapp' ? !_.isEmpty(template) && <ABTestMobilePreview isOpen={isOpen} onClose={onClose} channel={'whatsapp'} message={template?.dt?.message} attachment={template?.dt?.attachment} buttons={template?.dt?.buttons} /> :
                                        <TemplatePreviewModal isOpen={isOpen} onClose={onClose} tempBody={formik.values.tempBody}></TemplatePreviewModal>
                                    }
                                    {
                                        selectedChannel == 'sms'  &&  <ABTestMobilePreview isOpen = {isOpen} onClose={onClose} channel={'sms'} message={template?.dt?.message}  /> 
                                    }
                                    
                                </Box>
                                {
                                        selectedChannel !=='inApp' && selectedChannel !== 'mobile' &&
                                <PrimaryButton
                                mt={4}
                                justifySelft={'start'}
                                label={'Add Ratio'}
                                onClick={() => {
                                    if (Object.keys(targetRatios).length >= 6) {
                                        console.error("Cannot add more than 6 templates");
                                        return;
                                    }
                                    if (targetRatios[0] < 100) {
                                        formik.setFieldValue("campaignType", "multiple_schedule_v2");
                                        formik.setFieldValue("broadcastType", "multiple_schedule_v2");
                                        formik.setFieldValue("broadcast", "multiple_schedule_v2");
                                    } else {
                                        formik.setFieldValue("campaignType", "BROADCAST_V2");
                                        formik.setFieldValue("broadcastType", "BROADCAST_V2");
                                        formik.setFieldValue("broadcast", "BROADCAST_V2");
                                    }
                                    
                                    const lastIndex = Object.keys(targetRatios).length - 1;
                                    
                                    if (!!lastIndex && !formik.values?.scheduleData?.targetRatios[lastIndex]) {
                                        formik.setFieldValue(`scheduleData.targetRatios[${lastIndex}].templateData`, {});
                                    }
                                    
                                    const currentRatios = formik.values.scheduleData.targetRatios || [];
                                    
                                    
                                    const newEntry = {
                                        templateData: {
                                            uuid: currentRatios[0]?.templateData?.uuid || "",
                                            name: currentRatios[0]?.templateData?.name || "",
                                        }
                                    };
                                    
                                    
                                    const updatedRatios = [...currentRatios, newEntry];
                                    
                                    
                                    formik.setFieldValue("scheduleData.targetRatios", updatedRatios);
                                    
                                    
                                    console.log("Updated Ratios:", updatedRatios);
                                    
                                    !!lastIndex &&
                                    formik.setFieldValue(
                                        `scheduleData.targetRatios[${lastIndex}].templateData.uuid`,
                                        formik.values.scheduleData.targetRatios[0]?.templateData?.uuid
                                    );
                                    !!lastIndex &&
                                    formik.setFieldValue(
                                        `scheduleData.targetRatios[${lastIndex}].templateData.name`,
                                        formik.values.scheduleData.targetRatios[0]?.templateData?.name
                                    );
                                    
                                    setTargetRatios((prevRatios) => {
                                        const newKey = Object.keys(prevRatios).length;
                                        formik.setFieldValue("campaignType", "multiple_schedule_v2");
                                        formik.setFieldValue("broadcastType", "multiple_schedule_v2");
                                        formik.setFieldValue("broadcast", "multiple_schedule_v2");
                                        return {
                                            ...prevRatios,
                                            [newKey]: 0,
                                        };
                                    });
                                    setTemplates((prevTemplates) => [...prevTemplates, ""]);
                                    
                                }}
                                >

                                </PrimaryButton>
                                }
                            </VStack>
                        </Box>
                    </>
                )
            }

        </>
    )
}

export default TemplateSelection
