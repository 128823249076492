import React, { useEffect, useState } from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text, Heading, Badge, Spinner, Alert, AlertIcon } from "@chakra-ui/react";
import moment from "moment";
import { getCurrency, getTransactionDetails, getWalletDetails } from "../../Services/api";
import { usePagination } from '@ajna/pagination';
const Wallet = () => {
  const [wallet, setWallet] = useState({});
  const [currency, setCurrency] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ source, setSource] = useState("")
  const [selectedCreditChannel, setSelectedCreditChannel] =  useState("")
  const [totalCount, setTotalCount] = useState(null)
  const { currentPage, setCurrentPage, pagesCount, pages, pageSize } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 10, isDisabled: false }, limits: { outer: 1, inner: 1 } })
  
  const getCurrencyDetails = async () => {
    try {
      const response = await getCurrency();
      setCurrency(response.data.data.key);
      await getWalletInfo(response.data.data.key);
    } catch (err) {
      console.error("Error fetching currency:", err);
      setError("Failed to load currency details.");
    }
  };

  const getWalletInfo = async () => {
    try {
      if (!currency) return;
      const response = await getWalletDetails(currency);
      setWallet(response?.data);
      await getTransactions(source,response?.data?.uuid,selectedCreditChannel,currentPage,pageSize);
    } catch (err) {
      console.error("Error fetching wallet details:", err);
      setError("Failed to load wallet details.");
    }
  };

  const getTransactions = async (source, walletUuid ,selectedCreditChannel ,currentPage ,pageSize) => {
    try {
      const response = await getTransactionDetails(source, walletUuid, selectedCreditChannel , currentPage, pageSize);
      setTotalCount(response?.data?.count)
      setTransactions(response?.data?.items || []);
    } catch (err) {
      console.error("Error fetching transactions:", err);
      setError("Failed to load transactions.");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    setError(null);
    await getCurrencyDetails();
    setLoading(false);
  };
  useEffect(() => {
    
    fetchData();
  }, [currency]);

  return (
    <Box p={5} mx="auto">
    
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" my={5}>
          <Spinner size="xl" />
        </Box>
      )}

      
      {!loading && !error && (
        <Box bg="gray.100" p={5} borderRadius="md" mb={5}>
          <Heading size="md">Wallet Balance</Heading>
          <Text fontSize="lg" fontWeight="bold">
            {wallet?.currency} {wallet?.balance?.toFixed(2)}
          </Text>
        </Box>
      )}

      {!loading && !error && transactions.length > 0 && (
        <Box border="1px" borderColor="gray.200" borderRadius="md" overflowX="auto">
          <Table variant="simple">
            <Thead bg="gray.200">
              <Tr>
                <Th>Description</Th>
                <Th>Channel</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transactions.map((txn) => (
                <Tr key={txn.id}>
                  <Td>
                    {txn.description}
                    <Text fontSize="sm" color="gray.500">
                      {moment.utc(txn.createdAt).local().format("LL LT")}
                    </Text>
                  </Td>
                  <Td>
                    <Badge
                      colorScheme={
                        txn.communicationChannel === "SMS"
                          ? "blue"
                          : txn.communicationChannel === "EMAIL"
                          ? "green"
                          : "purple"
                      }
                    >
                      {txn.communicationChannel}
                    </Badge>
                  </Td>
                  <Td>
                    <Badge colorScheme={txn.status === "PAID" ? "green" : "red"}>{txn.status}</Badge>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}

      {!loading && !error && transactions.length === 0 && (
        <Text textAlign="center" color="gray.500" mt={5}>
          No transactions available.
        </Text>
      )}
    </Box>
  );
};

export default Wallet;
