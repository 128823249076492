import { Alert, AlertIcon, Box, FormControl, FormLabel, HStack, Icon, Select, Text } from '@chakra-ui/react';
import React, { Fragment, useEffect, useState } from 'react';
import { getTemplatesQuery } from '../helpers/FlowQueries';
import { useQuery } from 'react-query';
import _, { isError } from 'lodash';
import FlowEditService from '../helpers/FlowEditService';
import { prepareFlowActionTemplatesList, prepareWhatsappProviderData } from '../../../_helpers/data_helper';
import { Link } from 'react-router-dom';
import { BiRefresh } from 'react-icons/bi';
import ExportCustomAudience from '../../Audience/ExportCustomAudience';
import { getChanneldata } from '../../../Services/api';

export default function InspectorForAction({ formData, selectedElement, updateFormData, validateFormData, selectedAdAccount, setSelectedAdAccount, customAudienceList, setCustomAudienceList, hasAdAccount, setHasAdAccount, hasCustomAudience, setHasCustomAudience, isFbConnected, setIsFbConnected, adAccountsList, setAdAccountsList, customAudience, setCustomAudience }) {
    const orgToken = JSON.parse(localStorage.getItem('selectedOrg')).token;

    const fetchTemplates = async (path, providerUuid) => {
        if (selectedElement.actionType === 'whatsapp') {
            path += '?providerUuid=' + providerUuid;
        }
        let res = await FlowEditService.getTemplatesForAction(path);
        return prepareFlowActionTemplatesList(res.data);
    };

    const { data, isLoading, error } = useQuery(selectedElement.actionType, () => {
        if (selectedElement.actionType === 'meta') {
            return;
        } else if (selectedElement.actionType === 'whatsapp' && !!formData[selectedElement.id].action?.providerUuid) {
            return fetchTemplates(selectedElement.templatesFetchUrl, formData[selectedElement.id].action?.providerUuid);
        } else {
            return fetchTemplates(selectedElement.templatesFetchUrl);
        }
    }, {
        enabled: selectedElement.actionType !== 'whatsapp' || !!formData[selectedElement.id].action?.providerUuid,
    });
    const createTemplateLinks = {
        email: '/email/edit',
        whatsapp: '/whatsapp/create',
        sms: '/sms/create',
        push: '/push/list',
        onsite: '/nudge/layout',
        appPush: '/mobilepush/create',
        webhook: '/settings/webhook',

    }
    const selectTemplate = (e) => {
        let tempFormData = { ...formData };
        try {
            tempFormData[selectedElement.id].action.templateUUID = e?.target?.value;
            if (selectedElement?.actionType === 'meta') {
                tempFormData[selectedElement.id].action.templateUUID = e;
            }
        } catch (e) { }
        updateFormData(tempFormData);
        validateFormData("beginsWith");
    };

    const selectSetting = (e) => {
        let tempFormData = { ...formData };
        try {
            tempFormData[selectedElement.id].action['providerUuid'] = e?.target?.value;
            fetchTemplates(selectedElement.templatesFetchUrl, e?.target?.value);
        } catch (e) { }
        updateFormData(tempFormData);
        validateFormData("beginsWith");
    };

    const whatsappProviderQuery = useQuery(['providers'], {
        queryFn: async () => {
            let whatsappProviders = await getChanneldata(orgToken);
            return whatsappProviders?.data?.data?.response;
        },
        enabled: true
    });

    useEffect(() => {
        if (selectedElement?.actionType === "whatsapp" && whatsappProviderQuery.data) {
            const providers = prepareWhatsappProviderData(whatsappProviderQuery.data);
            if (providers.length > 0) {
                const firstProvider = providers[0].value;
                let tempFormData = { ...formData };
                tempFormData[selectedElement.id].action['providerUuid'] = firstProvider;
                updateFormData(tempFormData);
                fetchTemplates(selectedElement.templatesFetchUrl, firstProvider);
            }
        }
    }, [whatsappProviderQuery.data]);
    useEffect(()=>{
        if (selectedElement?.actionType === "meta") {
            const actionData = formData[selectedElement.id]?.action?.templateUUID;

            if (actionData) {
                let data = JSON.parse(atob(actionData));
                console.log(data);

                if (data?.newCustomAudienceData?.selectedAdAccount) {
                    setSelectedAdAccount(data.newCustomAudienceData.selectedAdAccount);
                    setHasAdAccount(true);

                    setCustomAudience(prevState => ({
                        ...prevState,
                        selected: { id: data.newCustomAudienceData.customAudienceId },
                        create: data.newCustomAudienceData.isNew,
                        newCustomAudienceData: {
                            ...prevState.newCustomAudienceData,
                            name: data.newCustomAudienceData.name,
                            description: data.newCustomAudienceData.description
                        }
                    }));
                }
                console.log(customAudience, "adAccc");
            }
        }

    },[selectedAdAccount])
    if (isLoading && selectedElement.actionType !== 'meta' && selectedElement.actionType !== 'whatsapp') return <Text>Loading....</Text>;
    if (error && selectedElement.actionType !== 'meta' && selectedElement.actionType !== 'whatsapp') return <Text>Error....</Text>;

    return (
        <Fragment>
            {!!data && !data?.hasAccess && (
                <Alert status='warning'>
                    <AlertIcon />
                    You do not have access to this feature. To get this feature please contact us at info@orbisdata.ai
                </Alert>
            )}
            {selectedElement?.actionType === 'whatsapp' && (
                <FormControl mt={3}>
                    <FormLabel color={'inputLabel'}>Whatsapp Setting </FormLabel>
                    <Select onChange={selectSetting} value={formData[selectedElement?.id]?.action?.providerUuid}>
                        {prepareWhatsappProviderData(whatsappProviderQuery.data).map(setting => (
                            <option key={setting.value} value={setting.value}>{setting.label}</option>
                        ))}
                    </Select>
                </FormControl>
            )}
            {(selectedElement?.actionType !== 'meta' && !!data?.templates && !!data.hasAccess && !!data.templates.length) && (
                <Fragment>
                    <FormLabel color={'inputLabel'} mt={2}>Select Template</FormLabel>
                    <Select mt={2} placeholder='Select' value={formData[selectedElement?.id]?.action?.templateUUID} onChange={selectTemplate}>
                        {data.templates.map(template => (
                            <option key={template.uuid} value={template.uuid}>{template.name}</option>
                        ))}
                    </Select>
                </Fragment>
            )}
            {selectedElement?.actionType !== 'meta' && !!data?.templates && !!data.hasAccess && !data.templates?.length && (
                <Alert status='warning'>
                    <AlertIcon />
                    You do not have any saved templates.
                </Alert>
            )}
            {
                selectedElement?.actionType !== 'meta' &&
                <HStack w={'100%'} justifyContent={'space-between'} >
                    <Link target='_blank' to={createTemplateLinks[selectedElement.actionType]}>
                        <Text textDecoration={'underline'} cursor={'pointer'} >
                            Create Template +
                        </Text>
                    </Link>
                    {
                        !!formData[selectedElement?.id]?.action?.templateUUID &&
                        <Text
                            as="span"
                            textDecoration="underline"
                            cursor="pointer"
                            onClick={() => {
                                const url = `${createTemplateLinks[selectedElement.actionType]}/${formData[selectedElement?.id]?.action?.templateUUID}`;
                                const state = { provider: formData[selectedElement?.id]?.action?.providerUuid };

                                const newTab = window.open(url, "_blank");
                                if (newTab) {
                                    newTab.onload = () => {
                                        newTab.history.pushState(state, "", url);
                                    };
                                }
                            }}
                        >
                            Edit
                        </Text>

                    }
                    <Text onClick={() => fetchTemplates(selectedElement.templatesFetchUrl)} textDecoration={'underline'} cursor={'pointer'} >
                        Refresh
                        <Icon as={BiRefresh} />
                    </Text>
                </HStack>
            }
             {
                selectedElement?.actionType == 'meta' && <>
                    <Box>
                        <ExportCustomAudience
                            selectedAdAccount={selectedAdAccount} setSelectedAdAccount={setSelectedAdAccount} customAudienceList={customAudienceList} setCustomAudienceList={setCustomAudienceList} hasAdAccount={hasAdAccount} setHasAdAccount={setHasAdAccount} hasCustomAudience={hasCustomAudience} setHasCustomAudience={setHasCustomAudience} isFbConnected={isFbConnected} setIsFbConnected={setIsFbConnected} adAccountsList={adAccountsList} setAdAccountsList={setAdAccountsList}
                            customAudience={customAudience} setCustomAudience={setCustomAudience}
                            selectTemplate={selectTemplate} segmentId={formData['beginsWith']?.nextActionCondition?.criteria[0]?.selectedSegmentId} />
                        
                        

                    </Box>
                </>
            }
        </Fragment>
    );
}
