import React, { useEffect, useState } from "react";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getPowerBiSettings, powerBiSettings } from "../../Services/api";
import {
  Flex,
  Text,
  Box,
  FormControl,
  Input,
  FormErrorMessage,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";

const PowerBiIntegration = () => {
  const toast = useToast();
  const [initialUrls, setInitialUrls] = useState([{ label: "", url: "" }]); // Initial state for dynamic fields
  const token = window.location.pathname.split("/")[3];

  // Validation schema for the form
  const validationSchema = Yup.object().shape({
    urls: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required("Label is required"),
          url: Yup.string().url("Invalid URL format").required("URL is required"),
        })
      )
      .min(1, "At least one URL is required"),
  });

  // Function to fetch Power BI settings and parse stringified JSON data
  const fetchPowerBiDetails = async () => {
    try {
      const response = await getPowerBiSettings(token);

      // Parse stringified JSON or use fallback
      const fetchedData = response.data?.data
        ? JSON.parse(response.data.data) // Parse stringified JSON
        : [{ label: "", url: "" }]; // Fallback to empty object array

      setInitialUrls(fetchedData); // Set the parsed data as initial values
    } catch (error) {
      console.error("Error while fetching Power BI details:", error);
      setInitialUrls([{ label: "", url: "" }]); // Fallback for errors
    }
  };

  // Function to handle form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const payload = {
        powerbi_url: JSON.stringify(values.urls), // Stringify the array of objects
      };
      await powerBiSettings(payload, token);
      window.location.reload()
      toast({
        title: "Success!",
        description: "Power BI settings updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to update settings:", error);
      toast({
        title: "Error!",
        description: "Failed to update Power BI settings.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Fetch Power BI details on component mount
  useEffect(() => {
    fetchPowerBiDetails();
  }, []);

  return (
    <>
      <Flex align="center" justify="space-between" mb={4}>
        <Text fontSize="lg" fontWeight="bold">
          Power BI Integration
        </Text>
      </Flex>
      <Formik
        initialValues={{ urls: initialUrls }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize // Allows form to update when initial values change
      >
        {({ values, isSubmitting, touched, errors }) => (
          <Form>
            <FieldArray
              name="urls"
              render={(arrayHelpers) => (
                <Box>
                  {values.urls.map((urlObj, index) => (
                    <Flex key={index} alignItems="center" mb={4}>
                      {/* Label Field */}
                      <FormControl
                        isInvalid={
                          touched.urls &&
                          touched.urls[index]?.label &&
                          !!errors.urls &&
                          !!errors.urls[index]?.label
                        }
                        mr={2}
                      >
                        <Field name={`urls.${index}.label`}>
                          {({ field }) => (
                            <Input placeholder="Enter Label" {...field} />
                          )}
                        </Field>
                        <ErrorMessage
                          name={`urls.${index}.label`}
                          component={FormErrorMessage}
                        />
                      </FormControl>

                      {/* URL Field */}
                      <FormControl
                        isInvalid={
                          touched.urls &&
                          touched.urls[index]?.url &&
                          !!errors.urls &&
                          !!errors.urls[index]?.url
                        }
                        mr={2}
                      >
                        <Field name={`urls.${index}.url`}>
                          {({ field }) => (
                            <Input placeholder="Enter URL" {...field} />
                          )}
                        </Field>
                        <ErrorMessage
                          name={`urls.${index}.url`}
                          component={FormErrorMessage}
                        />
                      </FormControl>

                      {/* Delete Button */}
                      <IconButton
                        icon={<DeleteIcon />}
                        colorScheme="red"
                        onClick={() => arrayHelpers.remove(index)}
                        aria-label="Remove URL"
                        mr={2}
                      />
                    </Flex>
                  ))}

                  {/* Add Button */}
                  <PrimaryButton
                    type="button"
                    onClick={() => arrayHelpers.push({ label: "", url: "" })}
                    label="Add URL"
                  />
                </Box>
              )}
            />
            <Box mt={4}>
              <PrimaryButton
                type="submit"
                isLoading={isSubmitting}
                label="Submit"
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PowerBiIntegration;
