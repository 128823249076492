import { jwtDecode } from 'jwt-decode'
import moment from 'moment';
import ReactDOMServer from "react-dom/server"
import { Box, Text } from '@chakra-ui/react';
export const extractErrorMessageFromResponse = (respone) => {
    let message = respone?.response?.data?.details || "Something went wrong. Please try again.";
    return message;
}

export const extractUserDataFromJWT = (data) => {

    let token = data?.data?.data?.jwt;
    let decodedToken = jwtDecode(token);
    let retVal = {
        user_data: JSON.parse(decodedToken.user_data),
        exp: moment(decodedToken.exp * 1000),
        jwt: token
    }
    return retVal;
}

export const setTokenInLocalStorage = (token) => {
    
    localStorage.setItem("token", JSON.stringify(token));
}

export const getTokenFromLoacalStorage = () => {
    
    let token = localStorage.getItem("token");
    // let decodedToken = extractUserDataFromJWT(JSON.parse(token) )
    let selectedOrg = localStorage.getItem("selectedOrg");
    if (!!token && !!selectedOrg) {
        token = JSON.parse(token);
        selectedOrg = JSON.parse(selectedOrg);
    }

    // console.log('getTokenFromLoacalStorage',{ user_data: token?.user_data, exp: moment(token?.exp), jwt: token?.jwt, token: selectedOrg?.token })
    return { user_data: token?.user_data , exp: moment(token?.exp), jwt: token?.jwt, token: selectedOrg?.token };
}

export function isTokenExpired(tokenExpiryTime) {
    
    const currentTime = moment();
    const tokenTime = moment(tokenExpiryTime)
    //const tokenExpiry = authData.tokenExpiry;
    return !!(tokenExpiryTime.diff(currentTime, "m") > 1)

}

export function tokenTimeDifference(tokenExpiryTime) {
    const currentTime = moment();
    const tokenTime = moment(tokenExpiryTime)
    //const tokenExpiry = authData.tokenExpiry;
    return currentTime.diff(tokenExpiryTime, "m")
}

export const isProduction = () => {
    return !window.location.host.includes("localhost");
}
export const CHANNELS = {
    email: 'Email', push: 'Push', sms: 'SMS', whatsapp: 'WhatsApp', mobile : 'Mobile Push', inApp : "In App"
}

export const getTargetAudienceTypes = {

    SEND_TO_ALL: 'sendToAll', EXISTING_CSV: 'existingCsv', UPLOAD_CSV: 'uploadCsv', PREDEFINED_SEGMENTS: 'predefined', CUSTOM_SEGMENT: 'custom',
    RFM_SEGMENT: 'rfm', KLAVIYO: 'klaviyo', DAMAS_CRM: 'damasCrm'

};

export const showSwal = (swalInstance, title, desc, showLoaderOnConfirm, showCancelButton, confirmButtonText, cancelButtonText, preConfirmCallback, callback) => {
    swalInstance.fire({
        html: ReactDOMServer.renderToStaticMarkup(
            <Box display={'flex'} flexDirection={'column'} gap={6}>
                <Text mb={6} as={'b'} fontSize={'xl'}>{title}</Text>
                <Text fontSize={'md'}>{desc}</Text>
            </Box>
        ),
        showLoaderOnConfirm: !!showLoaderOnConfirm,
        showCancelButton: !!showCancelButton,
        confirmButtonText: confirmButtonText || 'Okay',
        cancelButtonText: cancelButtonText || 'No',
        preConfirm: preConfirmCallback,
        
    }).then(res => {
        callback(res)
    })
}

export const apeendRequiredScript = () => {
    
}