import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Box, ListItem, UnorderedList, Text, Select, Alert, AlertIcon, AlertTitle, Flex, AlertDescription } from '@chakra-ui/react'
import FlowEditService from './FlowEditService'
import InspectorForInitial from '../components/InspectorForInitial';
import InspectorForCondition from '../components/InspectorForCondition';
import InspectorForDelay from '../components/InspectorForDelay';
import InspectorForAction from '../components/InspectorForAction';
import { useQuery } from 'react-query';
import { fetchEventNameJson } from '../../../Services/api';
import { prepareEventNameJson } from './FlowQueries';
import ShowToastr from '../../../components/themeComponents/ShowToastr';
import { prepareFlowActionTemplatesList } from '../../../_helpers/data_helper';
import PrimaryButton from '../../../components/themeComponents/PrimaryButton';
export default function FlowInspector({ formData, formValidation, selectedElement, updateFormData, formAcionProps, validateFormData, saveButtonRef, updateFormActionProps,segmentAttributes, setSegmentAttributes }) {
    const eventQueries = {
        "EventName": useQuery({
            queryKey: "EventName",
            queryFn: async () => {
                let res = await fetchEventNameJson();
                return prepareEventNameJson(res.data);
            },
            onError: (error) => <ShowToastr title={error.message}></ShowToastr>,
            cacheTime: 0,
            retry: 1,
            enabled: false
        })
    }
    const getCustomAudienceCreateJson = () => {
        return {
            name: '',
            subtype: 'CUSTOM',
            description: '',
            customer_file_source: 'USER_PROVIDED_ONLY',
        };
    };

    
    const [isCustomAttribute, setIsCustomAttribute] = useState(false);
    const [segmentEvents, setSegmentEvents] = useState([])
    const[showDateTimePicker,setShowDateTimePicker]=useState(false)
    const [selectedAdAccount, setSelectedAdAccount] = useState(null);
    const [customAudienceList, setCustomAudienceList] = useState([]);
    const [hasAdAccount, setHasAdAccount] = useState(false);
    const [hasCustomAudience, setHasCustomAudience] = useState(false);
    const [isFbConnected, setIsFbConnected] = useState(false);
    const [adAccountsList, setAdAccountsList] = useState([]);
    const [customAudience, setCustomAudience] = useState({
            create: false,
            newCustomAudienceData: getCustomAudienceCreateJson(),
            selected: {},
        });

    const templateQuery = {
        'email': useQuery({
            queryKey: 'email',
            queryFn: async () => {
                let res = await FlowEditService.getTemplatesForAction(selectedElement.templatesFetchUrl);
                return prepareFlowActionTemplatesList(res.data)
            },
            onError: (error) => <ShowToastr title={error.message}></ShowToastr>,
            cacheTime: 0,
            retry: 1,
            enabled: false
        })
    }

    return (
        <Fragment>
            {
                (!formValidation.isFormValid && formValidation.reasons?.elementReasons && Object.keys(formValidation.reasons.elementReasons).length > 0) && !selectedElement && (
                    <Box>
                        <UnorderedList>
                            {
                                Object.keys(formValidation.reasons.elementReasons).map(elemId => {
                                    return <ListItem key={elemId}>{formValidation.reasons.elementReasons[elemId].reason}</ListItem>
                                })
                            }
                        </UnorderedList>
                    </Box>
                )
            }
{/*  */}
            {
                !!selectedElement &&
                <Box>

                    <Text as={'b'}>{selectedElement.labelText}</Text>
                    {
                        selectedElement.elementType === FlowEditService.elementTypes.initial && <InspectorForInitial segmentEvents = {segmentEvents} setSegmentEvents = {setSegmentEvents} formData={formData} updateFormData={updateFormData} formAcionProps={formAcionProps} eventQueries={eventQueries} validateFormData={validateFormData} updateFormActionProps={updateFormActionProps} setShowDateTimePicker={setShowDateTimePicker}></InspectorForInitial>
                    }
                    {
                        selectedElement.elementType === FlowEditService.elementTypes.condition && <InspectorForCondition segmentAttributes={segmentAttributes} setSegmentAttributes={setSegmentAttributes}  isCustomAttribute = {isCustomAttribute} setIsCustomAttribute={setIsCustomAttribute} formData={formData} selectedElement={selectedElement} updateFormData={updateFormData} validateFormData={validateFormData}></InspectorForCondition>
                    }
                    {
                        selectedElement.elementType === FlowEditService.elementTypes.delay && <InspectorForDelay formData={formData} selectedElement={selectedElement} updateFormData={updateFormData} validateFormData={validateFormData} setShowDateTimePicker={setShowDateTimePicker} showDateTimePicker={showDateTimePicker}></InspectorForDelay>
                    }
                    {
                        selectedElement.elementType === FlowEditService.elementTypes.action && <InspectorForAction 
                        selectedAdAccount={selectedAdAccount} setSelectedAdAccount={setSelectedAdAccount} customAudienceList={customAudienceList} setCustomAudienceList={setCustomAudienceList} hasAdAccount={hasAdAccount} setHasAdAccount={setHasAdAccount} hasCustomAudience={hasCustomAudience} setHasCustomAudience={setHasCustomAudience} isFbConnected={isFbConnected}  setIsFbConnected={setIsFbConnected}adAccountsList={adAccountsList} setAdAccountsList={ setAdAccountsList}
                        customAudience={customAudience} setCustomAudience={setCustomAudience}
                        formData={formData} 
                        selectedElement={selectedElement} updateFormData={updateFormData} validateFormData={validateFormData}></InspectorForAction>
                    }
                </Box>
            }
            {
                !formValidation.isFormValid && formValidation.reasons.genericReasons.length > 0 && <Alert status='error' display={'flex'} flexDirection={'column'} alignItems={'start'} position={'absolute'} bottom={'4rem'} w={'calc(100% - 20px)'} right={'10px'} left={'10px'}>
                    <Flex alignItems={'center'} mb={2}>
                        <AlertIcon></AlertIcon>
                        <AlertTitle>Invalid Flow</AlertTitle>

                    </Flex>

                    {
                        formValidation.reasons.genericReasons.map(reason => {
                            return <AlertDescription>{reason}</AlertDescription>
                        })
                    }
                    <Text></Text>
                </Alert>
            }
        
        </Fragment>
    )
}
