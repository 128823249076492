import React, { Fragment, useState, useEffect } from 'react';
import {
    Box,
    Button,
    Input,
    Radio,
    RadioGroup,
    Stack,
    Select,
    Spinner,
    Text,
    Flex,
    Image
} from '@chakra-ui/react';
import axiosInstance from '../../Services/axiosInstance';
import store from '../../store/store';
import moment from 'moment';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { Link } from 'react-router-dom';
import { FaSyncAlt } from 'react-icons/fa';
import { extractUserDataFromJWT } from '../../_helpers/helpers';
import { useSelector } from 'react-redux';

const ExportCustomAudience = ({ onClose, criteria, type, segmentId, dates, selectTemplate, selectedAdAccount, setSelectedAdAccount, customAudienceList, setCustomAudienceList, hasAdAccount, setHasAdAccount, hasCustomAudience, setHasCustomAudience, isFbConnected, setIsFbConnected, adAccountsList, setAdAccountsList, customAudience, setCustomAudience }) => {

    const [loading, setLoading] = useState(false);

    const orgId = store.getState().auth.selectedOrg.token;

    const userDetails = useSelector(state => state.auth.user_data);

    let getAdsAccountsList = () => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/zc/zads/account/list/adv`;
        return axiosInstance.get(url);
    };

    let checkFbAuth = () => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/fb/fbmessenger/credentials?restful=true&channel=facebook`;
        return axiosInstance.get(url);
    }

    const checkFbCredentials = () => {
        checkFbAuth().then((res) => {
            setIsFbConnected(res.data.loggedIn);
        });
    };

    useEffect(() => {
        checkFbCredentials();
        getAdsAccountsList().then(response => {
            setAdAccountsList(response?.data?.data?.data);

        });
    }, []);

    const selectAdAccount = (adAccount) => {
        setSelectedAdAccount(adAccount);
        setHasAdAccount(true);
        getCustomAudienceList(adAccount);
    };

    const refreshFbAuthenticated = () => {
        checkFbCredentials();
    };

    let getCustomAudience = (accountId) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/analytics/rest/v1/customaudience/accounts/${accountId}/customaudiences`;
        return axiosInstance.get(url);
    }

    const getCustomAudienceList = (accountId) => {
        setLoading(true);
        getCustomAudience(accountId).then((response) => {
            if (response.data.data.data.length > 0) setHasCustomAudience(true);
            setCustomAudienceList(response.data.data.data);

            setLoading(false);
        });

    };

    let createNewCustomAudience = (name, desc) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/analytics/rest/v1/customaudience/create/${orgId}/${selectedAdAccount}/customaudiences/new`;
        let data = {
            "newCustomAudienceData": {
                "isNew": true,
                "name": customAudience?.newCustomAudienceData?.name,
                "subtype": "CUSTOM",
                "description": customAudience?.newCustomAudienceData?.description,
                "customer_file_source": "USER_PROVIDED_ONLY",
                "selectedAdAccount": selectedAdAccount,
                "user_id" : userDetails.id
            },
            "criteria": {
                "fields": [
                    "email"
                ]
            },
            "type": "custom",
            "segmentId": segmentId,
            "dates": {
                "startDate": moment().subtract(30, "days"),
                "endDate": moment()
            }
        };
        
        selectTemplate(btoa(JSON.stringify(data)))
        // return axiosInstance.post(url, data);
    }

    
    let updateCustomAudience = (name, desc, id) => {
        let url = `${process.env.REACT_APP_API_ENDPOINT}/analytics/rest/v1/customaudience/create/${orgId}/${selectedAdAccount}/customaudiences/${customAudience.selected.id}`;

        let data = {
            "newCustomAudienceData": {
                "isNew": false,
                "name": name,
                "subtype": "CUSTOM",
                "description": desc,
                "customer_file_source": "USER_PROVIDED_ONLY",
                "selectedAdAccount": selectedAdAccount,
                "customAudienceId": id,
                "user_id" : userDetails.id
            },
            "criteria": {
                "fields": [
                    "email"
                ]
            },
            "type": "custom",
            "segmentId": segmentId,
            "dates": {
                "startDate": moment().subtract(30, "days"),
                "endDate": moment()
            }
        };
        
        selectTemplate(btoa(JSON.stringify(data)))
        // return axiosInstance.post(url, data);
    }

    const updateCreateCustomAudience = (id) => {
        if (!customAudience.create) {
            updateCustomAudience(
                customAudience.newCustomAudienceData.name,
                customAudience.newCustomAudienceData.description,
                id
            )
        } else {
            createNewCustomAudience(
                customAudience.newCustomAudienceData.name,
                customAudience.newCustomAudienceData.description
            )
        }
    };
    useEffect(() => {
        if (!!selectedAdAccount) {

            getCustomAudienceList(selectedAdAccount)
        }
    }, [selectedAdAccount])

    return (
        <Fragment>
            <Box padding="5px 35px 20px 35px">
                <Flex direction="column">
                    {!isFbConnected && (
                        <Flex alignItems="center" justifyContent="center" gap={2}>
                            <Link target='_blank' to={'/fb/auth/login/settings'}>
                               <button class="logout-btn" style={{width:"fit-content"}} >
                                        <img src='/images/flows/white/meta.svg' height={'30px'} width={'30px'} />
                                        Login with Meta
                                    </button>
                            </Link>
                            <Button variant="link" onClick={refreshFbAuthenticated}>
                                Refresh <FaSyncAlt />
                            </Button>
                        </Flex>
                    )}

                    {
                        !!isFbConnected &&
                        (
                            <Flex direction="column">
                                <Text>Select the Ad account to publish custom audience:</Text>
                                <Select
                                    placeholder="Select Ad Account..."
                                    onChange={(e) => selectAdAccount(e.target.value)}
                                    value={selectedAdAccount}
                                >
                                    {!!adAccountsList?.length && adAccountsList.map((account) => (
                                        <option key={account.account_id} value={account.account_id}>
                                            Name: {account.name} | ID: {account.account_id}
                                        </option>
                                    ))}
                                </Select>
                            </Flex>
                        )}

                    {loading && <Spinner />}

                    {
                        !!hasAdAccount &&
                        (
                            <Box>
                                <RadioGroup
                                    onChange={(value) =>
                                        setCustomAudience({ ...customAudience, create: value === 'create-new' })
                                    }
                                    value={customAudience.create ? 'create-new' : 'update-existing'}
                                >
                                    <Stack direction="column">
                                        <Radio isChecked={!customAudience.create} value="update-existing">Update Existing</Radio>
                                        {
                                            !customAudience.create &&
                                            <>


                                                <Select
                                                    value={customAudience.selected?.id || ''}
                                                    onChange={(e) => {

                                                        setCustomAudience({
                                                            ...customAudience,
                                                            selected: customAudienceList.find(aud => aud.id === e.target.value)
                                                        })
                                                        updateCreateCustomAudience(e.target.value)
                                                    }
                                                    }
                                                >
                                                    {!!customAudienceList?.length && customAudienceList.map(aud => (
                                                        <option key={aud.id} value={aud.id}>
                                                            {aud.name} | Reach: {aud.approximate_count}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </>
                                        }
                                        <Radio isChecked={customAudience.create} value="create-new">Create New</Radio>

                                        {
                                            !!customAudience.create &&
                                            <>
                                                <Input placeholder="Name" 
                                                    value={customAudience?.newCustomAudienceData?.name}
                                                    onChange={(e) => {
                                                        
                                                        setCustomAudience({ ...customAudience, newCustomAudienceData: { ...customAudience.newCustomAudienceData, name: e.target.value } })
                                                        updateCreateCustomAudience()
                                                    }
                                                    } />
                                                <Input
                                                    value={customAudience?.newCustomAudienceData?.description}
                                                    placeholder="Description" onChange={(e) => {
                                                        setCustomAudience({ ...customAudience, newCustomAudienceData: { ...customAudience.newCustomAudienceData, description: e.target.value } })
                                                        updateCreateCustomAudience()
                                                    }} />
                                            </>
                                        }

                                        {/* <PrimaryButton w={'100px'} label={'Create'} onClick={()=>createNewCustomAudience(customAudience?.newCustomAudienceData?.name,customAudience?.newCustomAudienceData?.description)}></PrimaryButton> */}
                                    </Stack>
                                </RadioGroup>
                                {/* <PrimaryButton label="Publish" onClick={updateCreateCustomAudience} /> */}
                            </Box>
                        )}

                </Flex>
            </Box>
        </Fragment>
    )
}

export default ExportCustomAudience;
