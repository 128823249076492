import React, { useMemo, useEffect, useRef } from 'react';
import { 
    Box, FormControl, FormLabel, InputGroup, InputLeftElement, Icon, 
    Input, Select, Skeleton 
} from '@chakra-ui/react';
import { campaignStatus } from './../../../_helpers/data_helper';
import { BiSearch } from 'react-icons/bi';
import { useFormik } from 'formik';
import _, { debounce } from 'lodash';
import { FaDownload } from 'react-icons/fa';

function CampaignFilter({ onFilter, channels, downloadReport, isLoading, campaignFilter }) {
    const initialValues = useMemo(() => ({
        campaignName: campaignFilter?.campaignName || '',
        status: campaignFilter?.status || '',
        channel: campaignFilter?.channel || '',
    }), [campaignFilter]);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: onFilter
    });

    useEffect(() => {
        console.log("Formik values updated:", formik.values);
    }, [formik.values]);

    const debounceFn = useRef(_.debounce(formik.handleSubmit, 1000)).current;

    function searchCampaign(e) {
        const value = e.target.value;
        formik.setFieldValue("campaignName", value);
        if (value.length > 2 || value.length === 0) {
            debounceFn();
        }
    }

    const safeChannels = channels || {};

    return (
        <Box display="flex" p="1rem" width="99%" bg="white" gap={3}>
            {/* Search Campaign */}
            <FormControl maxW="350px">
                <FormLabel color="inputLabel" fontSize="1vw" fontWeight="600">Search Campaign</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <Icon as={BiSearch} color="gray.500" />
                        </InputLeftElement>
                        <Input 
                            onChange={searchCampaign} 
                            value={formik.values.campaignName}
                            disabled={isLoading} 
                        />
                    </InputGroup>
                </Skeleton>
            </FormControl>

            {/* Status */}
            <FormControl maxW="350px">
                <FormLabel color="inputLabel" fontSize="1vw" fontWeight="600">Status</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                    <Select 
                        value={formik.values.status}
                        onChange={(e) => {
                            formik.setFieldValue("status", e.target.value);
                            formik.handleSubmit();
                        }}
                        disabled={isLoading}
                    > 
                        {campaignStatus.map(status => (
                            <option key={status.key} value={status.key}>{status.label}</option>
                        ))}
                    </Select>
                </Skeleton>
            </FormControl>

            {/* Channel */}
            <FormControl maxW="350px">
                <FormLabel color="inputLabel" fontSize="1vw" fontWeight="600">Channel </FormLabel>
                <Skeleton isLoaded={!isLoading}>
                    <Select 
                        value={formik.values.channel}
                        onChange={(e) => {
                            console.log("Setting channel to:", e.target.value);
                            formik.setFieldValue("channel", e.target.value);
                            formik.handleSubmit();
                        }}
                        disabled={isLoading}
                    >
                        <option value="">All</option>
                        {Object.keys(safeChannels).map(channel => (
                            <option key={channel} value={channel}>{channel}</option>
                        ))}
                    </Select>
                </Skeleton>
            </FormControl>

            {/* Download Report */}
            <FormControl display="flex" flexDirection="column" alignItems="center" maxW="350px">
                <FormLabel color="inputLabel" fontSize="1vw" fontWeight="600">Download Report</FormLabel>
                <Skeleton isLoaded={!isLoading}>
                    <Icon as={FaDownload} cursor="pointer" title="Download Report" onClick={downloadReport} />
                </Skeleton>
            </FormControl>   
        </Box>
    );
}

export default CampaignFilter;
