import { Text, Box, Flex, TableContainer, Table, useToken } from '@chakra-ui/react'
import React, { Fragment, useState } from 'react'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query';
import { fetchSegmentList } from '../../Services/api';
import { connect } from 'react-redux';
import TableHead from '../Campaigns/components/TableHead';
import SegmentTable from './SegmentTable';
import { OvalSpinner } from '../../components/Loaders';
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from '@ajna/pagination';
import hexToRgba from 'hex-to-rgba';
function SegmentList({ orgId ,orgToken}) {
    if(!!orgToken){
        orgId = orgToken
    }
    const navigate = useNavigate();
    const [totalCount, setTotalCount] = useState(0)
    const [mainColor] = useToken('colors', ['primary.main'])
    const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 10, isDisabled: false }, limits: { outer: 1, inner: 1 } })
    const customSegmentListQuery = useQuery(['customSegmentListQuery', currentPage], {
        queryFn: async () => {
            let resp = await fetchSegmentList(orgId, currentPage);
            !!resp?.data?.data?.length && setTotalCount(resp?.data?.data[0]?._total_count);
            return resp?.data?.data
        },
        cacheTime: 0
    })
    return (
        <Fragment>
            <Box w={'100%'} h={'100%'} overflowY={'scroll'}>
                <Flex justifyContent={'space-between'}>
                    <Text as={'b'} fontSize={'2xl'}>Segment List </Text>
                     {
                        !window.location.href.includes("all-banks") &&
                      <OutlinedButton width={'10vw'} borderRadius={'8px'} label={'Create Segment'} onClick={() => navigate("/audience/segment/create")} />
                     }
                </Flex>
                <TableContainer mt={3}>
                    {
                        !customSegmentListQuery?.data?.length && !customSegmentListQuery.isLoading && <Text>No Segment Created.</Text>
                    }
                    {
                        !!customSegmentListQuery.isLoading && <Fragment>
                            <Flex justifyContent={'center'} alignItems={'center'} h={'200px'}><OvalSpinner width={50} height={50}></OvalSpinner></Flex>
                        </Fragment>
                    }
                    {
                        !!customSegmentListQuery?.data?.length &&
                        <Box>
                            <Table>
                                <TableHead headersProps={['Segment Name', 'Valid Phone', 'Valid Email', 'Total Customers', 'Actions']}></TableHead>
                                <SegmentTable list={customSegmentListQuery?.data} customSegmentListQuery={customSegmentListQuery} orgId={orgId}></SegmentTable>
                            </Table>
                        </Box>
                    }
                    {
                        !!customSegmentListQuery?.data?.length && <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage}>
                            <PaginationContainer alignItems={'center'} justifyContent="end" mt={2}>
                                <PaginationPrevious mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
                                <PaginationPageGroup height={8} >
                                    {
                                        pages.map(page => (
                                            <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                                        ))
                                    }
                                </PaginationPageGroup>
                                <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
                            </PaginationContainer>
                        </Pagination>
                    }
                </TableContainer>

            </Box>
        </Fragment>
    )
}

const mapStatetoProps = (state) => {
    return {
        ...state,
        orgId: state.auth.selectedOrg.token
    }
}

export default connect(mapStatetoProps)(SegmentList)