import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { getWhiteLabelDetails, saveWhiteLabelApp } from "../../Services/api";
import "./WhitelabelEdit.css";
import ImageGallery from "../../components/ImageGallery";

const WhitelabelEdit = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const domain = searchParams.get("domain");
  const isUpdate = Boolean(domain);
  const [formData, setFormData] = useState({
    entityName: "",
    domain: "",
    email: "",
    companyName: "",
    emailFooter: "",
    helpUrl: "",
    darkBgLogo: "",
    lightBgLogo: "",
    plan: "",
    smtpSettings: {
      server: "",
      port: "",
      user: "",
      password: "",
      from: "",
      security: "SSL",
    },
    miscProperties: ""
  });

  useEffect(() => {
    if (isUpdate) {
      fetchEntityDetails();
    }
  }, [isUpdate]);

  const fetchEntityDetails = async () => {
    try {
      const response = await getWhiteLabelDetails(domain);
      setFormData({ ...response.data, smtpSettings: response.data.smtpSettings});
    } catch (error) {
      console.error("Error fetching details", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await saveWhiteLabelApp({ ...formData, domain });
      Swal.fire("Success", "Settings updated successfully", "success");
      navigate("/su/whitelabel");
    } catch (error) {
      Swal.fire("Error", "Failed to save", "error");
    }
  };

  return (
    <div className="container whitelabel-edit-container">
      <h1 className="whitelabel-edit-title">{isUpdate ? "Update" : "Create"} White Label App</h1>
      <form onSubmit={handleSubmit} className="whitelabel-edit-form">
        <label>White Label App Name</label>
        <input
          type="text"
          value={formData.entityName}
          onChange={(e) => setFormData({ ...formData, entityName: e.target.value })}
          placeholder="Eg: Mkt"
          required
        />
        <label>Domain</label>
        <input type="text" value={domain} disabled />
        <label>Contact Email</label>
        <input
          type="email"
          value={formData.email}
          onChange={(e) => setFormData({ ...formData, email: e.target.value })}
          placeholder="Eg: info@orbisdata.ai"
          required
        />
        <label>Email Footer</label>
        <input
          type="text"
          value={formData.emailFooter}
          onChange={(e) => setFormData({ ...formData, emailFooter: e.target.value })}
          placeholder="Enter email footer"
        />
        <label>HelpDesk URL</label>
        <input
          type="url"
          value={formData.helpUrl}
          onChange={(e) => setFormData({ ...formData, helpUrl: e.target.value })}
          placeholder="Eg: https://mktsupport.zendesk.com"
        />
        <label>Logo - Dark Background (230px x 70px)</label>
            <div className="logo-container">
              <ImageGallery
                setImageData={(image) =>
                  setFormData({ ...formData, darkBgLogo: image?.mediaFileUrl || "" })
                }
                renderComponent={<button type="button" className="btn btn-primary">Choose from Gallery</button>}
              />
              {formData.darkBgLogo && <img src={formData.darkBgLogo} alt="Dark Logo" className="whitelabel-logo-dark" style={{ maxWidth: "500px", maxHeight: "500px" }} />}
            </div>

            <label>Logo - Light Background (70px x 70px)</label>
            <div className="logo-container">
              <ImageGallery
                setImageData={(image) =>
                  setFormData({ ...formData, lightBgLogo: image?.mediaFileUrl || "" })
                }
                renderComponent={<button type="button" className="btn btn-primary">Choose from Gallery</button>}
              />
              {formData.lightBgLogo && <img src={formData.lightBgLogo} alt="Light Logo" className="whitelabel-logo-light" style={{ maxWidth: "500px", maxHeight: "500px" }} />}
            </div>
        <label>SMTP Host</label>
        <input
          type="text"
          value={formData.smtpSettings.server}
          onChange={(e) => setFormData({ ...formData, smtpSettings: { ...formData.smtpSettings, server: e.target.value } })}
          placeholder="Eg: smtp.us-east-1.amazonaws.com"
          required
        />
        <label>SMTP Port</label>
        <input
          type="number"
          value={formData.smtpSettings.port}
          onChange={(e) => setFormData({ ...formData, smtpSettings: { ...formData.smtpSettings, port: e.target.value } })}
          placeholder="Eg: 25"
          required
        />
        <label>SMTP User</label>
        <input
          type="text"
          value={formData.smtpSettings.user}
          onChange={(e) => setFormData({ ...formData, smtpSettings: { ...formData.smtpSettings, user: e.target.value } })}
          required
        />
        <label>SMTP Password</label>
        <input
          type="password"
          value={formData.smtpSettings.password}
          onChange={(e) => setFormData({ ...formData, smtpSettings: { ...formData.smtpSettings, password: e.target.value } })}
          required
        />
        <label>From Email</label>
        <input
          type="text"
          value={formData.smtpSettings.from}
          onChange={(e) => setFormData({ ...formData, smtpSettings: { ...formData.smtpSettings, from: e.target.value } })}
          required
        />
        <label>Security Method</label>
        <select
          value={formData.smtpSettings.security}
          onChange={(e) => setFormData({ ...formData, smtpSettings: { ...formData.smtpSettings, security: e.target.value } })}
        >
          <option value="SSL">SSL</option>
          <option value="STARTTLS">Start TLS</option>
          <option value="NONE">None</option>
        </select>
        <label>Misc Settings</label>
        <input
          type="text"
          value={formData.miscProperties}
          onChange={(e) => setFormData({ ...formData, miscProperties: e.target.value })}
          placeholder="{....}"
          required
        />
        <div className="whitelabel-edit-actions">
          <button type="submit" className="btn btn-success">{isUpdate ? "Update" : "Create"}</button>
          <button type="button" className="btn btn-secondary" onClick={() => navigate("/overview")}>Cancel</button>
        </div>
      </form>
    </div>
  );
};

export default WhitelabelEdit;
