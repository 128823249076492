import React, { useEffect } from 'react'
import { Avatar, Box, HStack, VStack, Text, Image , Flex, Button, CloseButton} from '@chakra-ui/react'
import WebPushPreview from '../Push/WebPushPreview'
import 'react-device-frameset/styles/marvel-devices.min.css'

import * as _ from 'lodash'


function Template001({ formData }) {
    return (
      <Box bg={formData?.notificationDetails?.templateBackground} p={4} borderRadius="md">
        {formData?.notificationDetails?.isclose === true && (
              <CloseButton
                position="absolute"
                top="0rem"
                right="0rem"
                color="black"
              />
            )}
        <Flex p={2} gap={4} alignItems="center">
          {formData?.templateImageUrl[0] && formData?.templateType?.type !== 'Text Only' && (
            <Image src={formData.templateImageUrl[0]} boxSize="70px" />
          )}
          <Box>
          
            <Text fontSize={formData.notificationDetails?.titleFontSize} color={formData?.notificationDetails?.titleColor} fontWeight="bold">
              {formData?.templateTitle} 
            </Text>
            <Text fontSize={formData?.notificationDetails?.descriptionFontSize} color={formData?.notificationDetails?.descriptionFontColor} isTruncated>
              {formData?.templateDescription}
            </Text>
          </Box>
        </Flex>
        
        <Flex flexDirection={formData?.notificationDetails?.buttonOrientation === 'vertical' ? 'column' : 'row'} gap={2} p={2}>
          { !!formData?.buttons?.length && formData.buttons.map((btn, index) => (
            <Button key={index} bg={btn.buttonColor} color={btn.buttonFontColor} fontSize={btn.buttonFontSize} flex="1">
              {btn.buttonName}
            </Button>
          ))}
        </Flex>
      </Box>
    );
  }

  function Template002({ formData }) {
    return (
      <Box
        id="wigzoHolder"
        className="wigzoHolder smaller-size"
        display="block"
        background={formData?.notificationDetails?.templateBackground || '#fff'}
        position="absolute"
        bottom="40px"
        width="100%"
        boxShadow="0 0 10px rgba(0, 0, 0, .2)"
      >
        {formData?.notificationDetails?.isclose === true && (
            <CloseButton
              position="absolute"
              top="0rem"
              right="0rem"
              color="black"
            />
          )}
        <Box
          className="wigzo-notification"
          display="flex"
          padding="10px"
        >
          {formData?.templateImageUrl?.[0] && formData?.templateType?.type !== 'Text Only' && (
            <Image
              height="70px"
              width="70px"
              src={formData?.templateImageUrl?.[0]}
              alt="Notification Image"
            />
          )}
  
          <Box className="wigzo-notification-info" flex="1" pl={2}>
            {formData?.templateType?.type !== 'Image Only' && (
              <VStack align="flex-start" spacing={0}>
                <Text
                  fontSize={formData?.notificationDetails?.titleFontSize || '14px'}
                  color={formData?.notificationDetails?.titleColor || '#000'}
                >
                  {formData?.templateTitle || 'Title goes here'}
                </Text>
                <Text
                  className="description"
                  fontSize={formData?.notificationDetails?.descriptionFontSize || '12px'}
                  color={formData?.notificationDetails?.descriptionFontColor || '#000'}
                  isTruncated
                >
                  {formData?.templateDescription || 'Description goes here'}
                </Text>
              </VStack>
            )}
          </Box>
          {formData?.notificationDetails?.isClose === 'true' && (
            <Text as="p" className="bx box-sizing" cursor="pointer">x</Text>
          )}
        </Box>
  
        {formData?.buttons?.length > 0 && (
          <HStack
            className="button-container"
            justify="space-around"
            textAlign="center"
            p={2}
            gap={2}
            flexDirection={
              formData?.notificationDetails?.buttonOrientation === 'vertical'
                ? 'column'
                : 'row'
            }
          >
            {formData?.buttons?.map((btn, index) => (
              <Button
                key={index}
                background={btn?.buttonColor || '#000'}
                width="100%"
                padding="0.5rem"
                fontSize={btn?.buttonFontSize || '14px'}
                color={btn?.buttonFontColor || '#fff'}
                borderRadius="8px"
              >
                {btn?.buttonName || `Button ${index + 1}`}
              </Button>
            ))}
          </HStack>
        )}
      </Box>
    );
  }
  

  function Template003({ formData }) {
    return (
      <Box
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor="rgba(0, 0, 0, 0.5)"
        zIndex={9999}
      >
        <Box
          background={formData?.notificationDetails?.templateBackground || '#fff'}
          p={4}
          borderRadius="12px"
          textAlign="center"
          width="100%"
          maxW="400px"
        >
          {formData?.notificationDetails?.isclose === true && (
            <CloseButton
              position="absolute"
              top="1rem"
              right="1rem"
              color="black"
            />
          )}
    
          {formData?.templateImageUrl?.[0] && (
            <Image
              src={formData?.templateImageUrl?.[0]}
              height="110px"
              mb={3}
            />
          )}
    
          {formData?.templateType?.type !== 'Image Only' && (
            <VStack spacing={1}>
              <Text
                fontSize={formData?.notificationDetails?.titleFontSize || '16px'}
                color={formData?.notificationDetails?.titleColor || '#000'}
              >
                {formData?.templateTitle || 'Title goes here'}
              </Text>
              <Text
                fontSize={formData?.notificationDetails?.descriptionFontSize || '14px'}
                color={formData?.notificationDetails?.descriptionFontColor || '#000'}
                isTruncated
                maxWidth="100%"
              >
                {formData?.templateDescription || 'Description goes here'}
              </Text>
            </VStack>
          )}
    
          <HStack
            spacing={2}
            mt={4}
            flexDirection={
              formData?.notificationDetails?.buttonOrientation === 'vertical'
                ? 'column'
                : 'row'
            }
          >
            {!!formData?.buttons?.length && formData?.buttons.map((btn, index) => (
              <Button
                key={index}
                background={btn?.buttonColor || '#000'}
                color={btn?.buttonFontColor || '#fff'}
                fontSize={btn?.buttonFontSize || '14px'}
                borderRadius="8px"
                flex={1}
                width="100%"
              >
                {btn?.buttonName || `Button ${index + 1}`}
              </Button>
            ))}
          </HStack>
        </Box>
      </Box>
    );
  }
  
  
  function Template004({ formData }) {
    
    return (
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundImage={`url(${formData?.templateImageUrl?.[0] || ''})`}
        backgroundSize="100% 100%"
        zIndex={9999}
      >
        {formData?.notificationDetails?.isclose && (
          <CloseButton
            position={'absolute'}
            right={'0'}
            top={'0'}
          >
            
          </CloseButton>
        )}
  
        <Box
          height="100%"
          display="flex"
          justifyContent="space-around"
          flexDirection="column"
          alignItems="flex-start"
          p={4}
        >
          {formData?.templateType?.type !== 'Image Only' && (
            <Box ml="30px" mt="3vh">
              <Text
                fontSize={formData?.notificationDetails?.titleFontSize || '16px'}
                color={formData?.notificationDetails?.titleColor || '#000'}
              >
                {formData?.templateTitle || 'Title goes here'}
              </Text>
              <Text
                fontSize={formData?.notificationDetails?.descriptionFontSize || '14px'}
                color={formData?.notificationDetails?.descriptionFontColor || '#000'}
                isTruncated
                noOfLines={1}
                maxW="9vw"
              >
                {formData?.templateDescription || 'Description goes here'}
              </Text>
            </Box>
          )}
  
          {!!formData?.buttons?.length && (
            <HStack
              spacing={2}
              justifyContent="space-around"
              alignItems="center"
              width="100%"
              flexDirection={
                formData?.notificationDetails?.buttonOrientation?.includes('vertical')
                  ? 'column'
                  : 'row'
              }
            >
              {formData?.buttons?.map((btn, index) => (
                <Button
                  key={index}
                  background={btn?.buttonColor || '#000'}
                  color={btn?.buttonFontColor || '#fff'}
                  fontSize={btn?.buttonFontSize || '14px'}
                  borderRadius="8px"
                  width="100%"
                >
                  {btn?.buttonName || `Button ${index + 1}`}
                </Button>
              ))}
            </HStack>
          )}
        </Box>
      </Box>
    );
  }
  function InappPreview({ formData ,type}) {

    
    let parsedFormData = { ...formData };

    try {
        parsedFormData.templateType = JSON.parse(formData.templateType);
    } catch (error) {
        parsedFormData.templateType = formData.templateType;
    }

    try {
        parsedFormData.buttons = JSON.parse(formData.buttons);
    } catch (error) {
        parsedFormData.buttons = formData.buttons;
    }

    try {
        parsedFormData.templateImageUrl = JSON.parse(formData.templateImageUrl);
    } catch (error) {
        parsedFormData.templateImageUrl = formData.templateImageUrl;
    }

    try {
        parsedFormData.targetPlatform = JSON.parse(formData.targetPlatform);
    } catch (error) {
        parsedFormData.targetPlatform = formData.targetPlatform;
    }

    const templates = {
        "001": <Template001 formData={parsedFormData} />,
        "002": <Template002 formData={parsedFormData} />,
        "003": <Template003 formData={parsedFormData} />,
        "004": <Template004 formData={parsedFormData} />, 
    }

    return (
        <>
            <Box>
                <div class="relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] z-[99] ">
                    <div class="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
                    <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
                    <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
                    <div class="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
                    <div
                        className="rounded-[2rem] overflow-hidden w-[272px] h-[572px]"
                        style={{
                            background: 'linear-gradient(90deg, hsla(332, 53%, 82%, 1) 0%, hsla(176, 57%, 89%, 1) 100%)'
                        }}
                    >
                    {templates[formData.layoutId]}
                    </div>
                </div>

            </Box>
        </>
    )
}

export default InappPreview
