import React from 'react'
import { Modal, Box, ModalOverlay, ModalContent, ModalCloseButton, ModalHeader, ModalBody } from '@chakra-ui/react'
import Editor from '../Email/Editor'
import { QueryClient, QueryClientProvider } from "react-query";
import SegmentList from '../Audience/SegmentList';
export default function SegmentListModal({isOpen, onClose,orgId}) {
    
    
    return (
        <Modal  isOpen={isOpen} onClose={onClose} size={"6xl"} scrollBehavior='inside'>
            <ModalOverlay></ModalOverlay>
            <ModalContent  >
                {/* <ModalHeader fontSize={'sm'}></ModalHeader> */}
                <ModalCloseButton onClick={onClose} ></ModalCloseButton>
                <ModalBody>
                    <SegmentList orgToken={orgId} orgId={orgId} />
                </ModalBody>
            </ModalContent>
        </Modal>

    )
}
