import { Text, Box, Flex, useSteps, IconButton, useToast, layout } from '@chakra-ui/react'
import _ from 'lodash';
import React, { Fragment, createRef, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { onsitePushTemplates } from './NudgeTemplates';
import { useFormik } from 'formik';
import { getOnsiteFormData } from '../../_helpers/formValues';
import { getNudgeEditSteps, getPreviewMarkup, prepareNudgeTemplateFinalData, readTemplateAndGetMarkup } from './NudgeHelper';
import NudgeStepper from './NudgeComponents/NudgeStepper';
import VisualSettings from './NudgeComponents/VisualSettings';
import { connect } from 'react-redux';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import Mustache from "mustache"
import useStateRef from 'react-usestateref';
import SecondaryButton from '../../components/themeComponents/SecondaryButton';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import OutlinedButton from '../../components/themeComponents/OutlinedButton';
import NudgeTargeting from './NudgeComponents/NudgeTargeting';
import NudgeFinalTouch from './NudgeComponents/NudgeFinalTouch';
import { showSwal } from '../../_helpers/helpers';
import Swal from 'sweetalert2';
import { fetchNudgeTemplateData, saveOnsiteTemplate } from '../../Services/api';

function NudgeTemplateForm({ orgToken }) {
    const { templateUuid } = useParams();
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [formData, setFormData, formDataRef] = useStateRef(null)
    const navigate = useNavigate();
    const toast = useToast();
    const [searchParams] = useSearchParams();
    const uuid = searchParams.get("uuid");
    const { activeStep, setActiveStep } = useSteps({
        index: 0, count: getNudgeEditSteps().length
    })
    const stepsRef = {
        visualSettings: useRef(null),
        nudgeTargeting: useRef(null),
        finalSettings: useRef(null)
    }

    function getSelectedTemplate() {
        let retVal = {};
        retVal = Object.keys(onsitePushTemplates).filter(e => {
            if (onsitePushTemplates[e].uuid === templateUuid) {
                //formik.setFieldValue("layoutId", e)
                setFormData({ layoutId: e, ...formData })
                return onsitePushTemplates[e]
            }
            //return onsitePushTemplates[e].uuid === uuid
        })
        return onsitePushTemplates[retVal[0]]
    }
    const formik = useFormik({
        initialValues: getOnsiteFormData(),
        enableReinitialize: true
    })
    const prepareTemplateDataForEditing = async () => {
        let resp = await readTemplateAndGetMarkup(formDataRef.current.layoutId);
        let tempFormData = { ...formDataRef.current };
        
        if (resp.data) {
            tempFormData.markup.raw = _.clone(resp.data);
            tempFormData.template.props = _.clone(onsitePushTemplates[formDataRef.current.layoutId])
            setFormData(tempFormData)
        }

    }
    const previewTemplate = async () => {
        if (stepsRef?.visualSettings?.current) {
            stepsRef.visualSettings.current.collectAllFormDataAndSubmit();
        }
    
        let previewMarkup = await getPreviewMarkup();
        let renderedMarkup = Mustache.render(formDataRef.current.markup.raw, { formData: formDataRef.current });
        
        let renderedPreview = Mustache.render(previewMarkup.data, { markup: renderedMarkup });
    
        var previewWindow = window.open("", "Preview");
        previewWindow.document.open();
        previewWindow.document.write(renderedPreview);
        previewWindow.document.title = "Preview";
        previewWindow.document.close();
        previewWindow.focus();
    };
    
    const nextStep = () => {
        if (activeStep === 0) {
            stepsRef.visualSettings.current.collectAllFormDataAndSubmit();
        } else if (activeStep === 1) {
            stepsRef.nudgeTargeting.current.submitToParent()
        } else if (activeStep === 2) {
            stepsRef.finalSettings.current.submitToParent();
            saveNudgeTemplate()
        }
        !(activeStep === 2) && setActiveStep(activeStep + 1)
    }
    const saveNudgeTemplate = () => {
        showSwal(Swal, 'Are you sure?', `Do you want to ${!!uuid ? 'update' : 'save'} the template?`, true, true, !!uuid ? 'Update' : 'Save', 'No', async () => {
            let finalTempData = prepareNudgeTemplateFinalData(formDataRef.current);
            let res = await saveOnsiteTemplate(finalTempData, formDataRef.current.uuid).catch(error => {
                toast({ title: 'Oops', description: `Error while ${!!uuid ? 'updating' : 'creating'} the nudge template. Please try again.`, status: 'error' });
                Swal.close()
            });
            !_.isEmpty(res.data) ? toast({ title: 'Success', description: `Nudge Template ${!!uuid ? 'Updated' : 'Created'}`, status: 'success' }) : toast({ title: 'Oops', description: `Error while ${!!uuid ? 'updating' : 'creating'} the nudge template. Please try again.`, status: 'error' });;

        }, (res) => {
            !!res.isConfirmed && (() => {
                //formik.setSubmitting(false)
                Swal.close()
                navigate("/nudge/list");
            })()
        })
    }
    const fetchTemplateDataAndSet = async () => {
        let resp = await fetchNudgeTemplateData(templateUuid);
        console.log("data from response", resp.data);
        if (!_.isEmpty(resp.data)) {
            let data = resp.data;
            let tempFormData = {};
            tempFormData["name"] = data.name;
            tempFormData['description'] = data.description;
            tempFormData['uuid'] = data.uuid;
            tempFormData['layoutId'] = data.layout_id;
            tempFormData['template'] = {}
            tempFormData.template['props'] = data.template_props;
            tempFormData['settings'] = data.settings;
            tempFormData.settings.locationAndDeviceTargeting.criteria.length && (() => {
                let criteria = tempFormData.settings.locationAndDeviceTargeting.criteria;
                criteria.length && criteria.map(criterion => {
                    criterion.value = criterion.length && criterion.value.map(e => {
                        return { value: e, label: e }
                    })
                })
                tempFormData.settings.locationAndDeviceTargeting.criteria = criteria;
            })()
            tempFormData['markup'] = data.markup;
            tempFormData['status'] = data.status;
            tempFormData['type'] = 'template';
            setFormData(tempFormData);
        }
    }
    useEffect(() => {
        if (!!templateUuid && !_.isEmpty(getSelectedTemplate())) {
            let selectedTemplate = getSelectedTemplate();
            
            let temp = getOnsiteFormData();
            temp["layoutId"] = formDataRef.current.layoutId
            temp["template"]["props"] = selectedTemplate
            
            setFormData(temp)
            //formik.setFieldValue("template.props", selectedTemplate);
            prepareTemplateDataForEditing()

        } else if (!!uuid) {
            fetchTemplateDataAndSet()
        } else {
            navigate("/nudge/layout")
        }
    }, [])
    const updateMarkup = (markup) => {
        let tempFormData = { ...formData };
        tempFormData.markup.raw = markup;
        setFormData(tempFormData);
    }
    const updateTemplateProps = (props) => {
        console.log("props from child", props)
        let tempFormData = { ...formData };
        tempFormData.template.props = props;
        setFormData(tempFormData)
    }
    const updateTemplateSettings = (settings) => {
        console.log("data from child settings", settings);
        let tempFormData = { ...formDataRef.current };
        tempFormData.settings = settings;
        setFormData(tempFormData);
    }
    const updateFinalSettings = (finalSettings) => {
        let tempFormData = { ...formDataRef.current };
        tempFormData.settings.ttl = finalSettings.ttl;
        tempFormData.name = finalSettings.name;
        tempFormData.description = finalSettings.description;
        setFormData(tempFormData);
    }
    return (
        <Fragment>
            <Box w={'100%'} h={'100%'} overflow={'hidden'}>
                <Flex justifyContent={'space-between'}>
                    {/* {JSON.stringify(formik.values)} */}
                    <Text as={'b'} fontSize={'2xl'} onClick={() => setActiveStep(activeStep + 1)}>Onsite Template </Text>
                </Flex>
                <NudgeStepper activeStep={activeStep}></NudgeStepper>
                {/* <IconButton isRound={true} variant={'solid'} bg={'primary.main'} icon={<MdOutlineRemoveRedEye fill='white'/>} position={'fixed'} right={10} bottom={10} onClick={() => previewTemplate()}></IconButton> */}
                <form action={process.env.REACT_APP_API_ENDPOINT + "/onsitepush/campaign/preview"} id="previewForm" method="POST"
                    target="Preview">
                    <input id="previewMarkup" name="markup" type="hidden" />
                    <input id="orgToken" name="orgToken" type="hidden" value={orgToken} />
                </form>
                {
                    !!formDataRef?.current && <Box maxW={'1230px'} margin={'0 auto'} p={2} height={'calc(100% - 110px)'}>
                        
                        {
                            activeStep === 0 && <VisualSettings templateProps={formDataRef.current.template.props} markup={formDataRef.current.markup.raw} updateMarkup={updateMarkup} updateTemplateProps={updateTemplateProps} ref={stepsRef.visualSettings}></VisualSettings>
                        }
                        {
                            activeStep === 1 && <NudgeTargeting data={formDataRef.current.settings} ref={stepsRef.nudgeTargeting} updateTemplateSettings={updateTemplateSettings}></NudgeTargeting>
                        }
                        {
                            activeStep === 2 && <NudgeFinalTouch
                                data={{ name: formDataRef.current.name, description: formDataRef.current.description, ttl: formDataRef.current.settings.ttl }} ref={stepsRef.finalSettings}
                                updateFinalSettings={updateFinalSettings}></NudgeFinalTouch>
                        }
                        <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'} bg={'white'} borderTop={'1px solid'} borderColor={'gray.200'} gap={3}>
                            <OutlinedButton label={'Preview'} size="md" onClick={() => previewTemplate()}></OutlinedButton>
                            {
                                activeStep !== 0 && <SecondaryButton label={'Back'} onClick={() => setActiveStep(activeStep - 1)}></SecondaryButton>
                            }
                            <PrimaryButton label={'Next'} onClick={nextStep}></PrimaryButton>
                        </Box>
                    </Box>
                }

            </Box>
        </Fragment>
    )
}
const mapStatetoProps = (state) => {
    return {
        ...state,
        orgToken: state.auth.selectedOrg.token
    }
}
export default connect(mapStatetoProps)(NudgeTemplateForm)
