import { Box, Icon, Input, InputGroup, InputLeftElement, Table, TableContainer, Text, useDisclosure, Select, FormControl, FormLabel, useToken, Flex, HStack } from '@chakra-ui/react'
import React, { Fragment, useRef, useState, useEffect, useCallback } from 'react'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import CampaignDraftDrawer from './CampaignDraftDrawer'
import DateRangeSelection from '../../components/DateRangeSelection'
import moment from 'moment'
import TableHead from './components/TableHead'
import CampaignTable from './components/CampaignTable'
import { useQuery } from 'react-query'
import { getBroadcastData, prepareCampaignListData } from '../../_helpers/data_helper'
import { getBroadcastHeaders, getCampaigns } from '../../Services/api'
import * as _ from 'lodash'

import CampaignFilter from './components/CampaignFilter'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Pagination, PaginationContainer, PaginationNext, PaginationPage, PaginationPageGroup, PaginationPrevious, usePagination } from '@ajna/pagination'
import hexToRgba from 'hex-to-rgba'
import { OvalSpinner } from '../../components/Loaders'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import { FaDownload } from 'react-icons/fa'
import DownloadReportModal from './DownloadReportModal'

function CampaignList({ org }) {
  const [mainColor] = useToken('colors', ['primary.main'])
  const [dateRange, setDateRange] = useState({ startDate: moment().subtract(30, "days"), endDate: moment() });
  const [totalCount, setTotalCount] = useState(0)
  const [selectChannel, setSelectedChannel] = useState("")
  const [isDownloadReportModalOpen, setIsDownloadReportModalOpen] = useState(false)
  const [broadcastHeaders,setBroadcastHeaders] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [campaignFilter, setCampaignFilter] = useState({
    campaignName: '',
    channel: '',
    status: 'ALL'
  });

  useEffect(() => {
    if (!campaignFilter.channel) {
      setCampaignFilter(prevFilter => ({ ...prevFilter, channel: "Email" }));
    }
  }, [campaignFilter.channel]);

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({ total: totalCount, initialState: { currentPage: 1, pageSize: 5, isDisabled: false }, limits: { outer: 1, inner: 1 } })
  const [campaignData, setCampaignData] = useState([])
  const campaignQuery = useQuery(
    ['campaigns', dateRange, campaignFilter, currentPage],
    {
      queryFn: async ({ signal }) => {
        const abortController = new AbortController();
        
        try {
          let res = await getCampaigns(org.token, campaignFilter, dateRange, currentPage, { signal: abortController.signal });
          let broadcastHeaders = await getBroadcastHeaders({ signal: abortController.signal });
          
          setTotalCount(res?.data?.count);
          setBroadcastHeaders(broadcastHeaders.data);
          
          setCampaignData(res.data.campaigns);
          return prepareCampaignListData(res.data.campaigns, broadcastHeaders.data, campaignFilter);
        } catch (error) {
          if (error.name === "AbortError") {
            console.log("Request aborted");
          } else {
            throw error;
          }
        }
      },
      onError: (error) => {
        console.error("Error fetching campaigns:", error);
      },
    }
  );

  function onDateChange(date) {
    setDateRange({ startDate: date.startDate, endDate: date.endDate })
  }
useEffect(()=>{
  campaignQuery.refetch()
},[campaignFilter])
  return (
    <Fragment>
      <DateRangeSelection dateRange={dateRange} onDateChange={onDateChange} isLoading={campaignQuery.isLoading}></DateRangeSelection>
      <Box zIndex={99} display={'flex'} alignItems={'center'} justifyContent={'space-between'} my={4}>
        <Text fontSize={'1vw'} as={'b'}>Campaigns</Text>
        <OutlinedButton label={'Create Campaign'} onClick={() => onOpen()}></OutlinedButton>
      </Box>
      
      <CampaignFilter 
    isLoading={campaignQuery.isLoading} 
    downloadReport={() => setIsDownloadReportModalOpen(true)} 
    channels={broadcastHeaders} 
    campaignFilter={campaignFilter}  
    onFilter={(dt) =>{
      
      setCampaignFilter(prev => ({ ...prev, ...dt }))
      
      } } 
/>

      <TableContainer bg={'white'} maxH={'60vh'} w={'99%'} overflowY={'auto'} mt={0} position="relative">
        {
                                         campaignQuery.isLoading && <OvalSpinner/>
                                        }
        <Table opacity={campaignQuery.isLoading ? 0.5 : 1}>
          {
            !!campaignQuery?.data?.data?.length > 0 ? (
              <Fragment>
                <TableHead headersProps={broadcastHeaders[campaignFilter.channel] || []} />
                <CampaignTable selectedChannel={campaignFilter.channel} campaignDataRaw={campaignData} campaignQuery={campaignQuery} broadcastHeaders={broadcastHeaders} campaignData={campaignQuery.data?.data} />
              </Fragment>
            ) : (
              <Flex direction="column" justifyContent="center" alignItems="center" py={10}>
                <Text fontSize="lg" fontWeight="bold" color="gray.600">
                  No campaigns have been created yet.
                </Text>
              </Flex>
            )
          }
        </Table>
      </TableContainer>
      {
          !campaignQuery.isLoading && !!campaignQuery?.data?.data?.length>0 &&  <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={setCurrentPage} >
            <PaginationContainer alignItems={'center'} justifyContent="end" mt={2}>
              <PaginationPrevious  mr={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&lt;</PaginationPrevious>
              <PaginationPageGroup height={8}  >
                {
                  pages.map(page => (
                    <PaginationPage key={`pagination_page_${page}`} page={page} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8} isDisabled={currentPage === page}></PaginationPage>
                  ))
                }
              </PaginationPageGroup>
              <PaginationNext ml={2} w={8} bg={hexToRgba(mainColor, .4)} p={1} fontSize={'xs'} height={8} minW={8}>&gt;</PaginationNext>
            </PaginationContainer>
          </Pagination>
        }
      
      <CampaignDraftDrawer isOpen={isOpen} onClose={onClose} orgToken={org.token}></CampaignDraftDrawer>
      <DownloadReportModal dateRange={dateRange} channel={campaignFilter.channel} campaignFilter={campaignFilter} isOpen={isDownloadReportModalOpen} onClose={() => setIsDownloadReportModalOpen(false)} />
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
    org: state.auth.selectedOrg
  }
}
export default connect(mapStateToProps)(CampaignList);
