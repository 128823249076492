import {
    Flex, Icon, Switch, Td, Tr, Text, useToast, useDisclosure,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,
    ModalCloseButton, Box, Divider, Code
} from '@chakra-ui/react';
import React, { Fragment, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { CiExport } from "react-icons/ci";
import { IoRefresh } from 'react-icons/io5';
import { showSwal } from '../../_helpers/helpers';
import Swal from 'sweetalert2';
import { deleteCustomSegment, getSegmentViewDetails,  refreshSegment, toggleCustomSegmentStatus } from '../../Services/api';
import ExportCustomAudienceModal from './ExportCustomAudienceModal';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import * as _ from "lodash";
import moment from 'moment';
import SecondaryButton from '../../components/themeComponents/SecondaryButton';

export default function SegmentTable({ list, customSegmentListQuery, orgId }) {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure();
    const toast = useToast();
    const [selectedSegmentId, setSelectedSegmentId] = useState("");
    const [segmentDetails, setSegmentDetails] = useState(null);
    const [updatedList, setUpdatedList] = useState(list); 

    const getViewDetails = async (id, isRefreshOnly) => {
        try {
            const response = await getSegmentViewDetails(orgId,id);
            if (response?.data?.data) {
                
                const segmentData = response.data.data;
                setSegmentDetails(segmentData);
                
                const updatedSegments = updatedList.map(seg =>
                    seg.id === id ? { ...seg, aggregates: segmentData.aggregates,
                        pre_cache_status: segmentData.pre_cache_status
                     } : seg
                );
                setUpdatedList(updatedSegments);

                !isRefreshOnly && onDetailOpen();
                !!isRefreshOnly && toast({ title: 'Success', description: 'segment has been refreshed.', status: 'success' });
            } else {
                throw new Error("No data received from API.");
            }
        } catch (error) {
            toast({ title: 'Error', description: 'Failed to fetch segment details.', status: 'error' });
        }
    };
    const confirmRefreshSegment = (segmentId, inProcess) => {
        showSwal(
            Swal,
            'Are you sure?',
            !!inProcess? "The system is currently fetching the data for this segment. Do you want to cancel the current process and fetch the data again?" :'Do you want to refresh this segment?',
            true,
            true,
            'Yes',
            'No',
            async () => {
                try {
                     let refreshStatus =  await refreshSegment(orgId, segmentId);
                     !!refreshStatus && getViewDetails(segmentId, true)
                    toast({ title: 'Refetched', description: 'Segment data has been refetched.', status: 'success' });
                } catch (error) {
                    toast({ title: 'Oops!', description: 'Error in refreshing the segment. Please try again.', status: 'error' });
                }
            },
            () => {
                Swal.close(); 
            }
        );
    }

    
    const toggleSegment = (segment) => {
        let swalTextCheck = !!(segment.active_segments_count <= 5 && segment.pre_cache_status == 'DISABLED')
        let segmentStatus = segment.pre_cache_status === 'ENABLED' ? 'DISABLE' : 'ENABLE';
        let swalText = swalTextCheck ? ' The least recently used segment will be disabled' : 'Are You Sure?'
        let swalTitle = swalTextCheck ? " You are about to enable segment more than the maximum limit" : `Do you want to ${segmentStatus} the segment`
        showSwal(Swal, swalTitle, swalText, true, true, 'Continue', 'No', async () => {
            let resp = await toggleCustomSegmentStatus(orgId, segment.id, segment.pre_cache_status).catch(error => {
                toast({ title: 'Oops', description: `Something went wrong while ${segment.pre_cache_status === 'ENABLED' ? 'disabling' : 'enabling'} the segment`, status: 'error' })
            });
            !!resp && getViewDetails(segment.id, true)
            let toastTitle = segment.pre_cache_status === 'ENABLED' ? 'Disabled' : 'Enabled';
            let toastDesc = segment.pre_cache_status === 'ENABLED' ? 'Segment is disabled. You can enable it anytime' :
                'Your segment is being prepared in background. You will be notified via email once the data is ready.';
            toast({ title: toastTitle, description: toastDesc, status: 'success' });
            customSegmentListQuery.refetch()
        }, () => {})
    }
    const deleteSegment = (segmentId) => {
        showSwal(
            Swal,
            'Are you sure?',
            'Do you want to delete the segment?',
            true,
            true,
            'Yes',
            'No',
            async () => {
                try {
                    let resp = await deleteCustomSegment(segmentId, orgId);
                    if (resp?.data?.data?.success) {
                        toast({ title: 'Deleted', description: 'Segment deleted successfully.', status: 'success' });
                        customSegmentListQuery.refetch();
                    } else {
                        throw new Error("Segment deletion failed.");
                    }
                } catch (error) {
                    toast({ title: 'Oops!', description: 'Error deleting the segment.', status: 'error' });
                } finally {
                    Swal.close();
                }
            },
            () => Swal.close()
        );
    };

    return (
        <Fragment>
            {updatedList.map(lt => (
                <Tr key={lt.id}>
                    <Td>
                        <Text >
                            {lt.label}
                        </Text>
                        <Text textDecoration={'underline'} cursor="pointer" color="blue.500" onClick={() => getViewDetails(lt.id, false)}>
                            View Details
                        </Text>
                    </Td>
                    <Td>{lt.aggregates?.count_phone || 0}</Td>
                    <Td>{lt.aggregates?.count_email || 0}</Td>
                    <Td>{lt.aggregates?.count_customers || 0}</Td>
                    <Td>
                        <Flex alignItems={'center'} gap={3}>
                            <Icon cursor={'pointer'} title='Export Custom Audience' onClick={() => { setSelectedSegmentId(lt.id); onOpen(); }} as={CiExport} />
                            <Icon as={FaTrash} onClick={() => deleteSegment(lt.id)} />
                            <Switch size={'md'} isChecked={lt.pre_cache_status === 'ENABLED'} onChange={()=>toggleSegment(lt)}  />
                            {lt?.pre_cache_status === 'IN PROCESS' && "preparing..."}
                            {(lt?.pre_cache_status === 'ENABLED' || lt?.pre_cache_status === 'IN PROCESS') && (
                                <>
                                
                                    <PrimaryButton label={"Refresh"}  onClick={() => getViewDetails(lt.id, true)}   />
                                    <SecondaryButton label={"Recreate Data"}  onClick={() => confirmRefreshSegment(lt.id,lt?.pre_cache_status === 'IN PROCESS' )} />
                                </>
                            )}
                        </Flex>
                    </Td>
                </Tr>
            ))}
            <ExportCustomAudienceModal segmentId={selectedSegmentId} onClose={onClose} isOpen={isOpen} />

            {/* View Details Modal */}
            <Modal isOpen={isDetailOpen} onClose={onDetailClose} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Segment Details</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {!_.isEmpty(segmentDetails) ? (
                            <Flex direction="column" gap={4} p={2}>
                                <Box>
                                    <Text fontWeight="bold">Name:</Text>
                                    <Text>{segmentDetails?.name}</Text>
                                </Box>
                                <Divider />
                                <Box>
                                    <Text fontWeight="bold">Description:</Text>
                                    <Text>{segmentDetails?.description || 'N/A'}</Text>
                                </Box>
                                <Divider />
                                <Box>
                                    <Text fontWeight="bold">Counts:</Text>
                                    <Text>Customers: {segmentDetails?.aggregates?.count_customers || 0}</Text>
                                    <Text>Emails: {segmentDetails?.aggregates?.count_email || 0}</Text>
                                    <Text>Phones: {segmentDetails?.aggregates?.count_phone || 0}</Text>
                                </Box>
                                <Divider />
                                <Box>
                                    <Text fontWeight="bold">Created At:</Text>
                                    <Text>{moment.utc(segmentDetails?.createdAt).local().format("LL LT")}</Text>
                                </Box>
                                <Divider />
                                <Box>
                                    { 
                                    !!segmentDetails?.params?.raw_query  &&

<>
                                        <Text fontWeight="bold">Raw Query:</Text>
                                    <Code whiteSpace="pre-wrap" p={2} bg="gray.100" borderRadius="md">
                                        {atob(segmentDetails?.params?.raw_query || '')}
                                    </Code>
</>
                                    }
                                </Box>
                            </Flex>
                        ) : (
                            
                            <Text>Loading...</Text>
                        )}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Fragment>
    );
}
