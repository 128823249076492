import { Flex, useColorModeValue, IconButton, Text, HStack, Menu, MenuButton, Avatar, VStack, Box, MenuList, MenuItem, MenuDivider, Icon, Image } from "@chakra-ui/react";
import { FiMenu, FiBell, FiChevronDown } from "react-icons/fi"
import { logout } from "../Services/AuthService";
import { useDispatch } from "react-redux";
import OutlinedButton from "../components/themeComponents/OutlinedButton";
import { FaWallet } from "react-icons/fa";
import store from "../store/store";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/themeComponents/BackButton";
const MobileNav = ({ onOpen, ...rest }) => {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.300', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'flex-start' }}
      position={'sticky'}
      top={0}
      zIndex={99}
      {...rest}>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />


      <HStack spacing={{ base: '0', md: '6' }} justifyContent={'space-between'} px={6} w={'100%'}>
        <Box display={'flex'} alignItems={'center'}>
          {
            window.location.pathname !== '' && window.location.pathname !== '/' && 

          <BackButton ></BackButton>
          }
          <Image src={rest?.userData?.profilePic} width={30} rounded={5} mr={2}></Image>
          <Text fontWeight={'bold'} color={'primary.main'} pos={'relative'} top={'2px'} fontSize={'lg'}>Welcome Back, {rest.userData.fullName}</Text>
        </Box>
        {/* <IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} /> */}
        <OutlinedButton onClick={()=>navigate('/wallet')}  label={'Wallet'} colorScheme={'primary.main'} size={'sm'} leftIcon={<Icon as={FaWallet} color="primary.main"></Icon>}></OutlinedButton>
      </HStack>
    </Flex>
  )
}
export default MobileNav;