import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    FormControl,
    FormLabel,
    Input,
    Textarea,
    Button,
    VStack,
    Box,
    Text,
    HStack,
    Divider,
    FormErrorMessage,
    Select,
    useDisclosure
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import MobilePreview from '../Campaigns/components/MobilePreview';
import { postSmsTemplate, updateSmsTemplate, getSmsGatways, getSmsChannelData, getSmsTemplateData } from '../../Services/api';
import Confirmation from '../../components/themeComponents/Confirmation';
import { showSwal } from '../../_helpers/helpers';
import Swal from 'sweetalert2';
import { useLocation, useParams } from 'react-router-dom';
import OutlinedButton from '../../components/themeComponents/OutlinedButton';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from 'react-query';
import ShortenUrlModal from '../../components/ShortenUrlModal';

export default function SmsTemplate({onClose, uuid, fromALlBanks,refetch, orgId}) {
    const navigate = useNavigate()
    const location = useLocation()
    let {templateUuid} = useParams()

    const [isShortenUrlModalOpen, setIsShortenUrlModalOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const orgDetails=localStorage.getItem("selectedOrg")
    const parsedOrgDetails = JSON.parse(orgDetails);
    const orgToken = parsedOrgDetails.token; 
    // console.log(orgToken);
    const gatewayQuery = useQuery(['gatways'], {
        queryFn: async () => {
            let gatewayData = await getSmsGatways(orgId || orgToken);
            console.log(gatewayData)
            // formik.setFieldValue("provider",gatewayData?.data.gatewayList[0].gatewayUuid)
            return gatewayData.data;
        },
        enabled: true
    });
    const initialValues = {
        templateName: '',
        templateDescription: '',
        message: '',
        approvedTemplateId:"",
        provider: ""
    };

    const validationSchema = Yup.object().shape({
        templateName: Yup.string().required('Template Name is required'),
        // templateDescription: Yup.string().required('Description is required'),
        message: Yup.string().required('Message is required'),
        selectedGatewayUuid: Yup.string().required('Provider is required'),
        // approvedTemplateId: Yup.string().required('Approved Template Id is required'),
    });

   
    const smsTemplateDetails = useQuery(['details'], {
        queryFn: async () => {
            let templateDetails = await getSmsTemplateData(templateUuid);
            
            formik.setValues(templateDetails.data)
            formik.setFieldValue("selectedGatewayUuid", templateDetails?.data?.selectedGatewayUuid)
            return templateDetails.data;
        },
        enabled: true
    });
    const toast = useToast();
    const handleSubmit = async (values) => {
        try {
            let saveResponse;
    
            if (!location?.state?.uuid && !templateUuid) {
                // Create new template
                saveResponse = await postSmsTemplate(values, orgId);
                if (saveResponse) {
                    setShowAlert(true);
                    if (fromALlBanks) {
                        refetch();
                        onClose();
                    }
                }
            } else {
                // Update existing template
                saveResponse = await updateSmsTemplate(values, location?.state?.uuid || templateUuid, orgId || orgToken);
                if (saveResponse) {
                    await Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Template updated successfully!',
                        allowOutsideClick: false, // Prevents accidental closing
                        allowEscapeKey: false, // Prevents closing with Esc key
                        confirmButtonText: 'OK'
                    });
                }
            }
    
            // Ensure refetch and modal close after a successful response
            if (saveResponse && fromALlBanks) {
                refetch();
                onClose();
            }
        } catch (error) {
            console.error(error);
            toast({
                title: 'Oops',
                description: 'Something went wrong while saving the template. Please try again.',
                status: 'error',
            });
    
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'An error occurred while saving the template.',
                confirmButtonText: 'OK'
            });
        } finally {
            formik.setSubmitting(false);
        }
    };
    
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });
    useEffect(() => {
        gatewayQuery.refetch()
        if(!!templateUuid){
            smsTemplateDetails.refetch();
        }
        

    }, [])
    return (
        <Box px={'4vw'} >
            <Text fontSize={'xl'} >Create Template</Text>
            <Text>SMS template</Text>
            <form onSubmit={formik.handleSubmit}>
                <VStack mt={'5vw'} maxW={'50vw'} p={'1.5vw'} spacing={4}>
                    <FormControl isInvalid={formik.errors.templateName && formik.touched.templateName}>
                        <FormLabel htmlFor="templateName">Template Name </FormLabel>
                        <Input bg={"#EEEEEE"}
                            borderRadius={'16px'}
                            id="templateName"
                            name="templateName"
                            value={formik.values.templateName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormErrorMessage>{formik.errors.templateName}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={formik.errors.approvedTemplateId && formik.touched.approvedTemplateId}>
                        <FormLabel htmlFor="approvedTemplateId">Approved Template Id</FormLabel>
                        <Input bg={"#EEEEEE"}
                            borderRadius={'16px'}
                            id="approvedTemplateId"
                            name="approvedTemplateId"
                            value={formik.values.approvedTemplateId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormErrorMessage>{formik.errors.approvedTemplateId}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={formik.errors.templateDescription && formik.touched.templateDescription}>
                        <FormLabel htmlFor="templateDescription">Description</FormLabel>
                        <Textarea
                            bg={"#EEEEEE"}
                            borderRadius={'16px'}
                            id="templateDescription"
                            name="templateDescription"
                            value={formik.values.templateDescription}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <FormErrorMessage>{formik.errors.templateDescription}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={formik.errors.message && formik.touched.message}>
                        <FormLabel htmlFor="message">Message</FormLabel>
                        <Textarea
                            bg={"#EEEEEE"}
                            borderRadius={'16px'}
                            id="message"
                            name="message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        <Text textDecoration={'underline'} cursor={'pointer'} onClick={()=>setIsShortenUrlModalOpen(true)}  >Shorten Url</Text>
                        <FormErrorMessage>{formik.errors.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={formik.errors.selectedGatewayUuid && formik.touched.selectedGatewayUuid}>
                        <FormLabel htmlFor="selectedGatewayUuid">Provider</FormLabel>
                        <Select
                            bg={"#EEEEEE"}
                            borderRadius={'16px'}
                            id="selectedGatewayUuid"
                            name="selectedGatewayUuid"
                            value={formik.values.selectedGatewayUuid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            {
                                !!gatewayQuery.data && gatewayQuery.data?.gatewayList?.map((list) => (
                                    <option value={list.gatewayUuid} >
                                        {list.gatewayName}
                                    </option>
                                ))
                            }

                        </Select>
                        <FormErrorMessage>{formik.errors.selectedGatewayUuid}</FormErrorMessage>
                    </FormControl>
                    <HStack alignSelf={'baseline'}>
                        <PrimaryButton alignSelf={'baseline'} label={location?.state?.uuid ? 'Update' : 'Save'} type="submit"></PrimaryButton>
                        <OutlinedButton alignSelf={'baseline'} label={'Cancel'} type="button" onClick={() => navigate('/sms/list')}></OutlinedButton>
                    </HStack>
                </VStack>
            </form>
            <Box position={'absolute'} top={'9vw'} right={'10vw'} >
                <MobilePreview channel={'sms'} message={formik.values.message} />
            </Box>
            {
                !fromALlBanks &&
                <Confirmation isOpen={showAlert} title={'Template Created'} text="Want to create another template?" confirmButtonText={'Yes'} cancelButtonText={'Go to overview'} onConfirm={() => setShowAlert(false)} onCancel={() => { navigate('/sms/list'); setShowAlert(false) }}></Confirmation>
            }
        <ShortenUrlModal isOpen={isShortenUrlModalOpen} mainFormik={formik} onClose={()=>setIsShortenUrlModalOpen(false)}></ShortenUrlModal>
        </Box>
    );
};
