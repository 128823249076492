import React from 'react'
import SmsTemplate from '../SMS/SmsTemplate'
import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'

export default function CreateOrEditSmsTemplateModal({ isOpen, onClose, orgId, refetch }) {
    return (
        <>
            <Box  >

                <Modal isOpen={isOpen} size={'60%'} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>

                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pt={0}></ModalBody>
                        <SmsTemplate onClose={onClose} orgId={orgId} refetch={refetch} fromALlBanks={true} />
                    </ModalContent>
                </Modal>
            </Box>
        </>
    )
}
