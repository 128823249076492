import { Text, Box, Flex, SimpleGrid, Card, CardBody, Stack, Heading, Divider, CardFooter, ButtonGroup, Button, Image } from '@chakra-ui/react'
import React, { Fragment } from 'react'
import OutlinedButton from '../../components/themeComponents/OutlinedButton'
import { onsiteTemplatesLayout } from './NudgeTemplates'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/themeComponents/PrimaryButton'
import store from '../../store/store'


export default function NudgeLayout() {
    let localStorageData = localStorage.getItem("token") || '{}';
    let tokenData = JSON.parse(localStorageData);
    // let decodedToken = extractUserDataFromJWT(JSON.parse(token) )
    let selectedOrg = localStorage.getItem("selectedOrg");
    // Ensure the token is URL-encoded for safe inclusion in the query string
    //const encodedToken = encodeURIComponent(bearerToken);
    const orgToken = store.getState().auth?.selectedOrg?.token;
    let path = `/onsitepush/#/template`
    path = encodeURIComponent(path);
    const navigate = useNavigate();
    return (
        <Fragment>
            <Box w={'100%'} h={'100%'} overflow={'hidden'}>
                <Flex justifyContent={'space-between'}>
                    <Text as={'b'} fontSize={'2xl'}>Select a layout to Continue</Text>
                </Flex>
                <SimpleGrid templateColumns={'repeat(auto-fill, minmax(300px, 1fr))'} gap={3} mt={3}>
                    {
                        onsiteTemplatesLayout.map(temp => {

                            return (
                                <Card key={temp.uuid}>
                                    <CardBody>
                                        <Image
                                            src={temp.imgUrl}
                                            height="200px"
                                            width="100%"
                                            objectFit="cover"
                                            borderRadius="md"
                                        />
                                        <Stack mt={'6'} spacing={'3'}>
                                            <Heading size={'md'}>{temp.title}</Heading>
                                            <Text>{temp.description}</Text>
                                        </Stack>
                                    </CardBody>
                                    <Divider></Divider>
                                    <CardFooter>
                                        <ButtonGroup spacing={2}>
                                            <PrimaryButton label={'Select'} onClick={() => navigate(temp.uuid)}></PrimaryButton>
                                        </ButtonGroup>
                                    </CardFooter>
                                </Card>
                            )

                        })
                    }
                </SimpleGrid>
                {/* <iframe height={'100%'} width={'100%'} src={`${process.env.REACT_APP_IFRAME_AUTO_LOGIN_ENDPOINT}/onsitepush/?orgToken=${orgToken}&token=${tokenData.jwt}&path=${path}`} ></iframe> */}
            </Box>
        </Fragment>
    )
}
