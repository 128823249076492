// smsProviderMapping.js

import TrustSignal from "./SmsFroms/TrustSignal";
import Kaleyra from "./SmsFroms/Kalyera";
import Telnyx from "./SmsFroms/Telnyx";
import RouteMobile from "./SmsFroms/RouteMobile";
import Sinch from "./SmsFroms/Sinch";
import Equitas from "./SmsFroms/Equitas";
import SBI from "./SmsFroms/SBI";
const SmsProviderMapping = {
  TrustSignal: TrustSignal,
  RouteMobile: RouteMobile, 
  Kaleyra: Kaleyra,
  Telnyx: Telnyx,
  Sinch: Sinch,
  Equitas: Equitas,
  SBI: SBI,
};

export default SmsProviderMapping;
