import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  Flex,
  FormErrorMessage,
  Text,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { selectProvidersValue } from "../../_helpers/data_helper";
import componentMapping from "./ComponentMapping";
import { sendWhatsAppChannel, getChanneldata } from "../../Services/api";
import { useSelector } from "react-redux";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import { whatsappchannelSchema } from "../../_helpers/validationSchema";
import * as _ from "lodash";

const Whatsapp = ({onclose, refreshList}) => {
  const orgToken = useSelector((state) => state.auth.selectedOrg?.token);
  const toast = useToast();
  const formikRef = useRef(null);

  const initialData = {
    Kaleyra: { name: "", wabaId: "", auth: "", account: "", businessPhone: "", provider: "", remarks: "" },
    Routemobile: { name: "", businessPhone: "", provider: "", remarks: "", account: "", auth: "" },
    Gupshup: { name: "", businessPhone: "", provider: "", hsmAccount: "", hsmPassword: "", twoWayAccount: "", twoWayPassword: "", remarks: "" },
    Sinch: { name: "", businessPhone: "", provider: "", account: "", auth: "", remarks: "" },
    Trustsignal: { name: "", businessPhone: "", provider: "", account: "", auth: "", org_id: orgToken, org_token: orgToken },
  };

  const [selected, setSelected] = useState(selectProvidersValue[0].value);
  const [providerData, setProviderData] = useState({});
  const [loading, setLoading] = useState(false);
  const [validationSchema, setValidationSchema] = useState(whatsappchannelSchema[selected]);
  const [initialValues, setInitialValues] = useState(initialData[selected]);

  const SelectedChannelComponent = componentMapping[_.capitalize(selected)];

  const fetchChannelsData = async () => {
    if (!orgToken) return;
    try {
      const data = await getChanneldata(orgToken);
      const provider = data?.data?.data?.response?.provider || selectProvidersValue[0].value;
      setSelected(provider);
      if (formikRef.current) {
        formikRef.current.setValues({ ...initialData[provider], ...data.data.data.response, provider });
      }
    } catch (error) {
      console.error("Error while fetching Channel Data", error);
    }
  };

  useEffect(() => {
    fetchChannelsData();
  }, [orgToken]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues({ ...initialData[selected], provider: selected });
    }
    setValidationSchema(whatsappchannelSchema[selected]);
  }, [selected]);

  const registerWhatsappChannel = async (data) => {
    try {
      setLoading(true);
      data.provider = data.provider.toUpperCase();
      await sendWhatsAppChannel(data);
      onclose()
      refreshList()
      toast({ title: "Success", description: "WhatsApp Credentials Saved Successfully.", status: "success" });
    } catch (error) {
      console.error("Error while saving WhatsApp Channel", error);
      toast({ title: "Oops!", description: "Error in saving credentials.", status: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        registerWhatsappChannel({ ...values, ...providerData });
        actions.setSubmitting(false);
      }}
      enableReinitialize={true}
    >
      {(formikProps) => (
        <Box bg="#fff" p={4} mx="auto" maxW="90%" borderRadius="md">
          <Form>
            <Flex direction={{ base: "column", md: "row" }} gap={6}>
              {/* Left Section */}
              <Box
                bg="#fff"
                mt="1rem"
                maxW={{ base: "100%", md: "40vw" }}
                p="2rem"
                width="100%"
                borderRadius="md"
                boxShadow="sm"
              >
               
                <FormControl mb={4} isInvalid={formikProps.errors.provider && formikProps.touched.provider}>
                  <FormLabel htmlFor="provider">Select Provider</FormLabel>
                  <Select
                    id="provider"
                    name="provider"
                    value={selected}
                    onChange={(e) => {
                      setSelected(e.target.value);
                      formikProps.setFieldValue("provider", e.target.value);
                    }}
                    mt={2}
                  >
                    {selectProvidersValue.map((provider) => (
                      <option key={provider.key} value={provider.value}>{provider.value}</option>
                    ))}
                  </Select>
                  <FormErrorMessage>{formikProps.errors.provider}</FormErrorMessage>
                </FormControl>
                
                <FormControl mb={4} isInvalid={formikProps.errors.name && formikProps.touched.name}>
                  <FormLabel htmlFor="name">Name</FormLabel>
                  <Field as={Input} id="name" name="name" mt={2} />
                  <FormErrorMessage>{formikProps.errors.name}</FormErrorMessage>
                </FormControl>

                
                <FormControl mb={4} isInvalid={formikProps.errors.businessPhone && formikProps.touched.businessPhone}>
                  <FormLabel htmlFor="businessPhone">Business Phone</FormLabel>
                  <Field as={Input} id="businessPhone" name="businessPhone" mt={2} />
                  <FormErrorMessage>{formikProps.errors.businessPhone}</FormErrorMessage>
                </FormControl>


                
                {SelectedChannelComponent && <SelectedChannelComponent formikProps={formikProps} setProviderData={setProviderData} />}
              </Box>

              
              <Box mt={{ base: 4, md: 8 }} width="100%">
              
                <FormControl mb={4} isInvalid={formikProps.errors.remarks && formikProps.touched.remarks}>
                  <FormLabel htmlFor="remarks">Remarks</FormLabel>
                  <Textarea
                    id="remarks"
                    name="remarks"
                    value={formikProps.values.remarks}
                    onChange={formikProps.handleChange}
                    onBlur={formikProps.handleBlur}
                    placeholder="Enter your remarks"
                    height={{ base: "25vh", md: "35vh" }}
                    width="100%"
                    borderRadius="md"
                    resize="none"
                    border="1px solid #CBD5E0"
                    _focus={{ border: "1px solid #3182CE" }}
                  />
                  <FormErrorMessage>{formikProps.errors.remarks}</FormErrorMessage>
                </FormControl>

                
                <PrimaryButton label="Save" type="submit" isLoading={loading} size="md" width="100%" />
              </Box>
            </Flex>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default Whatsapp;
