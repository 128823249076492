import moment from "moment"
import { onsitePushTemplates } from "../Pages/Nudge/NudgeTemplates"
export const register = {
    email: '', phone: '', full_name: '', password: '', confirmPassword: '', org_domain: ''
}

export const login = {
    email: '', password: ''
}

export const broadcastCampaign = {
    selectedChannel: 'email',
    campaignName: 'Email - ' + moment(new Date()).format("lll"),
    campaignType: 'broadcast',
    broadcastType: 'BROADCAST_V2',
    broadcast: 'BROADCAST_V2',
    targetedAudience: {
        includes: {
            segment: [],
            CSV: []
        },
        excludes: {}
    },
    sendToContactList: true,
    "scheduleStatus": "NOW",
        "scheduleAt": new Date(new Date().getTime() + 5*60*1000),
        "repeatStopDateTime": new Date(new Date().getTime() + 4*24*60*60*1000), //after 4 days
        "repeatAfterDays": 1,
}
export const targetGroupCampaign = {
    selectedChannel: 'email',
    orgList:[],
    reachPercentage : 100,
    campaignName: 'Email - ' + moment(new Date()).format("lll"),
    broadcastType: 'ALL_BANKS',
    targetedAudience: {
        includes: [],
        excludes: []
    }
    
}
export const saveEmailTemplate = {
    description: 'Email Template - ' + moment(new Date()).format("lll"),
    type: "",
    subject: "",
    body: "",
    blockids: [],
    attachments: [],
    stripoData: {},
    stripoBody : "",
    preheader: ""
}

export const getOnsiteFormData = () => {
    return {
        assetsHost: process.env.REACT_APP_API_ENDPOINT, 
        uuid: "",
        name: "",
        description: "",
        layoutId: "mkt_onsite_temp001",
        markup: {
            raw: "",
            rendered: ""
        },
        settings: {
            urlSettings: {
                redirect: {
                    enabled: false,
                    url: "",
                    target: "_blank"
                },
                urlTargeting: {
                    choice: "ALL",    // ALL/SPECIFIC
                    globalOperator: "OR", // OR/AND
                    criteria: [{operator: 'equals', value: ''}]
                },
            },
            device: {
                screen: {
                    mobile: {
                        show: true
                    },
                    tablet: {
                        show: true
                    },
                    desktop: {
                        show: true
                    }
                }
            },
            ttl: {
                value: 30,
                timeunit: 'MINUTES',
                status: true
            },
            locationAndDeviceTargeting: {
                status: false,
                globalOperator: "AND", // OR/AND
                criteria: [{criterion: '', operator: '', value: ''}]
            },
            emailServiceProviders: [],
            recipientOption: "ALL",
            selectedSegmentId: "",
            showToMappedUsers: true,
        },
        template: {
            props: onsitePushTemplates['mkt_onsite_temp001']
        }
    }
}