import React, { useEffect, useState } from "react";
import { getChanneldata, updateWhatsappSetting } from "../../Services/api";
import { useSelector } from "react-redux";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Icon,
  Badge,
  Spinner,
  Flex,
  useToast,
  RadioGroup,
  Radio
} from "@chakra-ui/react";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import { BiTrash } from "react-icons/bi";
import Whatsapp from "./Whatsapp";
import axiosInstance from "../../Services/axiosInstance";
import Swal from "sweetalert2";




export default function WhatsappSettingList() {
  const orgToken = useSelector((state) => state.auth.selectedOrg?.token);
  const [channelData, setChannelData] = useState([]);
  const [defaultChannel, setDefaultChannel] = useState("");
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
 
  const deleteWhatsappSetting = async (uuid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action will permanently delete the WhatsApp setting!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let url = `/whatsapp/setting/${uuid}`;
          let response = await axiosInstance.delete(url);
          
          if (response) {
            toast({
              title: "Success",
              description: "WhatsApp settings deleted successfully.",
              status: "success",
            });
            fetchChannelsData();
          }
        } catch (error) {
          console.error("Error deleting WhatsApp setting:", error);
          toast({
            title: "Oops!",
            description: "Something went wrong while deleting.",
            status: "error",
          });
        }
      }
    });
  };
   const handleRadioChange = async (businessPhone) => {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: `Do you want to set this setting as default?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update it!',
        cancelButtonText: 'No, cancel!',
      });
  
      if (result.isConfirmed) {
        try {
          await updateWhatsappSetting(businessPhone);
          fetchChannelsData();
          Swal.fire('Updated!', 'The whatsapp channel has been updated.', 'success');
        } catch (error) {
          console.error("Error updating whatsapp channel:", error);
          Swal.fire('Error!', 'There was an error updating the whatsapp channel.', 'error');
        } 
      }
    };
  const fetchChannelsData = async () => {
    if (!orgToken) return;
    setLoading(true);
    try {
      const response = await getChanneldata(orgToken);
      if (response?.data?.status === "success") {
        
        setChannelData(response?.data?.data?.response || []);
      }else{
        toast({
          title: "Error",
          description: "Failed to fetch WhatsApp settings.",
          status: "error",
        });
      }
    } catch (error) {
      console.error("Error while fetching Channel Data", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChannelsData();
  }, [orgToken]);

  return (
    <div>
      <PrimaryButton label={"Add Provider +"} float={"right"} onClick={onOpen} mb={4} />

      {loading ? (
        <Flex justifyContent="center" alignItems="center" height="200px">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Table variant="simple" bg={'#fff'}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Provider</Th>
              <Th>Default Provider</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {channelData.length > 0 ? (
              channelData.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.name || item?.provider}</Td>
                  <Td><Badge>{item.provider}</Badge></Td>
                  <Td>
                    
                  <RadioGroup>
                  <Radio 
                    
                    isChecked={item.isDefault} 
                    onChange={() => handleRadioChange(item?.business_phone || item?.businessPhone)} 
                  />
                </RadioGroup>
                  </Td>
                  <Td><Icon onClick={()=>deleteWhatsappSetting(item?.uuid)} as={BiTrash} cursor="pointer" /></Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="3" textAlign="center">
                  No data available
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      )}

      
      <Modal size={"3xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Whatsapp refreshList={fetchChannelsData} onclose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
