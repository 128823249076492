import { Button, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { Fragment, createRef, forwardRef, useCallback, useImperativeHandle, useRef } from 'react'
import CodeMirror from "@uiw/react-codemirror"
import { html } from "@codemirror/lang-html"
import { BackgroundForm, CancelButton, ConfirmButton, CouponForm, DescriptionForm, Ribbon, Slides, SubtitleForm, TitleForm } from './NudgeTemplateSettingFields'
import _ from "lodash"
import useStateRef from 'react-usestateref'
import EmojiPicker from 'emoji-picker-react'
import Slider from 'react-slick'
const VisualSettings = forwardRef(({ templateProps, markup, updateMarkup, updateTemplateProps }, ref) => {
    const onCodeChange = useCallback((val, viewUpdate) => {
        //formikInstance.setFieldValue("markup.raw", val)
        updateMarkup(val);
    })
    const formRefs = useRef({});
    const [props, setProps, propsRef] = useStateRef(templateProps)
    Object.keys(templateProps).map(ref => {
        if (ref !== 'uuid') {
            formRefs[ref] = createRef()
        }

    })
    const handleSubmit = (values, formName) => {
        console.log(`Form "${formName}" submitted with values:`, values);
        const tempProps = { ...propsRef.current };
        tempProps[formName] = values;
        setProps(tempProps);
        updateTemplateProps(propsRef.current)
    };
    useImperativeHandle(ref, () => ({
        collectAllFormDataAndSubmit: () => {
            Object.keys(templateProps).forEach((formName, index) => {
                if (formName !== 'uuid') {
                    
                    if (!_.isEmpty(formRefs[formName].current)) {
                        formRefs[formName].current.submitForm();

                    }
                }

            })
        }
    }))
        
    

    return (
        <Fragment>
            <Tabs display={'flex'} flexDirection={'column'} height={'100%'}>
                <TabList>
                    <Tab>Visual Settings</Tab>
                    <Tab>Code Editor</Tab>
                </TabList>
                <TabPanels bg={'white'} overflow={'auto'} height={'100%'}>
                    <TabPanel bg={'white'}>
                        {
                            !!templateProps.title && <TitleForm data={propsRef.current.title} formName={"title"} onSubmit={handleSubmit} ref={formRefs['title']}></TitleForm>
                        }
                        {
                            !!templateProps.subTitle && <SubtitleForm data={propsRef.current.subTitle} formName="subTitle" onSubmit={handleSubmit} ref={formRefs['subTitle']}></SubtitleForm>
                        }
                        {
                            !!templateProps.description && <DescriptionForm data={propsRef.current.description} formName="description" onSubmit={handleSubmit} ref={formRefs['description']}></DescriptionForm>
                        }
                        {
                            !!templateProps.background && <BackgroundForm data={propsRef.current.background} formName="background" onSubmit={handleSubmit} ref={formRefs['background']}></BackgroundForm>
                        }
                        {
                            !!templateProps.confirmButton && <ConfirmButton data={propsRef.current.confirmButton} formName="confirmButton" onSubmit={handleSubmit} ref={formRefs['confirmButton']}></ConfirmButton>
                        }
                        {
                            !!templateProps.cancelButton && <CancelButton data={propsRef.current.cancelButton} formName="cancelButton" onSubmit={handleSubmit} ref={formRefs['cancelButton']}></CancelButton>
                        }
                        {
                            !!templateProps.coupon && <CouponForm data={propsRef.current.coupon} formName="coupon" onSubmit={handleSubmit} ref={formRefs['coupon']}></CouponForm>
                        }
                        {
                            !!templateProps.ribbon && <Ribbon data={propsRef.current.ribbon} formName="ribbon" onSubmit={handleSubmit} ref={formRefs['ribbon']}></Ribbon>
                        }
                        
                        {
                            !!templateProps.sliderSettings && <Slides data={propsRef.current.sliderSettings} formName="sliderSettings" onSubmit={handleSubmit} ref={formRefs['sliderSettings']}></Slides>
                        }
                    </TabPanel>
                    <TabPanel>
                        <CodeMirror value={markup} extensions={[html()]} height='600px' theme={'dark'} onChange={onCodeChange}></CodeMirror>
                    </TabPanel>

                </TabPanels>
            </Tabs>
        </Fragment>
    )
})
export default VisualSettings;