import React from 'react';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { FormControl, FormLabel, Input, VStack, FormErrorMessage } from '@chakra-ui/react';

const SBI = ({ setGatewayData, index }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  // Handle field changes
  const handleChange = (field, event) => {
    setFieldValue(`credentials[${index}].${field}`, event.target.value);
    setGatewayData(`credentials[${index}]`, { ...values.credentials[index], [field]: event.target.value });
  };

  return (
    <VStack spacing={4} alignItems="flex-start">
      <FormControl id={`url-${index}`} isInvalid={errors.credentials?.[index]?.url && touched.credentials?.[index]?.url}>
        <FormLabel>Sender ID</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].url`}
          value={values.credentials[index]?.url || ''}
          onChange={(e) => handleChange('url', e)}
          placeholder="URL"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.url}</FormErrorMessage>
      </FormControl>
      <FormControl id={`senderId-${index}`} isInvalid={errors.credentials?.[index]?.senderId && touched.credentials?.[index]?.senderId}>
        <FormLabel>Sender ID</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].senderId`}
          value={values.credentials[index]?.senderId || ''}
          onChange={(e) => handleChange('senderId', e)}
          placeholder="Sender ID"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.senderId}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default SBI;
