import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Container,
} from '@chakra-ui/react'
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi'
import { IconType } from 'react-icons'
import SidebarContent from './Sidebar';
import MobileNav from './MobileNav';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DateRangeSelection from '../components/DateRangeSelection';
import { QueryClientProvider } from 'react-query';
import BackButton from '../components/themeComponents/BackButton';
const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userDetails = useSelector(state => state.auth.user_data);
  return (
    <Box minH="100vh">
      
      
      <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} userData={userDetails} />
      <Box ml={{ base: 0, md: 60 }} height={'calc(100vh - 5rem)'} p={'1rem'}>
        <Outlet />

      </Box>
    </Box>
  )
}

export default Header