import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
  Flex,
} from '@chakra-ui/react';
import CsvDropzone from './CsvDropzone';
import { uploadCsvFile } from '../../Services/api';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { FiUpload } from 'react-icons/fi';

export default function UploadFile({ orgId , setUploadedFileUrl, uploadedFileUrl}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [csvContent, setCsvContent] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const uploadCsvToApi = async (data) => {
    setIsUploading(true);
    setSuccessMessage(null);
    setError(null);

    try {

      const formData = new FormData()


      formData.append('file', data.target.files[0])

      const response = await uploadCsvFile(orgId, "powerbi", formData)
      
      if (response.status !== 200) {
        throw new Error(`Failed to upload CSV: ${response.statusText}`);
      }

      if(!!response.data.url){
        setUploadedFileUrl(response.data.url)
      }

      setSuccessMessage('CSV uploaded successfully!');

    } catch (err) {
      setError(err.message || 'An error occurred while uploading the CSV.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileUpload = (data) => {

    console.log(data);
    setError(null);


    const rows = data.csvContent.split('\n').filter((row) => row.trim() !== '');
    if (rows.length < 2) {
      setError('The CSV must contain a header and at least one data row.');
      return;
    }

    const header = rows[0].split(',');
    const idColumnIndex = header.findIndex(
      (col) => col.trim().toLowerCase() === 'customer_id' || col.trim().toLowerCase() === 'customer id'
    );

    if (idColumnIndex === -1) {
      setError('The CSV must contain a header with "customer_id" or "customer id".');
      return;
    }

    const invalidRows = [];
    for (let i = 1; i < rows.length; i++) {
      const columns = rows[i].split(',');
      const idValue = columns[idColumnIndex]?.trim();


      if (!idValue/* || isNaN(parseInt(idValue, 10))*/) {
        invalidRows.push(i + 1);
      }
    }

    if (invalidRows.length > 0) {
      setError(
        `Invalid data in the following rows: ${invalidRows.join(
          ', '
        )}.`
      );
      return;
    }


    setCsvContent(data.csvContent);
    setRowCount(rows.length - 1);
    onClose();
    uploadCsvToApi(data.data);
  };

  return (
    <>
    <Flex w={'100%'} justifyContent={'center'} >

      <PrimaryButton label={'Upload CSV'} rightIcon={<FiUpload/>}  onClick={onOpen}>
        
      </PrimaryButton>
    </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload CSV File</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CsvDropzone handleCsvChange={handleFileUpload} />
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Display error message */}
      {error && (
        <Alert status="error" mt={4}>
          <AlertIcon />
          <Box>
            <AlertTitle>Error!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Box>
        </Alert>
      )}

      {/* Display success message */}
      {successMessage && (
        <Alert status="success" mt={4}>
          <AlertIcon />
          <Box>
            <AlertTitle>Success!</AlertTitle>
            <AlertDescription>{successMessage}</AlertDescription>
          </Box>
        </Alert>
      )}

      {/* Show uploading spinner */}
      {isUploading && (
        <Box mt={4}>
          <Spinner size="lg" />
          <Text ml={2}>Uploading CSV...</Text>
        </Box>
      )}

      {/* Display the CSV row count */}
      {csvContent && !error && !isUploading && (
        <Box my={4} p={4} bg="gray.50" borderRadius="md" borderWidth="1px">
          <Text fontWeight="bold" mb={2}>
            CSV Row Count: {rowCount}
          </Text>
        </Box>
      )}
    </>
  );
}
