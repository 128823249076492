import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Input, FormControl, FormLabel, FormErrorMessage, HStack, Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import { TagsInput } from "react-tag-input-component";
import PrimaryButton from './themeComponents/PrimaryButton';
import { getShortenedUrl, sendTestWhatsapp, sendTestEmail, sendTestEmailMulti, sendTestSms } from '../Services/api';
import { useToast } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { template } from 'lodash';
import * as _ from 'lodash';
import { FormikProvider } from 'formik';
function SendTestModal({ isOpen, onClose, channel, uuid, orgToken, templateData, selectedSmtpUuid, formik, targetedAudience }) {
    const toast = useToast();
    const [email, setEmail] = useState("");
    const [phones, setPhones] = useState([]);
    const [error, setError] = useState(false);
    const [smptpType, setSmptpType] = useState("promotional")
    const [intersaction, setIntersaction] = useState([])
    const checkExisting = () => {
        return
    }

    const smptpTypes = [
        {
            key: "Promotional",
            value: "promotional"
        },
        {
            key: "Transactional",
            value: "transactional"
        }
    ]


    let sendTestActions = {
        "Email": async function () {
            if (!!formik?.values?.orgList?.length) {
                let preparedData = {}

                let preparedTempData = []
                formik.values.orgList.forEach(async (orgToken) => {
                    if (window?.location?.href?.includes("all-banks")) {

                        console.log(targetedAudience[orgToken])
                        preparedTempData.push({
                            orgToken: orgToken,
                            csv_url: !!targetedAudience[orgToken]?.csv?.length ? targetedAudience[orgToken]?.csv.map((csv) => csv?.data?.url)[0] : null,
                            segment_id: !!targetedAudience[orgToken]?.segment?.length ? targetedAudience[orgToken]?.segment.map((segment) => segment?.data?.id || segment?.id)[0] : null,
                            templateData: {
                                headerUuid: templateData[orgToken]?.header?.uuid || "",
                                bodyUuid: templateData[orgToken]?.body?.uuid || "",
                                footerUuid: templateData[orgToken]?.footer?.uuid || ""
                            },
                            email: email,
                            subject: formik.values.subjectLine,
                            selectedSmtpUuid: selectedSmtpUuid[orgToken],
                            keys: !!targetedAudience[orgToken]?.segment?.length ? targetedAudience[orgToken]?.segment.map((segment) => segment?.data?.aggregates?.keys || segment?.aggregates?.keys)[0] : null,
                        })
                    }
                });
                preparedData = {
                    // email: email,
                    // selectedSmtptype: smptpType,
                    // selectedTemplate: uuid,
                    // templateData : preparedTempData,
                    // subject: formik.values.subjectLine,
                    // selectedSmtpUuid: selectedSmtpUuid[orgToken],
                    // orgList: formik.values.orgList,
                    data: preparedTempData
                }
                let testEmail = await sendTestEmailMulti(preparedData, orgToken).catch((err) => toast({ title: 'Oops!', description: 'Something went wrong while sending test email', status: 'error' }
                )

                )

                if (!!testEmail?.status == 200 || testEmail?.data?.length) {
                    testEmail.data.forEach((dt) => {
                        toast({ title: dt.status, description: `Test email sent ${dt.status}`, status: dt.status != 'success' ? 'error' : 'success' })
                        onClose();
                    })

                }
            }
            else {
                let preparedData = {
                    email: email,
                    // selectedSmtptype: smptpType,
                    selectedTemplate: uuid,
                    // templateData : preparedTempData,
                    selectedSmtpUuid: selectedSmtpUuid,
                    broadcastType: 'BROADCAST_V2',
                    csv_url: !!formik?.values?.targetedAudience?.includes?.CSV?.length ? formik?.values?.targetedAudience?.includes?.CSV[0]?.data.url : null,
                    segment_id: !!formik?.values?.targetedAudience?.includes?.segment?.length ? formik?.values?.targetedAudience?.includes?.segment[0].id : null,
                    keys: !!formik?.values?.targetedAudience?.includes?.segment?.length ? formik?.values?.targetedAudience?.includes?.segment[0].aggregates?.keys : null
                }
                let testEmail = await sendTestEmail(preparedData, orgToken).catch((err) => toast({ title: 'Oops!', description: 'Something went wrong while sending test email', status: 'error' }))

                if (!!testEmail?.status == 200 || testEmail?.data?.status) {
                    toast({ title: testEmail.data.status, description: `Test email sent ${testEmail.data.status}`, status: testEmail.data.status != 'success' ? 'error' : 'success' })
                    onClose();
                }

            }

        },
        "Whatsapp": async function () {

            let numbers = []
            let mappedUserPhones = phones.map((num, i) => {
                return numbers[i] = { phone: num }
            })
            let data = []
            if (!!formik?.values?.orgList?.length) {
                formik.values.orgList.forEach((org) => {
                    data.push({
                        orgToken: org,
                        mappedUserPhones,
                        csv_url: !!targetedAudience[org]?.csv?.length ? targetedAudience[org]?.csv.map((csv) => csv?.data?.url)[0] : null,
                        segment_id: !!targetedAudience[org]?.segment?.length ? targetedAudience[org]?.segment.map((segment) => segment?.data?.id || segment?.id)[0] : null,
                        selectedTemp: uuid || templateData[org]?.uuid,
                        keys: !!targetedAudience[orgToken]?.segment?.length ? targetedAudience[orgToken]?.segment.map((segment) => segment?.data?.aggregates?.keys || segment?.aggregates?.keys)[0] : null,
                    })
                })
            }
            let preparedData = {
                data
            }
            if (!formik?.values?.orgList?.length) {
                preparedData = {
                    mappedUserPhones,
                    selectedTemp: uuid || templateData[orgToken]?.uuid,
                    csv_url: !!formik?.values?.targetedAudience?.includes?.CSV?.length ? formik?.values?.targetedAudience?.includes?.CSV[0]?.data?.url : null,
                    segment_id: !!formik?.values?.targetedAudience?.includes?.segment?.length ? formik?.values?.targetedAudience?.includes?.segment[0]?.id : null,
                    keys: !!formik?.values?.targetedAudience?.includes?.segment?.length ? formik?.values?.targetedAudience?.includes?.segment[0].aggregates?.keys : null
                }
            }

            let testWhatsapp = await sendTestWhatsapp(preparedData, orgToken).catch((err) => toast({ title: 'Oops!', description: 'Something went wrong while sending test whatsapp', status: 'error' }))
            if (!!testWhatsapp.status == 200 || testWhatsapp.data.status) {
                toast({ title: testWhatsapp.data.status, description: `${testWhatsapp?.data?.message}`, status: testWhatsapp.data.status != 'success' ? 'error' : 'success' })
                onClose();

            }
            if (Array.isArray(testWhatsapp?.data) && !!testWhatsapp?.data.length) {
                testWhatsapp?.data.forEach((dt) => {
                    toast({ title: dt.status, description: `${dt.status} - ${dt.status}`, status: dt.status != 'success' ? 'error' : 'success' })
                    onClose();
                })

            }
        },
        "Sms": async function () {
            let numbers = []
            let destination = phones.map((num, i) => {
                return numbers[i] = { phone: num }
            })
            let preparedTempData = []
            if (!!formik?.values?.orgList?.length) {
                let preparedData = {}
                // let preparedTempData = []
                formik.values.orgList.forEach(async (orgToken) => {
                    console.log(targetedAudience[orgToken])
                    if (window?.location?.href?.includes("all-banks")) {
                        preparedTempData.push({
                            orgToken: orgToken,
                            selectedTemp: uuid || templateData[orgToken]?.uuid,
                            destination,
                            approvedTemplateId: "",
                            csv_url: !!targetedAudience[orgToken]?.csv?.length ? targetedAudience[orgToken]?.csv.map((csv) => csv?.data?.url)[0] : null,
                            segment_id: !!targetedAudience[orgToken]?.segment?.length ? targetedAudience[orgToken]?.segment.map((segment) => segment?.data?.id || segment?.id)[0] : null,
                            keys: !!targetedAudience[orgToken]?.segment?.length ? targetedAudience[orgToken]?.segment.map((segment) => segment?.data?.aggregates?.keys || segment?.aggregates?.keys)[0] : null,
                        })
                    }
                });
            }
            let preparedData = {
                data: preparedTempData,
            }
            if (!formik?.values?.orgList?.length) {
                preparedData = {
                    selectedTemp: uuid || templateData[orgToken]?.uuid,
                    destination,
                    csv_url: !!formik?.values?.targetedAudience?.includes?.CSV?.length ? formik?.values?.targetedAudience?.includes?.CSV[0]?.data?.url : null,
                    segment_id: !!formik?.values?.targetedAudience?.includes?.segment?.length ? formik?.values?.targetedAudience?.includes?.segment[0]?.id : null,
                    keys: !!formik?.values?.targetedAudience?.includes?.segment?.length ? formik?.values?.targetedAudience?.includes?.segment[0].aggregates?.keys : null

                }
            }

            let testSms = await sendTestSms(preparedData, orgToken).catch((err) => toast({ title: 'Oops!', description: 'Something went wrong while sending test sms', status: 'error' }))

            if (!!testSms?.status == 200 || testSms?.data?.status) {
                toast({ title: testSms.data.status, description: ` ${testSms.data.message}`, status: testSms.data.status != 'success' ? 'error' : 'success' })
                onClose();

            }
            if (Array.isArray(testSms.data) && testSms.data.length) {
                testSms.data.forEach((test) => {
                    toast({ title: test.status, description: ` ${test.status}`, status: test.status != 'success' ? 'error' : 'success' })
                    onClose();
                })
            }
        }
    }
    const sendTestCommunication = () => {

        !error &&
            sendTestActions[_.capitalize(channel)]();
    }
    const validateTag = (value) => {
        let re = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
        if (re.test(value)) {
            setError(false)
            return re.test(value)
        } else {
            setError(true)
            return re.test(value)
        }
    }
    const validateEmail = (value) => {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value)) {
            setError(false)
            return re.test(value)
        } else {
            setError(true)
            return re.test(value)
        }
    }
    useEffect(() => {
        if (window?.location.href.includes("all-banks")) {
            
        
            let allKeys = Object.values(targetedAudience)
                .flatMap(org => org.segment.map(segment => segment?.aggregates?.keys?.map(k => k.label)));
        
            let keysIntersection = allKeys.length 
                ? allKeys.reduce((acc, keys) => _.intersection(acc, keys))
                : [];
        
            setIntersaction(keysIntersection);
            console.log(keysIntersection);
        }
        
    }, [])

    return (
        <Modal isOpen={isOpen} size={'xl'} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text>Send Test {channel}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody pt={0}>
                    <HStack spacing={4} w={'100%'} >
                        {
                            channel.toLowerCase() == 'email' &&
                            <>
                                <VStack w={'100%'} >
                                    <Text></Text>
                                    <Input value={email} placeholder='Enter email' onChange={(e) => setEmail(e.target.value)} onKeyUp={(e) => validateEmail(e.target.value)} />
                                    {/* <Select value={smptpType} onChange={(e)=>setSmptpType(e.target.value)} >
                                    {
                                        smptpTypes.map((e) => (
                                            <option value={e.value} >
                                                {e.key}
                                            </option>
                                        ))
                                        
                                        }
                                </Select> */}
                                </VStack>
                            </>
                        }
                        {
                            channel.toLowerCase() !== 'email' &&
                            <TagsInput
                                style={{ width: "100%" }}
                                value={phones}
                                onChange={setPhones}
                                name="phone"
                                placeHolder="Enter Phone(s)"
                                onExisting={checkExisting}
                                beforeAddValidate={validateTag}
                            />
                        }

                    </HStack>
                    {error && <Text size={'sm'} color={'red'} >Enter a valid {channel == 'email' ? "email" : "number"}</Text>}
                    {
                       !window.location.href.includes("all-banks") &&   !!formik?.values?.targetedAudience?.includes?.segment?.length && !!formik?.values?.targetedAudience?.includes?.segment[0]?.aggregates?.keys?.length && <Text m={'2px'}>
                            Sample data will be generated for the evaluation of placeholders for these keys:
                        </Text>
                    }
                    {

                      !window.location.href.includes("all-banks") &&  !!formik?.values?.targetedAudience?.includes?.segment?.length && !!formik?.values?.targetedAudience?.includes?.segment[0]?.aggregates?.keys?.length

                        &&
                        formik?.values?.targetedAudience?.includes?.segment[0]?.aggregates.keys.map((key) => (
                            <Tag m={'2px'} >
                                {key.label}
                            </Tag>

                        ))


                    }
                    {
                        !!intersaction?.length && <Text m={'2px'}>
                            Sample data will be generated for the evaluation of placeholders for these keys:
                        </Text>
                    }
                    {


                        !!intersaction?.length && intersaction?.map((key) => (
                            <Tag m={'2px'} >
                                
                                {key}
                            </Tag>

                        ))


                    }
                    <PrimaryButton label={'Send'} mt={2} float={'right'} onClick={sendTestCommunication} />
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default SendTestModal;
