import React, { useEffect } from 'react';
import { Button, Image } from '@chakra-ui/react';
import { loginWithFacebook } from '../../Services/api';
const FacebookLoginButton = () => {


  const handleFBLogin = async () => {

    let res = await loginWithFacebook();
    console.log(res)
    if (!!res.data.authUrl) {
      window.open(res.data.authUrl, "_self");
    }
  };

  return (
    <button class="logout-btn" style={{width:"fit-content"}} onClick={handleFBLogin}>
                                        <img src='/images/flows/white/meta.svg' height={'30px'} width={'30px'} />
                                        Login with Meta
                                    </button>

  );
};

export default FacebookLoginButton;
