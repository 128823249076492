import React, { useState, useEffect } from 'react';
import { 
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Input,
  HStack,
  FormLabel,
  Box,
  Spinner
} from '@chakra-ui/react';
import Select from '../../components/themeComponents/react-chakra-select';
import { prepareEmailProviderData, prepareOrgList, prepareWhatsappProviderData } from '../../_helpers/data_helper';
import { fetchEmailChannels, getChanneldata, getOrgList } from '../../Services/api';
import moment from "moment";
import * as _ from 'lodash';
import TableHead from '../Campaigns/components/TableHead';

export default function OrgAndConfig({ formik, selectedOrgList, setSelectedOrgList, selectedSmtp, setSelectedSmtp, emailConfiguration, setEmailConfiguration, location ,whatsappConfiguration, setWhatsappConfiguration,whatsappSetting, setWhatsappSetting}) {
  const [orgList, setOrgList] = useState([]);
  const [loadingOrgList, setLoadingOrgList] = useState(false);
  const [loadingConfig, setLoadingConfig] = useState(false);

  // Fetch organization list once on component mount
  useEffect(() => {
    const loadOrgList = async () => {
      setLoadingOrgList(true);
      try {
        const { data } = await getOrgList();
        setOrgList(data || []);
      } catch (err) {
        console.error('Error fetching organization list:', err);
      } finally {
        setLoadingOrgList(false);
      }
    };
    loadOrgList();
  }, []);

  // Update campaign name when channel is selected
  useEffect(() => {
    if (formik.values.selectedChannel) {
      formik.setFieldValue(
        'campaignName',
        `${_.capitalize(formik.values.selectedChannel)} - ${moment().format('lll')}`
      );
    }
  }, [formik.values.selectedChannel]);

  // Handle organization selection changes
  const handleOrgSelectionChange = async (selectedOptions) => {
    setSelectedOrgList(selectedOptions);
    const selectedTokens = selectedOptions.map((option) => option.value);
    formik.setFieldValue('orgList', selectedTokens);

    // Fetch email configurations for new selections
    const newTokens = selectedTokens.filter(
      (token) => !emailConfiguration.some((config) => config.orgToken === token)
    );

    if (newTokens.length > 0) {
      setLoadingConfig(true);
      try {
        if(formik.values.selectedChannel == 'email'){

          const configPromises = newTokens.map(async (token) => {
            const { data } = await fetchEmailChannels(token);
            
            if (data?.default_promotional_uuid) {
              updateSmtpSettings(data.default_promotional_uuid, token);
            }
            
            return {
              orgToken: token,
              emailSettings: data?.data || [],
              defaultPromotionalUuid: data?.default_promotional_uuid || null,
            };
          });
          
          const configs = await Promise.all(configPromises);
          setEmailConfiguration((prevConfig) => [...prevConfig, ...configs]);
        }
        if(formik.values.selectedChannel == 'whatsapp'){

          const configPromises = newTokens.map(async (token) => {
            const { data } = await getChanneldata(token);
            
            if (data?.data?.response) {
              updateWhatsappSettings( _.find(data?.data?.response,{isDefault: true})?.uuid , token);
            }
            
            return {
              orgToken: token,
              whatsappSettings: data?.data || [],
              // defaultPromotionalUuid: data?.default_promotional_uuid || null,
            };
          });
          
          const configs = await Promise.all(configPromises);
          setWhatsappConfiguration((prevConfig) => [...prevConfig, ...configs]);
         console.log(whatsappConfiguration)
        }
      } catch (error) {
        console.error('Error fetching configurations:', error);
      } finally {
        setLoadingConfig(false);
      }
    }
  };

 
  const updateSmtpSettings = (value, org) => {
    setSelectedSmtp((prev) => ({ ...prev, [org]: value }));
  };
  const updateWhatsappSettings = (value, org) => {
     setWhatsappSetting((prev) => ({ ...prev, [org]: value }));
  }
useEffect(()=>{
  if(location?.state?.data){
    formik.setFieldValue("orgList", location.state.data.map((token)=>token.orgToken))
    location?.state?.data.forEach( async (dt)=>{
      try {
        if(formik.values.selectedChannel == 'email'){

          const configPromises = [1].map(async (token) => {
            const { data } = await fetchEmailChannels(dt.orgToken);
            
            if (data?.default_promotional_uuid) {
              // updateSmtpSettings(data.default_promotional_uuid, token);
            }
            
            return {
              orgToken: dt.orgToken,
              emailSettings: data?.data || [],
              defaultPromotionalUuid: data?.default_promotional_uuid || null,
            };
          });
          
          const configs = await Promise.all(configPromises);
          setEmailConfiguration((prevConfig) => [...prevConfig, ...configs]);
        }
        if(formik.values.selectedChannel == 'whatsapp'){

          const configPromises = [1].map(async (token) => {
            const { data } = await getChanneldata(dt.orgToken);
            
            if (data?.data?.response) {
              updateWhatsappSettings( _.find(data?.data?.response,{isDefault: true}).uuid , token);
            }
            
            return {
              orgToken: token,
              whatsappSettings: data?.data || [],
              // defaultPromotionalUuid: data?.default_promotional_uuid || null,
            };
          });
          
          const configs = await Promise.all(configPromises);
          setWhatsappConfiguration((prevConfig) => [...prevConfig, ...configs]);
         console.log(whatsappConfiguration)
        }
        
      } catch (error) {
        console.error('Error fetching email configurations:', error);
      } finally {
        setLoadingConfig(false);
      }
      updateSmtpSettings(dt.selectedSmtpUuid, dt.orgToken)
    })
  }
},[])
  const channels = [
    { label: 'Email', value: 'email' },
    { label: 'Whatsapp', value: 'whatsapp' },
    { label: 'SMS', value: 'sms' },
  ];

  return (
    <Box maxW="1100px">
      <Text fontSize="1vw">Channel</Text>
      <Select
        options={channels}
        value={_.find(channels, { value: formik.values.selectedChannel })}
        onChange={(e) => formik.setFieldValue('selectedChannel', e.value)}
      />

      <Box mt="1rem">
        <form>
          <Text>Campaign Name</Text>
          <Input
            type="text"
            name="campaignName"
            value={formik.values.campaignName}
            onChange={formik.handleChange}
          />

          <Text>Bank</Text>
          {loadingOrgList ? (
            <Spinner size="sm" />
          ) : (
            <Select
              isMulti
              options={prepareOrgList(orgList)}
              onChange={handleOrgSelectionChange}
              value={selectedOrgList}
              closeMenuOnSelect={false}
              colorScheme="brand"
              isClearable={false}
            />
          )}
        </form>
      </Box>

      {formik.values.selectedChannel === 'email' && (
        <>
          <FormLabel>Configuration </FormLabel>
          <VStack bg={"#fff"} w="100%" p="1rem" borderRadius="md" boxShadow="md">
            {loadingConfig ? (
              <Spinner size="lg" />
            ) : (!_.isEmpty(selectedSmtp)  || emailConfiguration.length > 0) ? (
              <Table variant="simple" size="sm" w="100%">
                <TableHead headersProps={["Bank", "From", "Configuration Type"]} />
                <Tbody>
                  {selectedOrgList.map((org, i) => {
                    const config = emailConfiguration.find(
                      (conf) => conf.orgToken === org.value
                    );
                    return (
                      <Tr key={i}>
                        <Td>{config?.orgName || org?.data?.name || org?.label || "N/A"}</Td>
                        <Td>{config?.emailSettings[0]?.from ||(_.find(
                                prepareEmailProviderData(config?.emailSettings || []),
                                { value: selectedSmtp[org.value] }
                              ))?.from || "N/A"} </Td>
                        <Td w={'45%'}>
                          {!_.isEmpty(prepareEmailProviderData(config?.emailSettings)) ? (
                            <Select
                              value={_.find(
                                prepareEmailProviderData(config?.emailSettings || []),
                                { value: selectedSmtp[org.value] }
                              )}
                              options={prepareEmailProviderData(config?.emailSettings || [])}
                              onChange={(e) => updateSmtpSettings(e.value, org.value)}
                            />
                          ) : (
                            <Text>N/A</Text>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : (
              <Text>No configurations available</Text>
            )}
          </VStack>
        </>
      )}
      {
        formik.values.selectedChannel === 'whatsapp' &&(
          <>
          
          <FormLabel>Configuration </FormLabel>
          <VStack bg={"#fff"} w="100%" p="1rem" borderRadius="md" boxShadow="md">
            {loadingConfig ? (
              <Spinner size="lg" />
            ) : (  whatsappConfiguration.length > 0) ? (
              <Table variant="simple" size="sm" w="100%">
                <TableHead headersProps={["Bank",  "Configuration Type"]} />
                <Tbody>
                  {selectedOrgList.map((org, i) => {
                    const config = whatsappConfiguration.find(
                      (conf) => conf.orgToken === org.value
                    );
                    return (
                      <Tr key={i}>
                        <Td>{config?.orgName || org?.data?.name || org?.label || "N/A"}</Td>
                        
                        <Td w={'45%'}>
                          {!_.isEmpty(prepareWhatsappProviderData(config?.whatsappSettings?.response)) ? (
                            <Select
                              value={_.find(
                                prepareWhatsappProviderData(config?.whatsappSettings?.response || []),
                                { value: whatsappSetting[org.value] }
                              )}
                              options={prepareWhatsappProviderData(config?.whatsappSettings?.response || [])}
                              onChange={(e) => updateWhatsappSettings(e.value, org.value)}
                            />
                          ) : (
                            <Text>N/A</Text>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            ) : (
              <Text>No configurations available</Text>
            )}
          </VStack>
          </>
        )
      }
    </Box>
  );
}
