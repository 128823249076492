import React from 'react';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { FormControl, FormLabel, Input, VStack, FormErrorMessage } from '@chakra-ui/react';

const Sinch = ({ setGatewayData, index }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  // Handle field changes
  const handleChange = (field, event) => {
    setFieldValue(`credentials[${index}].${field}`, event.target.value);
    setGatewayData(`credentials[${index}]`, { ...values.credentials[index], [field]: event.target.value });
  };

  return (
    <VStack spacing={4} alignItems="flex-start">
      <FormControl id={`channelID-${index}`} isInvalid={errors.credentials?.[index]?.channelID && touched.credentials?.[index]?.channelID}>
        <FormLabel>Channel ID</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].channelID`}
          value={values.credentials[index]?.channelID || ''}
          onChange={(e) => handleChange('channelID', e)}
          placeholder="Channel ID"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.channelID}</FormErrorMessage>
      </FormControl>

      <FormControl id={`transactionType-${index}`} isInvalid={errors.credentials?.[index]?.transactionType && touched.credentials?.[index]?.transactionType}>
        <FormLabel>Transaction Type</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].transactionType`}
          value={values.credentials[index]?.transactionType || ''}
          onChange={(e) => handleChange('transactionType', e)}
          placeholder="Transaction Type"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.transactionType}</FormErrorMessage>
      </FormControl>

      <FormControl id={`transactionSubType-${index}`} isInvalid={errors.credentials?.[index]?.transactionSubType && touched.credentials?.[index]?.transactionSubType}>
        <FormLabel>Transaction SubType</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].transactionSubType`}
          value={values.credentials[index]?.transactionSubType || ''}
          onChange={(e) => handleChange('transactionSubType', e)}
          placeholder="Transaction SubType"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.transactionSubType}</FormErrorMessage>
      </FormControl>

      <FormControl id={`conversationID-${index}`} isInvalid={errors.credentials?.[index]?.conversationID && touched.credentials?.[index]?.conversationID}>
        <FormLabel>Conversation ID</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].conversationID`}
          value={values.credentials[index]?.conversationID || ''}
          onChange={(e) => handleChange('conversationID', e)}
          placeholder="Conversation ID"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.conversationID}</FormErrorMessage>
      </FormControl>

      <FormControl id={`externalReferenceId-${index}`} isInvalid={errors.credentials?.[index]?.externalReferenceId && touched.credentials?.[index]?.externalReferenceId}>
        <FormLabel>External Reference ID</FormLabel>
        <Field
          as={Input}
          name={`credentials[${index}].externalReferenceId`}
          value={values.credentials[index]?.externalReferenceId || ''}
          onChange={(e) => handleChange('externalReferenceId', e)}
          placeholder="External Reference ID"
        />
        <FormErrorMessage>{errors.credentials?.[index]?.externalReferenceId}</FormErrorMessage>
      </FormControl>

      <FormControl id={`isAsync-${index}`} isInvalid={errors.credentials?.[index]?.isAsync && touched.credentials?.[index]?.isAsync}>
        <FormLabel>Is Async</FormLabel>
        <Field
          name={`credentials[${index}].isAsync`}
          type="checkbox"
          checked={values.credentials[index]?.isAsync || false}
          onChange={(e) => handleChange('isAsync', e)}
        />
        <FormErrorMessage>{errors.credentials?.[index]?.isAsync}</FormErrorMessage>
      </FormControl>
    </VStack>
  );
};

export default Sinch;
