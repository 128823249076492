import React, { useEffect, useState } from 'react';
import { 
  Box, Table, Thead, Tbody, Tr, Th, Td, Text, Spinner, Icon, Flex, 
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, 
  ModalFooter, FormControl, FormLabel, Input, Button, useDisclosure, useToast
} from '@chakra-ui/react';
import axiosInstance from '../../Services/axiosInstance';
import { FaEdit } from 'react-icons/fa';
import { BsTrash } from 'react-icons/bs';
import PrimaryButton from '../../components/themeComponents/PrimaryButton';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';


export default function Webhook() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/hooks/rest/v1/paged/template');
      setData(response.data.items);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };const handleDelete = async (uuid) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let url = `/hooks/rest/v1/template/${uuid}`;
          await axiosInstance.delete(url);
  
          toast({
            title: 'Webhook Deleted',
            description: 'The webhook has been successfully deleted.',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
  
          // Refresh the table after deletion
          fetchData();
        } catch (error) {
          toast({
            title: 'Error',
            description: error.response?.data?.message || 'Failed to delete webhook.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      }
    });
  };
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axiosInstance.post('/hooks/rest/v1/template', values);
      toast({
        title: 'Webhook Created',
        description: 'Webhook has been created successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      fetchData();  
      resetForm();
      onClose();
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to create webhook.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box p={4}>
      <Flex justifyContent={'space-between'} mb={4}>
        <Text fontSize="2xl" fontWeight="bold">Template List</Text>
        <PrimaryButton label={'Create Webhook +'} onClick={onOpen} />
      </Flex>
      
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Table bg={'#fff'}>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Status</Th>
              <Th>Created At</Th>
              
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item) => (
              <Tr key={item.id}>
                <Td>{item.name}</Td>
                <Td>{item.status ? 'Active' : 'Inactive'}</Td>
                <Td>{new Date(item.createdAt).toLocaleString()}</Td>
               
                <Td>
                  
                  <Icon as={BsTrash} onClick={()=>handleDelete(item.uuid)} ml={'10px'} cursor={'pointer'} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Webhook</ModalHeader>
          <Formik
            initialValues={{
              templateName: '',
              description: '',
              url: '',
            }}
            validationSchema={Yup.object({
              templateName: Yup.string().required('templateName is required'),
              description: Yup.string().required('Description is required'),
              url: Yup.string().url('Invalid URL').required('Webhook URL is required'),
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <ModalBody>
                  <FormControl isInvalid={errors.name && touched.name} mb={4}>
                    <FormLabel>Name</FormLabel>
                    <Field as={Input} name="templateName" placeholder="Webhook Name" />
                    {errors.name && <Text color="red.500">{errors.name}</Text>}
                  </FormControl>

                  <FormControl isInvalid={errors.description && touched.description} mb={4}>
                    <FormLabel>Description</FormLabel>
                    <Field as={Input} name="description" placeholder="Webhook Description" />
                    {errors.description && <Text color="red.500">{errors.description}</Text>}
                  </FormControl>

                  <FormControl isInvalid={errors.url && touched.url} mb={4}>
                    <FormLabel>Webhook URL</FormLabel>
                    <Field as={Input} name="url" placeholder="Webhook URL" />
                    {errors.url && <Text color="red.500">{errors.url}</Text>}
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="gray" mr={3} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button 
                    colorScheme="blue" 
                    type="submit"
                    isLoading={isSubmitting}
                  >
                    Create
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </Box>
  );
}
