import React, { useEffect, useState } from "react";
import {
  Table, Thead, Tbody, Tr, Th, Td, Spinner, Box, Flex, Text, useToast,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
  Button, Input, FormControl, FormLabel, useDisclosure
} from "@chakra-ui/react";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../Services/axiosInstance";
import PrimaryButton from "../../components/themeComponents/PrimaryButton";
import OutlinedButton from "../../components/themeComponents/OutlinedButton";
import { getTokenFromLoacalStorage } from "../../_helpers/helpers";
import SecondaryButton from "../../components/themeComponents/SecondaryButton";

export default function AdminManager() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const currentUser = getTokenFromLoacalStorage().user_data;

  const getUserData = async () => {
    try {
      let response = await axiosInstance.get("/admin/manager");
      setUsers(response?.data?.data || []);
    } catch (error) {
      toast({
        title: "Error fetching user data",
        description: error.response?.data?.message || "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const toggleAccountStatus = async (email, enable) => {
    Swal.fire({
      title: `Are you sure you want to ${enable ? "disable" : "enable"} this account?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response = await axiosInstance.post("/enabledisableuser", {
            email: btoa(email),
            enable: !enable,
          });

          if (response?.status === 200) {
            getUserData();
            toast({
              title: "Success!",
              description: "User status updated successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: "Error updating user status",
            description: error.response?.data?.message || "Failed to update user status.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    });
  };
  const editRole = async (email, make_admin) => {
    Swal.fire({
      title: `Are you sure you want to ${make_admin ? "revoke" : "make admin"} this account?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let response = await axiosInstance.post("/edituserrole", {
            email: btoa(email),
            make_admin: !make_admin,
          });

          if (response?.status === 200) {
            getUserData();
            toast({
              title: "Success!",
              description: "User status updated successfully.",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: "Error updating user status",
            description: error.response?.data?.message || "Failed to update user status.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string().email("Invalid email").required("Email is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        let formData = new FormData()

        formData.append("firstName",values.firstName)
        formData.append("lastName" ,values.lastName)
        formData.append("email" , values.email)

        let response = await axiosInstance.post("/admin/user", formData, {"headers": {"Content-Type": "multipart/form-data; boundary=------border" }});
        if (response?.status === 200) {
          getUserData();
          toast({
            title: "User Invited!",
            description: "The user has been successfully invited.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          resetForm();
          onClose();
        }
      } catch (error) {
        toast({
          title: "Error inviting user",
          description: error.response?.data?.message || "Failed to invite user.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
  });

  useEffect(() => {
    getUserData();
  }, []);

  if (loading) {
    return <Spinner size="xl" />;
  }

  return (
    <Box p={5}>
      <Flex w="100%" p="1rem" justifyContent="space-between">
        <Text fontSize="1.5rem">Users</Text>
        <PrimaryButton label="Invite New User +" onClick={onOpen} />
      </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Make/Revoke Admin</Th>
            <Th>Enable/Disable Account</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user, index) => (
            <Tr key={index}>
              <Td>
                {user?.user_full_name?.trim() || "Unknown User"}
                {currentUser.email === user.email && " (You)"}
              </Td>
              <Td>{user?.email}</Td>
              <Td>
                {currentUser.email !== user.email && <SecondaryButton onClick={()=>editRole(user.email,user.allowedByAdmin)} label={user.allowedByAdmin?  "Revoke Admin" : "Make Admin"} />}
              </Td>
              <Td>
                {currentUser.email !== user.email && (
                  <OutlinedButton
                    onClick={() => toggleAccountStatus(user.email, user.isDisabled)}
                    label={user.isDisabled ? "Enable Account" : "Disable Account"}
                  />
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Invite User Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <FormControl isInvalid={formik.touched.firstName && formik.errors.firstName}>
                <FormLabel>First Name</FormLabel>
                <Input name="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
              </FormControl>

              <FormControl mt={4} isInvalid={formik.touched.lastName && formik.errors.lastName}>
                <FormLabel>Last Name</FormLabel>
                <Input name="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
              </FormControl>

              <FormControl mt={4} isInvalid={formik.touched.email && formik.errors.email}>
                <FormLabel>Email</FormLabel>
                <Input name="email" type="email" value={formik.values.email} onChange={formik.handleChange} />
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            
            {/* <SecondaryButton label={'Cancel'} variant="ghost" onClick={onClose}>Cancel</SecondaryButton> */}
            <PrimaryButton label={'Submit'}  colorScheme="blue" ml={3} onClick={formik.handleSubmit}>
              
              </PrimaryButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
