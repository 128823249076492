import React, { useState } from "react";
import { Box, Input, Button } from "@chakra-ui/react";

function ContentPersonalizationFilter({ onFilter }) {
  const [keyword, setKeyword] = useState("");

  const handleSearch = () => {
    onFilter({
      keyword,
      // add more if needed
    });
  };

  return (
    <Box display="flex" gap={2} mb={4} mt={2}>
      <Input
        placeholder="Search..."
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
      />
      <Button onClick={handleSearch}>Search</Button>
    </Box>
  );
}

export default ContentPersonalizationFilter;
