import React from 'react';
import { useFormikContext, ErrorMessage } from 'formik';
import { FormControl, FormLabel, Input, VStack, FormErrorMessage, Checkbox } from '@chakra-ui/react';

const Equitas = ({ setGatewayData }) => {
  const { errors, touched, values, setFieldValue } = useFormikContext();

  const handleChange = (field, event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setFieldValue(`credentials.${field}`, value);
    setGatewayData({ ...values.credentials, [field]: value });
  };

  return (
    <VStack spacing={4} alignItems="flex-start">
      <FormControl isInvalid={errors.name && touched.name}>
              <FormLabel>Name</FormLabel>
              <Input
                name={`credentials.name`}
                placeholder="Enter your name"
                value={values.credentials.name || ''}
                onChange={(e) => handleChange('name', e)}
              />
              <ErrorMessage name={`credentials.name`} component={FormErrorMessage} />
            </FormControl>

    <FormControl isInvalid={errors.channelID && touched.channelID}>
        <FormLabel>Channel ID</FormLabel>
        <Input
          name={`credentials.channelID`}
          placeholder="Enter Channel ID"
          value={values.credentials.channelID || ''}
          onChange={(e) => handleChange('channelID', e)}
        />
        <ErrorMessage name={`credentials.channelID`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.transactionType && touched.transactionType}>
        <FormLabel>Transaction Type</FormLabel>
        <Input
          name={`credentials.transactionType`}
          placeholder="Enter Transaction Type"
          value={values.credentials.transactionType || ''}
          onChange={(e) => handleChange('transactionType', e)}
        />
        <ErrorMessage name={`credentials.transactionType`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.transactionSubType && touched.transactionSubType}>
        <FormLabel>Transaction SubType</FormLabel>
        <Input
          name={`credentials.transactionSubType`}
          placeholder="Enter Transaction SubType"
          value={values.credentials.transactionSubType || ''}
          onChange={(e) => handleChange('transactionSubType', e)}
        />
        <ErrorMessage name={`credentials.transactionSubType`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.conversationID && touched.conversationID}>
        <FormLabel>Conversation ID</FormLabel>
        <Input
          name={`credentials.conversationID`}
          placeholder="Enter Conversation ID"
          value={values.credentials.conversationID || ''}
          onChange={(e) => handleChange('conversationID', e)}
        />
        <ErrorMessage name={`credentials.conversationID`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.externalReferenceId && touched.externalReferenceId}>
        <FormLabel>External Reference ID</FormLabel>
        <Input
          name={`credentials.externalReferenceId`}
          placeholder="Enter External Reference ID"
          value={values.credentials.externalReferenceId || ''}
          onChange={(e) => handleChange('externalReferenceId', e)}
        />
        <ErrorMessage name={`credentials.externalReferenceId`} component={FormErrorMessage} />
      </FormControl>

      <FormControl isInvalid={errors.isAsync && touched.isAsync}>
        <Checkbox
          name={`credentials.isAsync`}
          isChecked={values.credentials.isAsync || false}
          onChange={(e) => handleChange('isAsync', e)}
        >
          Is Async
        </Checkbox>
        <ErrorMessage name={`credentials.isAsync`} component={FormErrorMessage} />
      </FormControl>
    </VStack>
  );
};

export default Equitas;
