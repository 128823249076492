import { Box, Text, Input, FormLabel, Icon, Spinner, FormErrorMessage } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import store from '../../store/store';
import TemplateSelection from './TemplateSelection';
import TemplateSelectionWhatsapp from './TemplateSelectionWhatsapp';
import { fetchTemplate, fetchTemplateTG } from '../../Services/api';
import { useQuery } from 'react-query';
import TemplateSelectionSms from './TemplateSelectionSms';
import SendTestModal from '../../components/SendTestModal';
import { BsSend } from 'react-icons/bs';

export default function TemplateSelectionTG({
  formik,
  templatesData,
  setTemplatesData,
  setSelectedGatewayUuid,
  selectedGatewayUuid,
  selectedSmtpUuid,
  location,
  targetGroupData,
  whatsappSetting
}) {
  const [orgList, setOrgList] = useState(store.getState()?.org?.orgList);
  const [isSendTestModalOpen, setIsSendTestModalOpen] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [subjectLineError, setSubjectLineError] = useState(false);

  const getOrgName = (token) => {
    let orgName = orgList.find(e => e.token === token);
    return orgName ? orgName.name : '';
  };

  const templateQuery = useQuery(['fetchTemplate'], {
    queryFn: async () => {
      if (formik?.values?.selectedChannel === 'whatsapp') {
        let requests = formik?.values?.orgList?.map(async (dt) => {
          let res = await fetchTemplate(formik.values.selectedChannel, dt, "", whatsappSetting[dt]);
          return {
            org_token: dt,
            data: res?.data?.templates,
          };
        });
  
        return Promise.all(requests); 
      } else {
        let res = await fetchTemplateTG(formik.values.selectedChannel, formik.values.orgList);
        return res.data;
      }
    },
    enabled: true,
  });
  
  useEffect(() => {
    templateQuery.refetch();
    setOrgList(
      orgList.filter((org) => {
        return formik.values.orgList.includes(org.token);
      })
    );
  }, []);

  useEffect(() => {

    if (Object.keys(templatesData).length === 0) {
      setTemplateError(true);
    } else {
      setTemplateError(false);
    }


    if (formik.values.selectedChannel === 'email' && !formik.values?.subjectLine?.trim()) {
      setSubjectLineError(true);
    } else {
      setSubjectLineError(false);
    }
  }, [templatesData, formik.values.subjectLine]);

  return (
    <>
    
      {templateQuery.isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" h="100%">
          <Spinner size="xl" color="teal.500" />
        </Box>
      ) : (
        !!templateQuery?.data?.length &&
        templateQuery?.data?.map((e, i) => (
          <Box key={i}>
            <Text>
              {formik.values.selectedChannel === 'email' && (
                <>
                  <Box>
                    {i === 0 && (
                      <>
                        <FormLabel>Common Subject Line</FormLabel>
                        <Input
                          w="350px"
                          name="subjectLine"
                          type="text"
                          value={formik.values.subjectLine}
                          onChange={(e) => {
                            formik.setFieldValue('subjectLine', e?.target?.value);
                          }}
                          isInvalid={subjectLineError}
                        />
                        {subjectLineError && (
                          <FormErrorMessage>
                            Common Subject Line is required.
                          </FormErrorMessage>
                        )}
                      </>
                    )}
                    {i === 0 && (
                      <Text
                        mb="15px"
                        float="right"
                        cursor={Object.keys(templatesData).length && !subjectLineError ? "pointer" : "not-allowed"}
                        textDecoration={Object.keys(templatesData).length && !subjectLineError ? "underline" : "none"}
                        color={Object.keys(templatesData).length && !subjectLineError ? "black" : "gray.400"}
                        onClick={() => {
                          if (Object.keys(templatesData).length && !subjectLineError) {
                            setIsSendTestModalOpen(true);
                          }
                        }}
                      >
                        Send Test Communication
                        <Icon
                          as={BsSend}
                          cursor={Object.keys(templatesData).length && !subjectLineError ? "pointer" : "not-allowed"}
                          title={`Send Test ${formik.values.selectedChannel}`}
                          ml="10px"
                        />
                      </Text>
                    )}
                    <TemplateSelection
                      location={location}
                      templateQueryRefetch={templateQuery.refetch}
                      templatesData={templatesData}
                      setTemplatesData={setTemplatesData}
                      templateQuery={e}
                      selectedChannel={formik.values.selectedChannel}
                      orgToken={e.org_token}
                      formik={formik}
                    />
                    {templateError && (
                      <FormErrorMessage>
                        Please select at least one template to proceed.
                      </FormErrorMessage>
                    )}
                    <SendTestModal
                      targetedAudience={targetGroupData}
                      formik={formik}
                      selectedSmtpUuid={selectedSmtpUuid}
                      templateData={templatesData}
                      orgToken={e.org_token}
                      uuid={templatesData[e.org_token]}
                      isOpen={isSendTestModalOpen}
                      onClose={() => setIsSendTestModalOpen(false)}
                      channel={formik.values.selectedChannel}
                    />
                  </Box>
                </>
              )}
              {formik.values.selectedChannel === 'whatsapp' && (
                <>
                  <Text my="15px" fontSize="16px" fontWeight="500">
                    Select Template for {getOrgName(e?.org_token)}
                  </Text>
                  {i === 0 && (
                    <Text
                      mb="15px"
                      float="right"
                      cursor={Object.keys(templatesData).length ? "pointer" : "not-allowed"}
                      textDecoration={Object.keys(templatesData).length ? "underline" : "none"}
                      color={Object.keys(templatesData).length ? "black" : "gray.400"}
                      onClick={() => {
                        if (Object.keys(templatesData).length) {
                          
                          setIsSendTestModalOpen(true);
                        }
                      }}
                    >
                      Send Test Communication
                      <Icon
                        as={BsSend}
                        cursor={Object.keys(templatesData).length ? "pointer" : "not-allowed"}
                        title={`Send Test ${formik.values.selectedChannel}`}
                        ml="10px"
                      />
                    </Text>
                  )}
                  <TemplateSelectionWhatsapp
                    location={location}
                    templatesData={templatesData}
                    setTemplatesData={setTemplatesData}
                    templateQuery={e}
                    selectedChannel={formik.values.selectedChannel}
                    orgToken={e.org_token}
                    targetedAudience={targetGroupData}
                    formik={formik}
                  />
                  <SendTestModal
                    formik={formik}
                    selectedSmtpUuid={selectedSmtpUuid}
                    templateData={templatesData}
                    orgToken={e.org_token}
                    isOpen={isSendTestModalOpen}
                    onClose={() => setIsSendTestModalOpen(false)}
                    channel={formik.values.selectedChannel}
                    targetedAudience={targetGroupData}
                  />
                  {templateError && (
                    <FormErrorMessage>
                      Please select at least one template to proceed.
                    </FormErrorMessage>
                  )}
                </>
              )}
            </Text>
            {formik.values.selectedChannel === 'sms' && (
                <>
                  {i === 0 && (
                    <Text
                      mb="15px"
                      float="right"
                      cursor={Object.keys(templatesData).length ? "pointer" : "not-allowed"}
                      textDecoration={Object.keys(templatesData).length ? "underline" : "none"}
                      color={Object.keys(templatesData).length ? "black" : "gray.400"}
                      onClick={() => {
                        if (Object.keys(templatesData).length) {
                          setIsSendTestModalOpen(true);
                        }
                      }}
                    >
                      Send Test Communication
                      <Icon
                        as={BsSend}
                        cursor={Object.keys(templatesData).length ? "pointer" : "not-allowed"}
                        title={`Send Test ${formik.values.selectedChannel}`}
                        ml="10px"
                      />
                    </Text>
                  )}
                  <TemplateSelectionSms
                    selectedGatewayUuid={selectedGatewayUuid}
                    setSelectedGatewayUuid={setSelectedGatewayUuid}
                    templatesData={templatesData}
                    setTemplatesData={setTemplatesData}
                    templateQuery={e}
                    refetch={() => {
                      templateQuery.refetch();
                    }}
                    selectedChannel={formik.values.selectedChannel}
                    orgToken={e.org_token}
                    formik={formik}
                    location={location}
                  />
                  {templateError && (
                    <FormErrorMessage>
                      Please select at least one template to proceed.
                    </FormErrorMessage>
                  )}
                  <SendTestModal
                    formik={formik}
                    selectedSmtpUuid={selectedSmtpUuid}
                    templateData={templatesData}
                    orgToken={e.org_token}
                    isOpen={isSendTestModalOpen}
                    onClose={() => setIsSendTestModalOpen(false)}
                    channel={formik.values.selectedChannel}
                    targetedAudience={targetGroupData}
                  />
                </>
              )}
          </Box>
        ))
      )}
    </>
  );
}
